"use strict";

import {
  RECEIVE_DEVICE_LIST,
  UPDATE_DEVICE,
  UPDATE_DEVICE_STATUS,
  ADD_DEVICE_STATUS_FIRESTORE_REFERENCE,
  SET_CURRENT_DEVICE,
  UPDATE_FIRESTORE_DATA,
  FETCHING_HEALTH_TEST_DATA,
  FETCHED_HEALTH_TEST_DATA_SUCCESS,
  FETCHED_HEALTH_TEST_DATA_ERROR,
  CLEAR_HEALTH_TEST_DATA,
  UPDATE_DEVICE_WEATHER_TEMP
} from "constants/action";

import DeviceStatus from 'constants/DeviceStatus';
import ValveStatus from 'constants/ValveStatus';
import SystemMode from 'constants/SystemMode';
import ProductType from '../constants/ProductType';
import _ from 'lodash';

const getTelemetryDataFromFirestore = (firestore, api) => {
  const isPuck = ProductType.fromValue(api.deviceType) === ProductType.DETECTOR;

  if ( isPuck ) {
    const current = (api.telemetry && api.telemetry.current) || {};
    return {
      humidity: current.humidity,
      t: current.tempF,
      battery: api.battery && api.battery.level,
      lastReported: current.updated,
      wf: undefined,
      p: undefined
    };
  } else if ( firestore ) {
    const current = (firestore.telemetry && firestore.telemetry.current) || {};
    return {
      wf: current.gpm || 0,
      p: current.psi || 0,
      t: current.tempF || 0,
      humidity: undefined,
      battery: undefined,
      lastReported: undefined
    };
  }

  return initialState.telemetry;
};

function isOffline(status) {
  return _.includes(status, 'offline');
}

const getSystemModeFromFirestore = (status, firestore) => {
  if(isOffline(status)) {
    return SystemMode.UNKNOWN;
  }

  if(firestore) {
    const mode = firestore.systemMode && firestore.systemMode.lastKnown;

    switch( mode ) {
      case "home": {
        return SystemMode.HOME;
      };break;
      case "away": {
        return SystemMode.AWAY;
      };break;
      case "sleep": {
        return SystemMode.SLEEP;
      };break;
      default:{
        return SystemMode.UNKNOWN;
      };break;
    }
  }

  return SystemMode.UNKNOWN;
};

const getValveStateFromFirestore = (status, firestore) => {
  if(isOffline(status)) {
    return ValveStatus.UNKNOWN;
  }

  if (firestore) {
    const valveState = firestore.valve && firestore.valve.lastKnown;

    switch (valveState) {
      case 'closed':
        return ValveStatus.OFF;
      case 'open':
      case 'opened':
        return ValveStatus.ON;
      case 'in_transition':
        return ValveStatus.IN_TRANSITION;
      default:
        return ValveStatus.UNKNOWN;
    }
  } else {
    return ValveStatus.UNKNOWN;
  }
};

/**
 * Initial State for Device Store
 */
const initialState = {
  dict: {},
  list: [],
  currentMacAddress: undefined,
  status: DeviceStatus.NO_DEVICE,
  deviceStatusFirestoreReferences: [],
  telemetry: {
    wf: 0,
    p: 0,
    t: 0,
    humidity: 0,
    battery: 0,
    lastReported: null,
  },
  systemMode: SystemMode.UNKNOWN,
  valveState: ValveStatus.UNKNOWN,
  healthTest: {
    fetching: false,
    error: null,
    latestRun: undefined
  },
  currentDeviceWeatherTemp: 0,
};

/**
 * Device Reducer
 * @param { Object } state - State of the 'Device' Store
 * @param { Object } action - Action Object. Always has action.type, which should match a constant
 * @return { Object } - Updated Device Store
 */
const device = ( state = initialState, action ) => {
  let newState = { ...state };
  switch( action.type ){
    case RECEIVE_DEVICE_LIST: {
      newState = { ...newState, ...{ list: action.devices } };

      if (action.devices.length < 1) {
        newState = { ...newState, currentMacAddress: undefined };
        return newState;
      }

      const defaultDevice = action.devices.find(d => d.deviceType === 'flo_device_v2') || action.devices[0];
      newState.currentMacAddress = defaultDevice.device_id;

      let tempDict = { ...newState.dict };
      if ( defaultDevice.device_id && !tempDict[defaultDevice.device_id] ) {
        action.devices.forEach(device => {
          tempDict[ device.device_id ] = {
            api: {
              telemetry: device.telemetry,
              battery: device.battery,
              deviceType: device.deviceType
            }
          };
        });
      }

      return { ...newState, ...{ dict: tempDict } };
    }; break;
    case UPDATE_DEVICE: {
      if ( action.device && action.device.id ) {
        if ( !newState.list || newState.list.length === 0 ) {
          newState.list = [ { "device_id": action.device.id } ];
        }
        if ( !newState.dict[ action.device.id ] ) {
          newState.dict[ action.device.id ] = {};
        }
        if ( action.device.data[ "ack_topic" ] ) {
          if ( action.device.data[ "ack_topic"].indexOf( "mvrzit" ) > -1 ) {
            newState.dict[ action.device.id ] = { ...newState.dict[ action.device.id ], zit: { ...newState.dict[ action.device.id ].zit, ...action.device.data } };
          } else if ( action.device.data.directive === "get-health-test-config" ) {
            newState.dict[ action.device.data[ "device_id" ] ] = { ...newState.dict[ action.device.id ], healthTestConfig: { ...action.device.data.data } };
          }
        } else {
          newState.dict[ action.device.id ] = { ...newState.dict[ action.device.id ], ...action.device.data };
        }

        return newState;
      }
    }; break;

    case UPDATE_FIRESTORE_DATA: {
      if (state.currentMacAddress !== action.macAddress) {
        return state;
      }

      const firestoreState = (state.dict[action.macAddress]) ? state.dict[action.macAddress].firestore : {};
      const apiState = (state.dict[action.macAddress]) ? state.dict[action.macAddress].api : {};
      const firestoreObj = action.firestore;

      const telemetry = {
        ...state.telemetry,
        ...getTelemetryDataFromFirestore(firestoreObj, apiState)
      };

      const systemMode = {
        ...state.systemMode,
        ...getSystemModeFromFirestore(state.status, firestoreObj)
      };

      const valveState = getValveStateFromFirestore(state.status, firestoreObj);
      const lastTimeHealthTestRan = firestoreObj && firestoreObj.healthTest && firestoreObj.healthTest.updated;

      return {
        ...state,
        dict: {
          ...state.dict,
          [action.macAddress] : {
            ...state.dict[action.macAddress],
            firestore: {
              ...firestoreState,
              ...firestoreObj
            }
          },
        },
        telemetry,
        systemMode,
        valveState,
        lastTimeHealthTestRan
      };
    };

    case UPDATE_DEVICE_STATUS: {
      const nextState = {
        ...state,
        status: action.connectionStatus
      };

      if(isOffline(action.connectionStatus)) {
        return {
          ...nextState,
          valveState: ValveStatus.UNKNOWN,
          systemMode: SystemMode.UNKNOWN
        };
      } else {
        return nextState;
      };
    }; break;

    case ADD_DEVICE_STATUS_FIRESTORE_REFERENCE: {
      const deviceStatusFirestoreReferences = [ ...state.deviceStatusFirestoreReferences ];

      if (action.deviceStatusFirestoreReference) {
        deviceStatusFirestoreReferences.push(action.deviceStatusFirestoreReference);
      }
      return {
        ...state,
        deviceStatusFirestoreReferences
      };
    }; break;

    case SET_CURRENT_DEVICE: {
      const firestoreObj = (state.dict[state.currentMacAddress]) ? state.dict[state.currentMacAddress].firestore : {};
      const apiObj = (state.dict[state.currentMacAddress]) ? state.dict[state.currentMacAddress].api : {};
      return {
        ...state,
        telemetry: getTelemetryDataFromFirestore(firestoreObj, apiObj),
        systemMode: getSystemModeFromFirestore(state.status, firestoreObj),
        valveState: getValveStateFromFirestore(state.status, firestoreObj),
        currentMacAddress: action.macAddress
      };
    };

    case FETCHING_HEALTH_TEST_DATA: {
      return {
        ...state,
        healthTest: {
          ...state.healthTest,
          fetching: true
        }
      };
    };

    case FETCHED_HEALTH_TEST_DATA_SUCCESS: {
      return {
        ...state,
        healthTest: {
          ...state.healthTest,
          ...action.data,
          fetching: false
        }
      };
    };

    case FETCHED_HEALTH_TEST_DATA_ERROR: {
      return {
        ...state,
        healthTest: {
          ...state.healthTest,
          error: action.message,
          fetching: false
        }
      };
    };

    case CLEAR_HEALTH_TEST_DATA: {
      return {
        ...state,
        healthTest: {
          fetching: false,
          error: null
        }
      };
    }

    case UPDATE_DEVICE_WEATHER_TEMP: {
      return {
        ...state,
        currentDeviceWeatherTemp: action.tempF,
      };
    }
  }
  return state;
}

export default device;
