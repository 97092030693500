"use strict";

import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import SelectField from "elements/SelectField";
import InputField  from "elements/InputField";
import ComboTextField  from "elements/ComboTextField";
import { required } from 'redux-form-validators';

const CheckboxGroup = ({ label, required, name, options,  input, meta}) => (
  <div className="form-check">
    { options.map((option, index) => (
      <div className="form-check form-check-inline" key={index}>
        <input type="checkbox"
               name={`${input.name}[${index}]`}
               value={option.value}
               checked={input.value.indexOf(option.value) !== -1}
               onChange={event => {
                 const newValue = [...input.value];
                 if(event.target.checked) {
                   newValue.push(option.value);
                 } else {
                   newValue.splice(newValue.indexOf(option.value), 1);
                 }

                 return input.onChange(newValue);
               }}
               className="form-check-input"
        />
        <label className="form-check-label">{option.display}</label>
      </div>))
    }
  </div>
);

const buildRangeOptions = (type, min, max, step) => {
  if (type !== 'number') {
    console.error(`Type ${type} is not supported.`);
    return [];
  }
  return _.range(min, max + 1, step).map(val => ({
    value: val,
    display: val
  }));
};

const HomeProfileCheckbox = ({label, name, options}) => (
  <div className="form-group">
    <label>{label}</label>
    <Field
      name      = {name}
      value     = {name}
      component = {CheckboxGroup}
      options   = {options}
      type      = "checkbox"
      className = "form-check-input"
    />
  </div>
);

const HomeProfileSelect = ({type, label, name, options, min, max, step, optional}) => (
  <div className="form-group">
    <Field
      label             = {label}
      name              = {name}
      options           = {(type === 'list' || type === 'combolist') ? options : buildRangeOptions(type, min, max, step)}
      component         = {SelectField}
      className         = "form-control"
      validate          = {!optional && required()}
      showDefaultOption = {false}
    />
  </div>
);

const HomeProfileText = ({label, name, type, optional}) => (
  <div className="form-group">
    <Field
      label             = {label}
      name              = {name}
      component         = {InputField}
      className         = "form-control"
      validate          = {!optional && required()}
      showDefaultOption = {false}
      type              = {type === 'bool' ? 'checkbox' : 'text'}
    />
  </div>
);

const HomeProfileCombolist = ({label, name, options, type, optional}) => (
  <div className="form-group">
    <Field
      label             = {label}
      name              = {name}
      options           = {options}
      component         = {ComboTextField}
      className         = "form-control"
      validate          = {!optional && required()}
      showDefaultOption = {false}
    />
  </div>
);

class HomeProfileForm extends PureComponent {

  renderFields() {
    const { fields } = this.props;
    const renderedFields = fields.map(field => {
      if (field.type === 'list' && field.multiple) {
        return (<HomeProfileCheckbox
          key       = {field.id}
          label     = {field.label}
          name      = {field.fieldName}
          options   = {field.options}
        />);
      } else if ((field.type === 'list' || field.type === 'number') && !field.multiple) {
        return (<HomeProfileSelect
          key       = {field.id}
          label     = {field.label}
          name      = {field.fieldName}
          options   = {field.options}
          type      = {field.type}
          min       = {field.min}
          max       = {field.max}
          step      = {field.step}
          optional  = {field.optional}
        />);
      } else if (field.type === 'text' || field.type === 'bool') {
        return (<HomeProfileText
          key       = {field.id}
          label     = {field.label}
          name      = {field.fieldName}
          type      = {field.type}
          optional  = {field.optional}
        />);
      } else if (field.type === 'combolist') {
        return (<HomeProfileCombolist
          key       = {field.id}
          label     = {field.label}
          name      = {field.fieldName}
          type      = {field.type}
          options   = {field.options}
          optional  = {field.optional}
        />);
      } else {
        return null;
      }
    });

    return renderedFields;
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={ handleSubmit }>

        { this.renderFields() }

        <button
          type="submit"
          className="btn btn-primary"
          ref="save"
          disabled={ this.props.submitting }
        >
          Save
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'home-profile-form',
  enableReinitialize : true
})(HomeProfileForm);
