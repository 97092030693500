"use strict";

import React, { PureComponent } from 'react';
import { Field, reduxForm }     from 'redux-form';
import { required, email }      from 'redux-form-validators';
import InputField               from "elements/InputField";

class LoginForm extends PureComponent {

  render() {
    const { handleSubmit, submitting, handleForgotPassword } = this.props;

    return (
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-8 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-center">
            <img src="/assets/images/FloByMoenLogo.png" alt="Flo Technologies" className="img-fluid login-logo" />
            <div className="form" ref="formWrapper">
              <p className={ "text-center text-danger " + ( !!this.props.loginErr ? "" : "hidden" ) }>
                { this.props.loginErr }
              </p>
              <legend>Login</legend>
              <form onSubmit={ handleSubmit }>
                <div className="form-group">
                  <Field
                    name="email"
                    component={ InputField }
                    type="email"
                    placeholder="Email"
                    className="form-control"
                    validate={[required(), email()]}
                  />
                </div>
                <div className="form-group">
                  <Field
                    name="password"
                    component={ InputField }
                    type="password"
                    placeholder="Password"
                    className="form-control"
                    validate={required()}
                  />
                </div>
                <button type="submit" className="btn btn-lg btn-primary btn-block btn-green" disabled={submitting}>Login</button>

                <div className="forgot-password" onClick={ handleForgotPassword }>
                  Forgot Password?
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: 'login-form',
  enableReinitialize : true
})(LoginForm);
