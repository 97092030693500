"use strict";

import React, { Component }         from "react";
import { connect }                  from "react-redux";
import { Link }                     from "react-router";
import Authentication               from "actions/authentication";
import LabelInput                   from "elements/LabelInput";
import Footer                       from "layout/Footer";
import { browserHistory }           from "react-router";
import { passwordComplexityRegex }  from "constants/regex";

/**
 * Password Reset View
 */
export class PasswordReset extends Component {
  /**
   * React Lifecycle: Initilizes the component and sets the initial state
   * @param { Object } props - Initial incomming properties on the component
   */
  constructor( props ) {
    super();
    this.state = {
      ...props,
      password: "",
      passwrodConf: "",
      passwordChanged: false
    };
  }

  /**
   * React Lifecycle: Exposes the incomming property updates to the component
   * @param { Object } nextProps - The incomming properties due to react properties update
   */
  componentWillReceiveProps( nextProps ){
    this.setState({ ...nextProps });
  }
  /**
   * Password update handler
   * @param { string } proxyValue - the value from the proxied event
   */
  updatePassword = event => this.setState( { password: event.target.value } );
  /**
   * PasswordConf update handler
   * @param { string } proxyValue - the value from the proxied event
   */
  updatePasswordConf = event => this.setState( { passwordConf: event.target.value } );
  /**
   * Key watcher / event handler
   */
  keyHandler = ( proxy, event ) => {
    if ( proxy.keyCode === 13 ) {
      this.submit()
    }
  }

  /**
   * Form validation for the login form
   * @param { Boolean|undefined } - true if the password is required
   */
  formValid(){
    const password = this.state.password;
    const passwordConf = this.state.passwordConf;

    return  password == passwordConf &&
      passwordComplexityRegex.test(password) &&
      passwordComplexityRegex.test(passwordConf);
  }
  error( msg, timeout = 4000 ){
    this.setState( { submitting: false, passResetErr: msg }, () => {
      setTimeout( Authentication.clearErrorMessages, timeout );
    });
  }
  submit(){
    if ( this.formValid() ) {
      this.setState( { submitting: true }, () => {

        Authentication
          .resetPassword( this.state.password, this.props.params.userId, this.props.params.resetToken )
          .then(x => {
            this.setState( { passwordChanged: true} );
            this.setState( { submitting: false }, Authentication.clearErrorMessages );
            this.setState( { passResetErr: null } );

            setTimeout( () => {
              browserHistory.push( "/login" )
            }, 3000 );
          });
      });
    } else {
      this.error( "Make sure the passwords match and are valid. Your password should have at least 8 characters, 1 capital, 1 lowercase and 1 digit." );
    }
  }

  render() {

    return (
      <section className="login">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-8 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-center">
              <img src="/assets/images/FloByMoenLogo.png" alt="Flo Technologies" className="img-fluid login-logo" />
              {this.state.passwordChanged ? (
                <div>
                  <h2>Your password has successfully been changed</h2>
                </div>
              ) : (
                <div>
                  <h2 className="title">Password Reset</h2>

                  <div className="form" ref="formWrapper">
                    <div className={ "text-center text-danger " + ( !!this.state.passResetErr ? "" : "hidden" ) }>
                      { this.state.passResetErr }
                    </div>

                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        aria-describedby="emailHelp"
                        pattern={ passwordComplexityRegex }
                        required
                        onKeyUp={ this.keyHandler }
                        onChange={ this.updatePassword }
                        placeholder="Enter new password"
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        name="passwordConf"
                        aria-describedby="emailHelp"
                        pattern={ passwordComplexityRegex }
                        required
                        onKeyUp={ this.keyHandler }
                        onChange={ this.updatePasswordConf }
                        placeholder="Confirm new password"
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn-lg btn-primary btn-block btn-green"
                      onClick={ this.submit.bind( this ) }
                      ref="submitButton"
                    >
                      Set Password
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </section>
    );
  }
}

export default connect( store =>
  ({
    passResetErr: store.authentication.passwordResetError
  })
)( PasswordReset );
