"use strict";

import React, { Component } from "react";
import Dialog from "elements/Dialog";

/**
 * Slide Select element
 */
export default class SlideSelect extends Component {
	/**
   * React Lifecycle: Initilizes the component and sets the initial state
   * @param { Object } props - Initial incomming properties on the component
   */
  constructor( props ){
    super();
    this.state = {
      ...props,
			classes: props.className ? props.className + " slide-select" : "slide-select",
      nodes: props.nodes.length > 0 ? props.nodes : [],
      active: props.active ? props.active : props.nodes[ 0 ],
      controlName: props.controlName ? props.controlName : "",
      animating: false,
      disabled: true,
      animatingClass: props.animatingClass ? props.animatingClass : "animating"
    };
  }
  /**
   * React Lifecycle: Exposes the incomming property updates to the component
   * @param { Object } nextProps - The incomming properties due to react properties update
   */
  componentWillReceiveProps( props ){
    this.setState( { ...props } );
  }
	/**
   * React Lifecycle: triggered after the component finishes mounting
	 * Sets the pill location
   * Calls: {@link setPill}
   */
  componentDidMount(){
    this.setPill();
  }
	/**
   * React Lifecycle: triggered after the component updates
   * Sets the pill location
   * Calls: {@link setPill}
   */
	componentDidUpdate(){
		this.setPill();
	}

	/**
   * Builds the selection span
   * @return { JSX } - of the selection option
   */
  renderSpan = name => {
    return ( <span key={ name } name={ name } className={ this.state.active === name ? "active" : "" } onClick={ this.selectNode.bind( this, name ) } ref={ "in-" + name }>{ name }</span> );
  }
	/**
   * Selection handler for selection option ( {@link renderSpan} )
   * @param { string } name - of the option selected
   * @return { undefined } - returns nothing
   */
  selectNode( name ){
    const select = this.props.onSelect ? this.props.onSelect : () => {};

    if ( this.props.skipModal ) {
      select( name );
    } else {
      if ( !this.props.disabled ) {
        const message = !this.props.message
                          ? ( <div className="message-block">
                              Would you really like to set { this.state.controlName + " to: " + name }
                            </div> )
                          : ( <div className="message-block">
                                { this.props.message.replace( /\$\{newState\}/g, name ) }
                              </div> );
        new Dialog( "FloModal", "warning" ).confirm( message )
          .then( response => {
            select( name );
          });
      }
    }
	}
  /**
   * Set's the pill location
   * @return { undefined } - returns nothing
   */
  setPill(){
		let offset = 0;
   	this.state.nodes.every( ( name, i ) => {
			if ( name === this.state.active ){
				offset = i;
				return false;
			} else {
				return true;
			}
		});
		const wrapperWidth = Math.floor( parseInt( window.getComputedStyle( this.refs.wrapper ).width, 10 ) );
		const delimeter = this.state.nodes.length;
		this.refs.pill.style.left = ( Math.floor( wrapperWidth / delimeter ) * offset ) - ( offset * 2 ) + "px";
  }
  /**
   * Set this's state.animating to true
   * @return { undefined } - returns nothing
   */
  startAnimating(){
    this.setState( { animating: true } );
  }
  /**
   * Set this's state.animating to false
   * @return { undefined } - returns nothing
   */
  stopAnimating(){
    this.setState( { animating: false } );
  }
	/**
   * React Lifecycle: render - Is fired when the <SlideSelect /> component is leveraged to draw to the screen.
   * @return { JSX } - a JSX Object
   */
  render() {
    return (<div className={ this.state.classes }>
              <div className="controlName">{ this.state.controlName }</div>
              <div className={ "pillSlider " + ( this.state.animating ? this.state.animatingClass : "" ) } ref="wrapper">
                <div className={ "pill " + ( this.props.disabled ? "disabled" : "" ) } ref="pill"></div>
                <div className="states">
                  { this.state.nodes.map( name => this.renderSpan( name ) ) }
                </div>
              </div>
            </div> );
  }
}
