"use strict";

import { UPDATE_FIXTURE_DETECTION, UPDATE_FLO_DETECT_DATA, LOADING_FLO_DETECT, UPDATE_FLOW_EVENTS, INITIALIZE_FLOW_EVENTS_NEW_PAGE } from "constants/action";
import _ from 'lodash';
import { convertFromGallons } from 'constants/UnitSystem';

const initialState = {
  requestId: null,
  deviceId: null,
  startDate: null,
  endDate: null,
  createdAt: null,
  status: null,
  isLoading: true,
  fixtures: [
  ],
  isLearning: true,
  hasNoDataToDisplay: false,
  fixtureUsageDetails: [],
  hasMoreFixtureUsageDetails: false,
  isUsageDetailsLoading: false,
  fixturesUsage: []
};

/**
 * Allowed Actions in the Fixture Detection Reducer
 */
const fixtureDetectionActions = [
  UPDATE_FIXTURE_DETECTION,
  UPDATE_FLO_DETECT_DATA,
  LOADING_FLO_DETECT,
  UPDATE_FLOW_EVENTS,
  INITIALIZE_FLOW_EVENTS_NEW_PAGE
];

function getColorByType(type) {
  switch (type) {
    case 101:
      return "#E93A2E";
    case 102:
      return "#E92C90";
    case 200:
      return "#F2A647";
    case 300:
      return "#A343B4";
    case 400:
      return "#1EB6CB";
    case 500:
      return "#4395AF";
    case 501:
      return "#4E85BB";
    case 601:
    case 602:
      return "#34F0CF";
    case 701:
      return "#2E9AE9";
    case 801:
    case 802:
      return "#50BA5B";
    case 900:
      return "#EA552F";
    case 10000:
      return "#9EBED0";
    default:
      return "#9EBED0";
  };
}

export const fixtureDetection = (state = initialState, action) => {
  if (action && action.type && fixtureDetectionActions.indexOf(action.type) > -1) {
    switch (action.type) {
      case LOADING_FLO_DETECT:
        return {
          ...state,
          isLoading: (action.data && typeof action.data.isLoading !== 'undefined') ? action.data.isLoading : true
        };
      case UPDATE_FIXTURE_DETECTION:
        const result = _.mapKeys(action.data, (v, k) => _.camelCase(k));

        return {
          ...state,
          ...result,
          isLoading: false
        };
        break;
      case UPDATE_FLO_DETECT_DATA:
        const data = action.data;
        const fixtureUsageDetails = _.orderBy(_.get(data, 'flowEvents.0.events', []), ['startAt'], ['desc']) || [];
        const macAddress = _.get(data, 'items.0');
        const fixtures = _.get(data, 'items.0.fixtures', []);

        return {
          ...state,
          isLearning: data.isLearning,
          hasNoDataToDisplay: data.hasNoDataToDisplay,
          isLoading: false,
          deviceId: macAddress,
          fixtureUsageDetails: fixtureUsageDetails.map(x => ({
            ...x,
            type: x.predicted.id,
            fixture: x.predicted.displayText,
            start: x.startAt,
            end: x.endAt,
            flow: convertFromGallons(x.totalGal, action.unitSystem),
            unitSystem: action.unitSystem
          })),
          hasMoreFixtureUsageDetails: fixtureUsageDetails.length > 0,
          fixturesUsage:  _.orderBy(
            (fixtures  || []).map((x, index) => ({
              ...x,
              count: x.totalEvents,
              name: x.displayText,
              type: x.id,
              color: getColorByType(x.id),
              value: convertFromGallons(x.totalGallons, action.unitSystem),
              unitSystem: action.unitSystem
            })), ['totalGallons'], ['desc']
          ),
        };
        break;
      case UPDATE_FLOW_EVENTS:
        const flowEventsData = _.orderBy(_.get(action.data, 'flowEvents.0.events', []), ['startAt'], ['desc']) || [];
        const flowEvents = flowEventsData.map(x => ({
          ...x,
          type: x.predicted.id,
          fixture: x.predicted.displayText,
          start: x.startAt,
          end: x.endAt,
          flow: convertFromGallons(x.totalGal, action.unitSystem),
          unitSystem: action.unitSystem
        }));

        return {
          ...state,
          fixtureUsageDetails: [
            ...state.fixtureUsageDetails,
            ...flowEvents
          ],
          hasMoreFixtureUsageDetails: flowEvents.length > 0,
          isUsageDetailsLoading: false,
        };
        break;
      case INITIALIZE_FLOW_EVENTS_NEW_PAGE:
        return  {
          ...state,
          hasMoreFixtureUsageDetails: false,
          isUsageDetailsLoading: true,
        };
        break;
      default: break;
    }
  }
  return state;
}

export default fixtureDetection;
