import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const isObject = val => {
  return typeof val === 'object' && val !== null;
};

export const classnames = (...args) => {
  const classes = [];
  args.forEach(arg => {
    if (typeof arg === 'string') {
      classes.push(arg);
    } else if (isObject(arg)) {
      Object.keys(arg).forEach(key => {
        if (arg[key]) {
          classes.push(key);
        }
      });
    } else {
      throw new Error(
        '`classnames` only accepts string or object as arguments'
      );
    }
  });

  return classes.join(' ');
};

function translateErrorFromGoogleAPI(status) {
  if(status === 'ZERO_RESULTS') {
    return "Sorry, we couldn't find anything matching your search. Please try again.";
  }

  return '';
}

class PlacesSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      errorMessage: null
    };
    this.landingPlaceSearchInput = null;

    this.setlandingPlaceSearchRef = element => {
      this.landingPlaceSearchInput = element;
    };
  }

  handleChange = address => {
    this.setState({
      address,
      errorMessage: null,
    });
  };

  handleSelect = address => {
    this.setState({
      address
    });

    geocodeByAddress(address)
      .then(res => Promise.all([getLatLng(res[0]), res]))
      .then(([{ lat, lng }, [ placesResponse ]]) => {
        const selected = {
          address: placesResponse.formatted_address,
          latitude: lat,
          longitude: lng,
          placesResponse: placesResponse
        };

        this.props.handleSelect(selected);
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  handleCloseClick = () => {
    this.setState({
      address: ''
    });
  };

  handleError = (status, clearSuggestions) => {
    console.log('Error from Google Maps API', status);
    this.setState({ errorMessage: translateErrorFromGoogleAPI(status) }, () => {
      clearSuggestions();
    });
  };

  showSuggestions = () => {
    if(this.state.address !== '') {
      this.landingPlaceSearchInput.fetchPredictions();
    }
  }

  render() {
    const {
      address,
    } = this.state;

    const errorMessage = this.state.errorMessage || this.props.errorMessage;

    return (
      <div className="search-bar-component">
        <PlacesAutocomplete
          ref={this.setlandingPlaceSearchRef}
          onChange={this.handleChange}
          value={address}
          onSelect={this.handleSelect}
          onError={this.handleError}
          shouldFetchSuggestions={true}
          searchOptions={this.props.searchOptions}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            return (
              <div className="search-bar-container">
                <div className="search-input-container">
                  <input
                    {...getInputProps({
                      placeholder: this.props.placeholder || 'Search Places...',
                      autoFocus: this.props.autoFocus,
                      className: 'search-input',
                      onFocus: this.showSuggestions
                    })}
                  />

                  {this.state.address.length > 0 && (
                    <button
                      className="clear-button"
                      onClick={this.handleCloseClick}
                    >
                      x
                    </button>
                  )}
                </div>
                {suggestions.length > 0 && (
                  <div className="autocomplete-container">
                    {suggestions.map(suggestion => {
                      const className = classnames('suggestion-item', {
                        'suggestion-item--active': suggestion.active,
                      });

                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, { className })}
                        >
                          <strong>
                            {suggestion.formattedSuggestion.mainText}
                          </strong>{' '}
                          <small>
                            {suggestion.formattedSuggestion.secondaryText}
                          </small>
                        </div>
                      );
                    })}
                    <div className="dropdown-footer">
                      <div>
                        <img
                          src="/assets/images/powered_by_google_default.png"
                          className="dropdown-footer-image"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        </PlacesAutocomplete>
        {errorMessage && errorMessage.length > 0 && (
          <div className="error-message">{errorMessage}</div>
        )}
      </div>
    );
  }
}

export default PlacesSearchBar;
