"use strict";

import React, { PureComponent }     from "react";
import Yup                          from 'yup';
import { Formik, Field }            from 'formik';
import { passwordComplexityRegex }  from "constants/regex";

class AccountCreationInnerForm extends PureComponent {
  render() {
    const {
      values,
      errors,
      touched,
      handleSubmit,
      isSubmitting,
      errorMessage
    } = this.props;
    return (
      <form onSubmit={handleSubmit} className="form" novalidate>
        { errorMessage !== '' &&
        <div className="message-container m-3">
          <span className="error-message">{ errorMessage }</span>
        </div>
        }

        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-8 col-md-6 col-lg-4 col-xl-4">
            <div className="form-group">
              <Field
                type="email"
                name="email"
                placeholder="Email address"
                className={ "form-control"  + ( (touched.email && errors.email) ? " invalid" : "" )  }
                value={values.email}
              />
              { touched.email && errors.email &&
                <div className="message-container">
                  <span className="error-message">
                    {errors.email}
                  </span>
                </div>
              }
            </div>
            <div className="form-group">
              <Field
                type="password"
                name="password"
                placeholder="Create a Password"
                className={ "form-control"  + ( (touched.password && errors.password) ? " invalid" : "" )  }
                value={values.password}
              />
              { touched.password && errors.password &&
              <div className="message-container">
                  <span className="error-message">
                    {errors.password}
                  </span>
              </div>
              }
            </div>
            <div className="form-group">
              <Field
                type="password"
                name="passwordConfirmation"
                placeholder="Confirm Password"
                className={ "form-control"  + ( (touched.passwordConfirmation && errors.passwordConfirmation) ? " invalid" : "" )  }
                value={values.passwordConfirmation}
              />
              { touched.passwordConfirmation && errors.passwordConfirmation &&
              <div className="message-container">
                  <span className="error-message">
                    {errors.passwordConfirmation}
                  </span>
              </div>
              }
            </div>
            <button className="btn-landing btn-light-blue-landing btn-block" onClick={ handleSubmit } disabled={isSubmitting}>
              Send me my plan
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const formSchema = Yup.object().shape({
  email: Yup
    .string()
    .required("Required")
    .email("Should be a valid email."),
  password: Yup
    .string()
    .required("Required")
    .matches(passwordComplexityRegex, 'Password should have at least 8 characters, 1 capital, 1 lowercase and 1 digit.'),
  passwordConfirmation: Yup
    .string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match")
    .required('Required')
});

class AccountCreationForm extends PureComponent {
  render() {
    const errorMessage = this.props.errorMessage;

    return (
      <Formik
        initialValues={{
          email: "",
          password: "",
          passwordConfirmation: ""
        }}
        enableReinitialize={ true }
        validationSchema={ formSchema }
        onSubmit={(
          values,
          { setSubmitting }
        ) => {
          const result = this.props.onSubmit(values);

          if(Promise.resolve(result) == result) {
            result
              .then(_ => setSubmitting(false))
              .catch(_ => setSubmitting(false));
          } else {
            setSubmitting(false);
          }
        }}
        render={
          formikProps =>
            <AccountCreationInnerForm
              { ...formikProps }
              errorMessage={ errorMessage }
              handleChange={ (event) => this.handleChange(formikProps, event) }
            />
        }
      />
    );
  }
}

export default AccountCreationForm;
