"use strict";

import axios  from "axios";
import config from "config";

const subscriptionUrl = `${config.environment.api.uri}/api/v1/subscriptions`;
const subscriptionPlanByPlanIdUrl = planId => `${subscriptionUrl}/plan/${planId}`;

// API V2
const apiURIV2 = config.environment.apiV2.uri + "/api/v2";
const subscribeURIV2 = `${apiURIV2}/subscriptions`;
const paymentURIV2 = `${subscribeURIV2}/payment`;
const getPaymentInfoURIV2 = userId => `${paymentURIV2}?userId=${userId}`;
const subscriptionByIdURIV2 = id => `${subscribeURIV2}/${id}`;

/**
 * Subscription Interface
 */
class _Subscription {

  subscribe(stripeToken, userId, locationId, planId, sourceId, couponId) {
    const payload = {
      user: { id: userId },
      location: { id: locationId },
      plan: { id: planId },
      sourceId: sourceId,
      provider: {
        "name": "stripe",
        "token": stripeToken,
        "couponId": couponId
      }
    };
    return axios.post(subscribeURIV2, payload);
  }

  updatePaymentMethod(stripeToken, userId) {
    const payload = {
      userId: userId,
      provider: {
        name: "stripe",
        token: stripeToken
      }
    };
    return axios.post(paymentURIV2, payload);
  }

  retrievePaymentInfo(userId) {
    return axios.get(getPaymentInfoURIV2(userId)).then(result => result.data);
  }

  cancelSubscription(subscriptionId, reason) {
    const data = {
      cancellationReason: reason
    };
    return axios.delete(subscriptionByIdURIV2(subscriptionId), { data });
  }

  // Legacy endpoints

  retieveCouponInfo(couponId) {
    return axios.get(`${subscriptionUrl}/coupon/${couponId}`).then(result => result.data);
  }

  retrieveSubscriptionPlan(planId) {
    return axios.get(subscriptionPlanByPlanIdUrl(planId)).then(result => result.data);
  }
}

/**
 * @ignore
 */
export const Subscription = new _Subscription();
export default Subscription;
