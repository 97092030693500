"use strict";

import React, { Component } from "react";
import { Link } from "react-router";

/**
 * Individual Tab Component
 */
export class Tab extends Component {
  /**
   * React lifecycle: Constructor, Build's component's state
   * @constructor
   */
	constructor( props ) {
    super();
    this.state = {
			...props,
      selected: props.selected ? true : false,
    }
  }
	componentWillReceiveProps( nextProps ){
    this.setState( { ...nextProps } );
  }
  shouldComponentUpdate( nextProps, nextState ){
    return JSON.stringify( this.state ) !== JSON.stringify( nextState );
  }
	/**
   * React Lifecycle: render - Is fired when the <Tab /> component is leveraged to draw to the screen.
   * @return { JSX } - a JSX Object
   */
  render() {
    return (
      <li className={ "tab " + ( this.state.selected ? "active" : "" ) }>
        <Link to={ this.state.link }>{ this.props.children }</Link>
      </li>
    )
  }
}

/**
 * Subnav tab bar component
 * @extends Component
 */
export class TabBar extends Component {
	/**
   * React Lifecycle: render - Is fired when the <TabBar /> component is leveraged to draw to the screen.
   * @return { JSX } - a JSX Object
   */
	render() {
    return (
      <section className={ "flo-tab-bar-container" } onMouseEnter={ this.props.onMouseEnter } onMouseLeave={ this.props.onMouseLeave }>
        <nav className={ "flo-tab-bar row " + this.props.className } onClick={ this.props.onClick }>
          <div className="col-12">
            <ul className="tabs">
              { this.props.children }
            </ul>
          </div>
        </nav>
      </section>
    )
  }
}

/**
 * Wrapper for TabBar component within the Flo User Portal
 * @extends Component
 */
export class FloTabBar extends Component {
	/**
   * React lifecycle: Constructor, Build's component's state
   * @constructor
   */
	constructor( props ){
		super();
		this.state = { ...props }
	}
  componentWillReceiveProps( nextProps ){
    this.setState( { ...nextProps } );
  }
	shouldComponentUpdate( nextProps, nextState ){
    return JSON.stringify( this.state ) !== JSON.stringify( nextState );
	}
  /**
   * React Lifecycle: render - Is fired when the <FloTabBar /> component is leveraged to draw to the screen.
   * @return { JSX } - a JSX Object
   */
  render(){
    return (
     	<TabBar className={ this.state.className } onMouseEnter={ this.props.onMouseEnter } onMouseLeave={ this.props.onMouseLeave } onClick={ this.props.onClick }>
				{ this.state.items.map( ( elem, i ) => {
					return (
						<Tab key={i} selected={ this.state.selected === elem } link={ "/" + this.state.path + "/" + elem}>
							{ elem.replace(/-/g, " ") }
						</Tab>
					);
				})}
			</TabBar>
    )
  }
}

export default FloTabBar;
