"use strict";

import React, { Component }     from "react";
import { Link, browserHistory } from "react-router";
import { connect }              from "react-redux";
import AlertNotification        from "elements/AlertNotification";
import Alert                    from "actions/alert";
import Authentication           from "actions/authentication";
import FloProtectActions       from 'actions/floprotect';
import PropTypes                from 'prop-types';
import ShieldLogo               from 'elements/ShieldLogo';
import Device                   from 'actions/device';
import LocationProfileActions   from "actions/locationProfile";
import _                        from 'lodash';

import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';

/**
 * Header component
 */
export class Header extends Component {
  /**
   * React lifecycle: Constructor, Build's component's state
   * @param { props } - Initial incomming properties on the component
   */
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
	}

  componentDidMount() {
    FloProtectActions.getLocationSubscriptionInfo();
    Alert.hydrateStore();
    LocationProfileActions.hydrateStore();
    Authentication.startSendingPresence();
  }

  componentWillUnmount() {
    Device.unsubscribeAllDeviceStatus();
    Authentication.stopSendingPresence();
  }

  getCurrentRouteName() {
    const  routes = this.context.router.routes;
    const index = routes.length - 2;
    return index > 0 ? routes[routes.length - 2].name : '';
  }

  getActiveClassIfNecessary(currentRouteName, linkRouteName) {
    return currentRouteName === linkRouteName ? ' active' : '';
  }

  floProtectLink(linkClasses, badgeClass) {
    const isSubscriptionActive = this.props.floProtect.enabled && !this.props.cancelAtPeriodEnd;
    return (
      <UncontrolledDropdown nav inNavbar className={ linkClasses }>
        <DropdownToggle nav caret to="/floprotect">
          { this.props.floProtect.enabled ? (
            <span>
              <div className="d-inline">
                <ShieldLogo isActive={ this.props.floProtect.enabled } size="small" ref="floprotect" />
              </div>
              <div className="d-inline align-middle">
                FloProtect Plan<Badge className={ "flo-green " + badgeClass } pill>On</Badge>
              </div>
            </span>
          ) : (
            <span>
              <div className="d-inline">
                <ShieldLogo isActive={ this.props.floProtect.enabled } size="small" ref="floprotect" />
              </div>
              <div className="d-inline align-middle">
                FloProtect Plan<Badge className={ "flo-red " + badgeClass } pill>Off</Badge>
              </div>
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag={Link} to="/floprotect/deductible-guarantee">
            <Link className="nav-link">
              We-Pay Guarantee
            </Link>
          </DropdownItem>
          <DropdownItem tag={Link} to="/floprotect">
            <Link className="nav-link">
              Manage your Plan
            </Link>
          </DropdownItem>
          { isSubscriptionActive &&
          <DropdownItem tag={Link} to="/floprotect" onClick={ this.handleChangePaymentMethod }>
            <Link className="nav-link">
              Change Payment Method
            </Link>
          </DropdownItem>
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    );

    /*
    return (
      <Link className={ "nav-link " + linkClasses } to="/floprotect">
        { this.props.floProtect.enabled ? (
          <span>
            <div className="d-inline">
              <ShieldLogo isActive={ this.props.floProtect.enabled } size="small" ref="floprotect" />
            </div>
            <div className="d-inline align-middle">
              FloProtect <Badge className={ "flo-green " + badgeClass } pill>On</Badge>
            </div>
          </span>
        ) : (
          <span>
            <div className="d-inline">
              <ShieldLogo isActive={ this.props.floProtect.enabled } size="small" ref="floprotect" />
            </div>
            <div className="d-inline align-middle">
              FloProtect <Badge className={ "flo-light-blue " + badgeClass } pill>Off</Badge>
            </div>
          </span>
        )}
      </Link>
    );
    */
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleChangePaymentMethod = () => {
    FloProtectActions.updatePaymentMethodFormInfo({ isModalOpen: true });
    FloProtectActions.loadPaymentInfo(this.props.userId);
  }

  logout = () => {
    Authentication.logout();
  }

  render() {
    const currentCurrentRouteName = this.getCurrentRouteName();

    return (
      <Navbar dark expand="md">
        <NavbarBrand href="#">
          <img src="/assets/images/FloByMoenLogo-White.png" className="white-logo" alt="Flo Technologies" width="45" height="45" />
        </NavbarBrand>
        <button type="button" className="navbar-toggler" onClick={this.toggle} >
          <span><i className="fas fa-bars"></i></span>
        </button>
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-left main-menu" navbar>
            <NavItem>
              <span className="nav-link address">{ this.props.fullAddress }</span>
            </NavItem>
            <NavItem className={ this.getActiveClassIfNecessary(currentCurrentRouteName, 'home') }>
              <Link className="nav-link" to="/home">
                Home
              </Link>
            </NavItem>
            <NavItem className={ this.getActiveClassIfNecessary(currentCurrentRouteName, 'usage') }>
              <Link className="nav-link" to="/usage">
                Usage
              </Link>
            </NavItem>
            <NavItem className={ this.getActiveClassIfNecessary(currentCurrentRouteName, 'alerts') }>
              <Link className="nav-link" to="/alerts">
                Alerts
                <span className="float-right">
                  { this.props.alert.pendingTotal > 0 &&
                    <span className="badge badge-pill flo-yellow d-md-none">{ this.props.alert.pendingTotal }</span>
                  }
                </span>
              </Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" href="https://solutions.moen.com/Smart_Water_Security_Products/Help_Center" target="_blank">
                Support
              </Link>
            </NavItem>
            <NavItem className={ this.getActiveClassIfNecessary(currentCurrentRouteName, 'floprotect') + " sub-menu" }>
              { this.floProtectLink("d-none d-md-block", "") }
              { this.floProtectLink("d-md-none", "float-right") }
            </NavItem>
            <NavItem className={ this.getActiveClassIfNecessary(currentCurrentRouteName, 'insurance-letter') }>
              <Link className="nav-link" to="/insurance-letter">
                Insurance Letter
              </Link>
            </NavItem>
          </Nav>
          <Nav className="ml-auto" navbar>
            {/* { this.props.hasDevices &&
            <NavItem className="d-none d-lg-block">
              <div className="d-flex flex-row">
                <AlertNotification
                  url="/alerts"
                  deviceStatus={ this.props.deviceStatus }
                  count={ this.props.alert.pendingTotal }
                />
              </div>
            </NavItem>
            } */}
          </Nav>
          <Nav className="settings sub-menu" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Settings
              </DropdownToggle>
              <DropdownMenu right>
                {/* <DropdownItem tag={Link} to="/friends">
                  <Link className="nav-link">
                    Share Flo <span className="flo-green badge badge-secondary badge-pill mt-0">New</span>
                  </Link>
                </DropdownItem> */}
                <DropdownItem tag={Link} to="/settings/user-profile">
                  <Link className="nav-link">
                    User Profile
                  </Link>
                </DropdownItem>
                <DropdownItem tag={Link} to="/settings/alert-settings">
                  <Link className="nav-link">
                    Alert Settings
                  </Link>
                </DropdownItem>
                <DropdownItem tag={Link} to="/settings/contacts">
                  <Link className="nav-link">
                    Contacts
                  </Link>
                </DropdownItem>
                <DropdownItem tag={Link} to="/settings/devices">
                  <Link className="nav-link">
                    Devices
                  </Link>
                </DropdownItem>
                <DropdownItem tag={Link} to="/settings/consumption-goals">
                  <Link className="nav-link">
                    Consumption Goals
                  </Link>
                </DropdownItem>
                <DropdownItem tag={Link} to="/settings/home-profile">
                  <Link className="nav-link">
                    Home Profile
                  </Link>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem tag="a" className="cursor-pointer" onClick={ this.logout }>
                  <a className="nav-link">
                    Logout
                  </a>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

Header.contextTypes = {
  router: PropTypes.object.isRequired
};

function capitalizeAllWords(words) {
  return (words || '').split(' ').map(x => _.capitalize(x)).join(' ');
}

export default connect(store => {
  const deviceId = store.device.currentMacAddress;
  const address = capitalizeAllWords(store.locationProfile.location.address);
  const address2 = store.locationProfile.location.address2 ? ` ${store.locationProfile.location.address2}` : '';
  const city = capitalizeAllWords(store.locationProfile.location.city);
  const state = (store.locationProfile.location.state) ? store.locationProfile.location.state.toUpperCase() : "";
  const fullAddress = state === '' ? '' : `${address}${address2}, ${city}, ${state}`;

  return {
    floProtect: {enabled: store.subscription.isSubscribedToFloProtect},
    alert: {...store.alert},
    deviceId,
    deviceStatus: store.device.status,
    hasDevices: store.device.list.length > 0,
    fullAddress,
    userId: store.user.id,
    cancelAtPeriodEnd: store.subscription.cancelAtPeriodEnd,
  };
})(Header);
