import _ from "lodash";

const Period = {
  LAST_DAY: {
    key: 'last_day',
    currentDisplayText: 'Current Day',
    prevDisplayText: 'Previous Day' 
  },
  LAST_24_HOURS: {
    key: 'last_24_hours',
    currentDisplayText: 'Last 24 Hours',
    prevDisplayText: 'Previous 24 Hours',
  },
  THIS_WEEK: {
    key: 'this_week',
    currentDisplayText: 'Current Week',
    prevDisplayText: 'Previous Week',
  },
  LAST_28_DAYS: {
    key: 'last_28_days',
    currentDisplayText: 'Last 28 Days',
    prevDisplayText: 'Previous 28 Days',
  },
  LAST_12_MONTH: {
    key: 'last_12_months',
    currentDisplayText: 'Last 12 Months',
    prevDisplayText: 'Previous 12 Months'
  }
};

function fromKey(key) {
  return _.values( _.pickBy(Period, (value, k) => {
    return value.key == key;
  }))[0];
}

export default {
  ...Period,
  fromKey,
};
