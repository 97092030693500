import _ from 'lodash';
import {
  SEND_EMAIL_CHANGE_CONFIRMATION,
  SEND_EMAIL_CHANGE_CONFIRMATION_SUCCESS,
  SEND_EMAIL_CHANGE_CONFIRMATION_ERROR,
  SEND_EMAIL_CHANGE_REQUEST,
  SEND_EMAIL_CHANGE_REQUEST_SUCCESS,
  SEND_EMAIL_CHANGE_REQUEST_ERROR,
  CLEAN_EMAIL_CHANGE_REQUEST_DATA,
} from "constants/action";
import { Authenticate } from "actions/authentication";
import Store from "store";
import UserAPI from "api/user";
import AuthenticationAction from "./authentication";

const sendEmailChangeConfirmation = (id, key, type) => ({
  type: SEND_EMAIL_CHANGE_CONFIRMATION,
  payload: { id, key, type }
});

const sendEmailChangeConfirmationSuccess = data => ({
  type: SEND_EMAIL_CHANGE_CONFIRMATION_SUCCESS,
  payload: { data }
});

const sendEmailChangeConfirmationError = (message, status) => ({
  type: SEND_EMAIL_CHANGE_CONFIRMATION_ERROR,
  payload: { message, status }
});

const sendEmailChangeRequest = newEmail => ({
  type: SEND_EMAIL_CHANGE_REQUEST,
  payload: { newEmail }
});

const sendEmailChangeRequestSuccess = data => ({
  type: SEND_EMAIL_CHANGE_REQUEST_SUCCESS,
  payload: { data }
});

const sendEmailChangeRequestError = (message, status) => ({
  type: SEND_EMAIL_CHANGE_REQUEST_ERROR,
  payload: { message, status }
});

const cleanEmailChangeRequestData = () => ({
  type: CLEAN_EMAIL_CHANGE_REQUEST_DATA,
});

class _EmailChange {

  @Authenticate()
  sendEmailChangeConfirmation(id, key, type) {
    Store.dispatch(sendEmailChangeConfirmation(id, key, type));

    return AuthenticationAction.getUserTokenInfo()
      .then(userData => {
        const { [ "user_id" ]: userId } = userData;
        return UserAPI.confirmEmailChange(userId, id, key, type);
      })
      .then(response => {
        Store.dispatch(sendEmailChangeConfirmationSuccess(response));
      })
      .catch(err => {
        if (err.response && err.response.status === 409) {
          Store.dispatch(sendEmailChangeConfirmationError(_.get(err, 'response.data.message', 'The new Email is already in use'), 'emailExists'));
        } else if (err.response && err.response.status === 403) {
          Store.dispatch(sendEmailChangeConfirmationError('Invalid confirmation key.', 'invalidKey'));
        } else {
          Store.dispatch(sendEmailChangeConfirmationError(`There was an error sending email change confirmation: ${err}`, 'server'));
        }
      });
  }

  @Authenticate()
  sendEmailChangeRequest(newEmail) {
    Store.dispatch(sendEmailChangeRequest(newEmail));

    return AuthenticationAction.getUserTokenInfo()
      .then(userData => {
        const { [ "user_id" ]: userId } = userData;
        return UserAPI.requestEmailChange(userId, newEmail);
      })
      .then(response => {
        Store.dispatch(sendEmailChangeRequestSuccess(response));
        return response;
      })
      .catch(err => {
        if (err.response && err.response.status === 409) {
          Store.dispatch(sendEmailChangeRequestError(_.get(err, 'response.data.message', 'The new Email is already in use'), 'emailExists'));
        } else {
          Store.dispatch(sendEmailChangeRequestError('There was an error submitting an email change request', 'server'));
        }
      });
  }

  @Authenticate()
  cleanEmailChangeRequestData() {
    Store.dispatch(cleanEmailChangeRequestData());
  }
};
/**
 * @ignore
 */
export const EmailChange = new _EmailChange();
export default EmailChange;
