/**
 * Auth Token name
 */
export const AUTH_COOKIE_TOKEN = "authToken";
/**
 * Auth Token Value name
 */
export const AUTH_LOCAL_STORAGE_TOKEN_VALUE = "authTokenValue";
/**
 * Auth Token Expiration value name 
 */
export const AUTH_LOCAL_STORAGE_TOKEN_EXP = "authTokenExp";
