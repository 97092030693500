"use strict";

import React from 'react';

function ShieldLogo({ size, isActive }) {
  if(isActive) {
    return (<img src="/assets/images/shieldgreen@2x.png" className={ size + "-logo" } />);
  } else {
    return (<img src="/assets/images/shield@2x.png" className={ size + "-logo" } />);
  }
}

export default ShieldLogo;
