"use strict";

import {
  UPDATE_INSURANCE_LETTER_DOWNLOAD_INFO,
  UPDATE_INSURANCE_LETTER_STATUS,
  REDEEM_INSURANCE_LETTER,
  UPDATE_LOADING_FILE
} from "constants/action";

import { Authenticate } from "actions/authentication";
import Store from "store";
import InsuranceLetterStatus from "constants/InsuranceLetterStatus";
import InsuranceLetterAPI from "api/insuranceLetter";
import LocationSettingAPI from "api/setting/location";
import SubscriptionAPI from "api/subscription";
import moment from 'moment-timezone';
import LocationProfile from "./locationProfile";
import Authentication from "./authentication";
import FloProtectAction from "./floprotect";

const updateInsuranceLetterInfo = data => ({
  type: UPDATE_INSURANCE_LETTER_DOWNLOAD_INFO,
  data: data
});

const updateInsuranceLetterStatus = status => ({
  type: UPDATE_INSURANCE_LETTER_STATUS,
  data: { status }
});

const redeemInsuranceLetter = date => ({
  type: REDEEM_INSURANCE_LETTER,
  data: { date_redeemed: date }
});

const udpateLoadingFile = isLoading => ({
  type: UPDATE_LOADING_FILE,
  isLoading
});

class _InsuranceLetter {

  @Authenticate
  hydrateStore(mixpanel, sourceId) {
    return Promise.all([
      FloProtectAction.getLocationSubscriptionInfo(),
      this.loadInsuranceLetter()
    ]).then(([subscriptionInfo]) => {
        mixpanel.track(
          "Viewed Insurance Letter page",
          {
            "Source": sourceId,
            "Subscription Status": subscriptionInfo ? subscriptionInfo.status : 'inactive'
          }
        );
      });
  }

  @Authenticate
  loadInsuranceLetter() {
    Store.dispatch(udpateLoadingFile(true));
    return LocationProfile
      .hydrateStore()
      .then(locationData => Promise.all([
        locationData.id,
        InsuranceLetterAPI.generate(locationData.id)
      ]))
      .then(([locationId]) =>
        this.loadInsuranceLetterByLocation(locationId)
      );
  }

  @Authenticate
  loadInsuranceLetterByLocation(locationId) {
    return InsuranceLetterAPI
      .getDownloadInfo(locationId)
      .then(data => {
        if(data.status == InsuranceLetterStatus.READY) {
          fetch(data.document_download_url)
            .then(res => res.blob())
            .then(blob => {
              Store.dispatch(updateInsuranceLetterInfo({
                ...data,
                blobObjectUrl: URL.createObjectURL(blob)
              }));
              Store.dispatch(udpateLoadingFile(false));
            });
        } else {
          Store.dispatch(updateInsuranceLetterStatus(data.status));

          if(data.status == InsuranceLetterStatus.PROCESSING) {
            setTimeout(() => this.loadInsuranceLetterByLocation(locationId), 1000);
          }
        }
      });
  }

  @Authenticate
  download(mixpanel, sourceId, status) {
    return LocationProfile
      .hydrateStore()
      .then(locationData => {
        const locationId = locationData.id;

        return InsuranceLetterAPI
          .redeem(locationId)
          .then(() => {
            mixpanel.track(
              "Clicked Insurance Letter download",
              {
                "Source": sourceId,
                "Subscription Status": status
              }
            );

            Store.dispatch(redeemInsuranceLetter(moment.utc()));
          });
      });
  }
};
/**
 * @ignore
 */
export const InsuranceLetter = new _InsuranceLetter();
export default InsuranceLetter;
