"use strict";

import Store from "store";
import { from } from "rxjs";
import {
  UPDATE_QUERY_METADATA,
} from "constants/action";

const updateIsFetching = (isFetching, query) => ({
  type: UPDATE_QUERY_METADATA,
  query,
  data: { isFetching }
});

const updateObservableQuery = (observableQuery, query) => ({
  type: UPDATE_QUERY_METADATA,
  query,
  data: { observableQuery  }
});

const updateFetched = (query) => ({
  type: UPDATE_QUERY_METADATA,
  query,
  data: { fetched: true }
});


class SafeQuery {

  /**
   * Creates a safe query. That means that if the same query is called elsewhere while it's still fetching, 
   * it won't generate a new API request, it will just subscribe to the corresponding observer.
   *
   * @param {*} query The query name
   * @param {*} callback API function to call
   */
  createQuery(query, callback) {
    return (...args) => {
      const metadata = Store.getState().query.metadata[query];
      if (metadata.isFetching) {
        return this._subscribeToQueryObservable(metadata.observableQuery, query);
      } else {
        Store.dispatch(updateIsFetching(true, query));
  
        // Create the observable from API Promise
        const observable = from(callback(...args));
  
        Store.dispatch(updateObservableQuery(observable, query));
        return this._subscribeToQueryObservable(observable, query);
      }
    };
  }

  _subscribeToQueryObservable(observable, query) {
    return new Promise((resolve, reject) => {
      observable.subscribe(data => {
        Store.dispatch(updateIsFetching(false, query));
        resolve(data);
      }, error => {
        Store.dispatch(updateIsFetching(false, query));
        reject(error);
      });
    });
  }
};
/**
 * @ignore
 */
export default SafeQuery;
