"use strict";

import React, { Component } from "react";
import { Link } from "react-router";

/**
 * Footer UI component
 */
export default class Footer extends Component {
  /**
   * React Lifecycle: determines whether or not component should redraw
   * - Since this is the footer it should never need to redraw: Always returning false;
   * @return { Boolean } - Always false
   */
  shouldComponentUpdate(){
   return false;
  }
	/**
   * React Lifecycle: render - Is fired when the <Header /> component is leveraged to draw to the screen.
   * @return { JSX } - a JSX Object of the footer
   */
	render() {
    return (
      <footer className="footer">
        <div className="container footer-block">
          <div className="row d-none d-lg-flex">
            <div className="col-6">
              <div className="companyDisclosureLinks">
                <span className="privacy-policy"><Link to="/privacy-policy">Privacy Policy</Link></span>
                <span className="terms-of-service"><Link to="/terms-of-service">Terms of Service</Link></span>
                <span className="privacy-policy"><Link to="/license-agreement">License Agreement</Link></span>
              </div>
            </div>
            <div className="col-6 text-right">
              <span className="contactBlock text-center">
                <div className="d-inline p-2 text-white">
                  <span className="icon icon-Phone"></span>
                  <span className="context">
                    <span className="content"><a href="tel:844.MEET.FLO">844.MEET.FLO</a></span>
                  </span>
                </div>
                <div className="d-inline p-2 text-white">
                  <span className="icon icon-Envelope"></span>
                  <span className="context">
                    <span className="content"><a href="mailto:support@meetflo.com">support@meetflo.com</a></span>
                  </span>
                </div>
              </span>
            </div>
          </div>
          <div className="row d-lg-none">
            <div className="col-12 text-center bottom-margin">
              <div className="companyDisclosureLinks">
                <span className="privacy-policy"><Link to="/privacy-policy">Privacy Policy</Link></span>
                <span className="terms-of-service"><Link to="/terms-of-service">Terms of Service</Link></span>
                <span className="privacy-policy"><Link to="/license-agreement">License Agreement</Link></span>
              </div>
            </div>
            <div className="col-12 text-center">
              <span className="contactBlock text-center">
                <div className="d-inline p-2 text-white">
                  <span className="icon icon-Phone"></span>
                  <span className="context">
                    <span className="content"><a href="tel:844.MEET.FLO">844.MEET.FLO</a></span>
                  </span>
                </div>
                <div className="d-inline p-2 text-white">
                  <span className="icon icon-Envelope"></span>
                  <span className="context">
                    <span className="content"><a href="mailto:support@meetflo.com">support@meetflo.com</a></span>
                  </span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
