"use strict";

import React, { Component }   from "react";
import Header                 from "layout/landing/Header";
import Footer                 from "layout/landing/Footer";
import PlacesSearchBar        from "elements/PlacesSearchBar";
import {browserHistory}       from "react-router";
import {Helmet}               from "react-helmet";
import PropTypes              from "prop-types";
import GoogleTag              from "utils/GoogleTag";
import ReactUtils             from "utils/ReactUtils";

const searchOptions = {
  componentRestrictions: {country: "us"},
  types: ['address']
}

function getAddressComponent(placesResponse, componentNames) {
  return _.find(
    placesResponse.address_components,
    (o) => _.intersection(o.types, componentNames).length > 0
  );
}

function getAddressInNiceFormat(placesResponse) {
  const city = getAddressComponent(placesResponse, ['locality']) ||
    getAddressComponent(placesResponse, ['neighborhood']) ||
    getAddressComponent(placesResponse, ['sublocality_level_1']);

  return {
    streetName: getAddressComponent(placesResponse, ['route']),
    streetNumber: getAddressComponent(placesResponse, ['street_number']),
    city,
    state: getAddressComponent(placesResponse, ['administrative_area_level_1']),
    zipCode: getAddressComponent(placesResponse, ['postal_code']),
    country: getAddressComponent(placesResponse, ['country'])
  };
}

/**
 * AccountCreationLanding View
 */
export class AccountCreationLanding extends Component {

	constructor( props ) {
    super(props);
    this.state = {
      address: '',
      latitude: 0,
      longitude: 0,
      errorMessage: null
    };
  }

  componentDidMount() {
    GoogleTag.gtag('event', 'PageView', { 'url': ReactUtils.getFullPathFromProps(this.props) });
  }

  handleSelect = (selected) => {
	  const addressComponents = getAddressInNiceFormat(selected.placesResponse);

    this.setState({
      ...selected,
      ...addressComponents,
      errorMessage: null
    });
  }

  handleCheckMyAddress = (e) => {
	  if(this.state.address !== '' && this.state.streetNumber && this.state.zipCode) {
      localStorage.setItem('accountCreationInfo', JSON.stringify({
        ...this.state
      }));

      browserHistory.push('/l/location/confirmation');
    } else {
      this.setState({
        errorMessage: 'That doesn’t seem to be a valid address. Please try again.'
      });
    }
  }

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  render() {
    return (
      <section className="account-creation-landing">
        <Helmet>
          <title>Flo - Guarantee Your Home's Protection from Water Damage</title>
        </Helmet>

        <Header />

        <div className="jumbotron jumbotron-fluid">
          <div className="container text-center">
            <h1>Guarantee Your Home's Safety from Water Damage</h1>
            <p className="short-description mx-auto">
              Get a complete water damage prevention system for your home with 24/7 monitoring &
              <strong> all equipment included</strong>, for less than <strong>$1 per day*</strong>
            </p>

            <form className="form-places-search form-inline justify-content-center">
              <div className="form-group form-group-search">
                <PlacesSearchBar
                  placeholder="Enter Your Home Address"
                  handleSelect={ this.handleSelect }
                  searchOptions={searchOptions}
                  autoFocus={true}
                  errorMessage={ this.state.errorMessage }
                />
              </div>
              <button
                type="button"
                className="btn btn-lg"
                onClick={ this.handleCheckMyAddress }
              >
                See if my home is compatible
              </button>
            </form>
            <img className="press-logos" src="/assets/images/press-logos-alt@2x.png" />
          </div>
        </div>

        <div className="container-fluid">
          <div className="row did-you-know">
            <div className="col-12 text-center">
              <img className="light-bulb" src="/assets/images/light_on@2x.png" />
              Did you know your home is 2x as likely to suffer from water damage
              than from theft and fire combined?
            </div>
          </div>
        </div>

        <div className="container text-center ">
          <div className="row how-it-works">
            <div className="col-12">
              <h2>How it Works</h2>
            </div>
            <div className="col-4">
              <div className="step">1</div>
              <p className="step-description">
                We’ll ship your Flo System to your home free
              </p>
            </div>
            <div className="col-4">
              <div className="step">2</div>
              <p className="step-description">
                Once it arrives, we’ll send a certified installer to install it (no extra fee!)
              </p>
            </div>
            <div className="col-4">
              <div className="step">3</div>
              <p className="step-description">
                As soon as it’s installed, your home is protected from water damage 24/7.
              </p>
            </div>
            <div className="col-12">
              <p className="how-it-works-note">
                *With a 24-month monitoring contract. Early termination and installation fees apply. Excludes taxes.
                Applies to traditional services only. Certain markets excluded.
              </p>
            </div>
          </div>

          <div className="row what-includes">
            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 image">
              <img className="img-fluid" src="/assets/images/devices.png" />
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 description">
              <div>
                <h3>All equipment is included <strong>free</strong> & installed by professionals </h3>
                <p>A <strong>$500</strong> value</p>
              </div>
            </div>
          </div>

          <div className="row home-protect-features">
            <div className="col-12">
              <h2>
                <img className="shield" src="/assets/images/shieldgreen@2x.png" /> <strong>FloProtect</strong> included!
              </h2>
              <h3>The Flo Device + the FloProtect Add-On (included) <br />
                <strong>Guarantees</strong> Your Home’s Protection from Water Damage</h3>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="home-Protect-feature">
                <img src="/assets/images/stats.png" />
                <h4>24/7 Proactive Monitoring</h4>
                <p>
                  Our team of experts will monitor your home and will proactively suggest solutions for you if we
                  detect a problem.
                </p>
              </div>
              <div className="home-Protect-feature">
                <img src="/assets/images/auto-shutoff.png" />
                <h4>Auto Water Shut Off</h4>
                <p>
                  Flo will monitor your home 24/7 and if it detects a serious water issue, it will automatically turn
                  off your water to prevent damage.
                </p>
              </div>
              <div className="home-Protect-feature">
                <img src="/assets/images/alerts.png" />
                <h4>Alerts</h4>
                <p>
                  Flo uses machine learning technology to understand how water is used in your home. If it detects
                  something irregular, it’ll notify you.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 d-none d-md-block">
              <img className="img-fluid" src="/assets/images/phone@2x.png" />
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="home-Protect-feature">
                <img src="/assets/images/guarantee.png" />
                <h4>Water Damage Deductible Guarantee</h4>
                <p>
                  If your home has a Flo device installed and still gets water damage, we’ll cover the cost of your
                  insurance deductible, up to $1500.
                </p>
              </div>
              <div className="home-Protect-feature">
                <img src="/assets/images/chart.png" />
                <h4>Deep Usage Insights</h4>
                <p>
                  Our team of experts will monitor your home and will proactively suggest solutions for you if we
                  detect a problem.
                </p>
              </div>
              <div className="home-Protect-feature">
                <img src="/assets/images/iphone.png" />
                <h4>Free iOS and Andriod Apps</h4>
                <p>
                  You control Flo from your smartphone. Turn your water on or off from wherever you are.
                </p>
              </div>
            </div>
          </div>

          <div className="row align-items-center main-review">
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
              <img className="img-fluid" src="/assets/images/device.png" />
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
              <h3>
                “As soon as I stepped out of the gym at 8:10, my phone rang. It was the Flo system alerting me that
                it stopped damage.”
              </h3>
              <p>
                “The problem was the galvanized pipe supplying the toilet in the bathroom. The pipe had disintegrated
                at the base of the valve”
              </p>
              <strong>Albert in Los Angeles</strong>
            </div>
          </div>

          <div className="row reviews">
            <div className="col-12">
              <h3>Reviews from our Home Owners</h3>
              <img className="img-fluid" src="/assets/images/ratings.png" />

              <p>
                “I love my FLO! I can tell if my plumbing system is sound even from another state, and turn off the water
                if a leak is occurring. I can monitor my water usage, and tell the exact flow rate of my shower heads and
                faucets. It is an innovative idea, something everyone should consider for the protection of their home.”
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid text-center">
          <div className="row entire-home-protection">
            <div className="col-12">
              <h3>Entire Home Protection against Water Damage for Less than $1 a Day</h3>
              <p className="price">
                Guarantee Protection for just <span><strong>$25</strong>/month</span>
              </p>
              <p className="installation-cost">+ $99 one-time setup & installation fee</p>

              <button className="btn-landing btn-primary-landing btn-bg" onClick={this.scrollToTop}>
                Secure My Home From Water Damage
              </button>

              <div className="details">
                <span><i className="fas fa-check"></i> All equipment included</span>
                <span><i className="fas fa-check"></i> Professional Installation</span>
                <span><i className="fas fa-check"></i> 24/7 Monitoring</span>
                <span><i className="fas fa-check"></i> Water Damage Deductible Guarantee</span>
              </div>
            </div>
          </div>
        </div>

        <div className="container text-center">
          <div className="row saves-in">
            <div className="col-6">
              <h4>Saves Money on Your Water Bill</h4>
              <p>
                Flo detects all leaks in your home, which add up to hundreds of wasted gallons per month and thousands
                per year.
              </p>
            </div>
            <div className="col-6">
              <h4>Lower Your Homeowner Insurance</h4>
              <p>
                By installing a FLO Water Protection System for your home, you may save on your homeowner’s insurance.
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </section>
    )
  }
}

AccountCreationLanding.contextTypes = {
  mixpanel: PropTypes.object.isRequired
};

export default AccountCreationLanding;
