"use strict";

import _Alert from "./alert";
import _Authentication from "./authentication";
import _Consumption from "./consumption";
import _Device from "./device";
import _Setting from "./setting";
import _User from "./user";
import LocationProfile from "./locationProfile";
import Authorization from "./authorization";
import Subscription from "./subscription";
import EmailPreferences from "./emailPreferences";
import FixtureDetection from "./fixtureDetection";
import InsuranceLetter from "./insuranceLetter";
import EmailChange from "./emailChange";
import UserConfirmation from "./userConfirmation";
import Query from "./query";

/**
 * @ignore
 */
export const alert = _Alert;
/**
 * @ignore
 */
export const authentication = _Authentication;
/**
 * @ignore
 */
export const consumption = _Consumption;
/**
 * @ignore
 */
export const device = _Device;
/**
 * @ignore
 */
export const settings = _Setting;
/**
 * @ignore
 */
export const user = _User;

export const locationProfile = LocationProfile;

export const authorization = Authorization;

export const subscription = Subscription;

export const emailPreferences = EmailPreferences;

export const fixtureDetection = FixtureDetection;

export const insuranceLetter = InsuranceLetter;

export const emailChange = EmailChange;

export const userConfirmation = UserConfirmation;

export const query = Query;

export default {
  alert,
  authentication,
  consumption,
  device,
  settings,
  user,
  locationProfile,
  authorization,
  subscription,
  emailPreferences,
  fixtureDetection,
  insuranceLetter,
  query,
  emailChange,
  userConfirmation,
};
