import React, { PureComponent } from 'react';
import { connect }              from 'react-redux';
import {Helmet}                 from 'react-helmet';
import EmailChange              from 'actions/emailChange';

/**
 * EmailConfirmation View
 */
export class EmailChangeConfirmation extends PureComponent {

	constructor( props ) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { id, key, type } = this.props.params;
    EmailChange.sendEmailChangeConfirmation(id, key, type);
  }

  renderLoading() {
    return (
      <div className="row">
        <div className="loader-wrapper col-12">
          <div className="loader"></div>
        </div>
      </div>
    );
  }

  renderError() {
    const { errorStatus, errorMessage } = this.props;
    let title;
    let message;
    if (errorStatus === 'emailExists') {
      title = errorMessage;
      message = 'The confirmation process could not be completed as the new email is already in use. Please go to "Settings" -> "User Profile" -> "Change Email" and request a new email change.';
    } else if (errorStatus === 'invalidKey') {
      title = errorMessage;
      message = 'The provided confirmation key is invalid, or has already been used. For further information please contact our support team.';
    } else {
      title = 'There was an error confirming your email address';
      message = 'For further information please contact our support team.';
    }

    return (
      <div className="row confirmation-message">
        <div className="col-12 text-center">
          <img className="envelope" src="/assets/images/mail@2x.png" />
          <h1 className="title-error">{title}</h1>
          <h2>{message}</h2>
        </div>
      </div>
    );
  }

  renderConfirmationPending() {
    const { status, oldEmail, newEmail } = this.props.confirmationResult;
    const type = status === 'pendingNew' ? 'current' : 'new';
    return (
      <div className="row confirmation-message">
        <div className="col-12 text-center">
          <img className="envelope" src="/assets/images/mail@2x.png" />
          <h1 className="title-success">{`Your ${type} ${status === 'pendingNew' ? oldEmail : newEmail} email address has been confirmed!`}</h1>
          <h2>
            Last step: We've sent you a second confirmation email to <strong>{status === 'pendingNew' ? newEmail : oldEmail}</strong> to validate your {status === 'pendingNew' ? 'new' : 'current'} address. Please go to your
            inbox and click the link inside to complete the process.
          </h2>
        </div>
      </div>
    );
  }

  renderConfirmationCompleted() {
    const { newEmail } = this.props.confirmationResult;
    return (
      <div className="row confirmation-message">
        <div className="col-12 text-center">
          <img className="envelope" src="/assets/images/mail@2x.png" />
          <h1 className="title-success">{`Your email address has been changed to ${newEmail}!`}</h1>
          <h2>
            In order to reflect changes, you need to logout and login again.
          </h2>
        </div>
      </div>
    );
  }

  renderConfirmation() {
    const { confirmationResult, errorStatus } = this.props;

    if (errorStatus) {
      return this.renderError();
    } else if (confirmationResult.status !== 'completed') {
      return this.renderConfirmationPending();
    } else {
      return this.renderConfirmationCompleted();
    }
  }

  render() {
    const { processingConfirmation } = this.props;

    return (
      <section className="email-confirmation">
        <Helmet>
          <title>Flo - Email Change Confirmation</title>
        </Helmet>

        <div className="container main-container">
          { processingConfirmation ? this.renderLoading() : this.renderConfirmation() }
        </div>
      </section>
    )
  }
}

export default connect(state => ({
  processingConfirmation: state.emailChange.processingConfirmation,
  confirmationResult: state.emailChange.confirmationResult,
  errorMessage: state.emailChange.errorMessage,
  errorStatus: state.emailChange.errorStatus,
}))(EmailChangeConfirmation);
