"use strict";

import React, { Component } from "react";
import { connect }          from "react-redux";
import _                    from 'lodash';
import ReactEChart          from "elements/ReactEChart";
import DataDownloader       from '../elements/DataDownloader';
import { displayLongVolumeUnit } from 'constants/UnitSystem';
import Consumption          from 'constants/Consumption';

/**
 * Usage -> Total Consumption ( Graph View ) Component
 */
export class TotalConsumption extends Component {
	/**
   * React lifecycle: Constructor, Build's component's state
   * @constructor
   * @param { Object } props - Initial incomming properties used to set component's initial state
   */
  constructor( props ) {
    super(props);
  }

  render() {
    const filename = `total-consumption-${this.props.totalGraphOptions.period}.csv`.replace(/_/g , '-');

    return (
      <div className="usage total-consumption">
        <div className="row">
          <div className="col-12 bottom-margin">
            <div className="card h-100">
              <div className="card-header">
                <span>Total consumption </span>
              </div>
              <div className="card-body">
                <div className="controls-bar">
                  <div className="total-gallons-per-period">
                    <DataDownloader
                      className="float-right"
                      isBlocked={false}
                      data={this.props.totalGraphDownloadData}
                      filename={ filename }
                    />
                    <div className="current-period">
                      Total {_.capitalize(displayLongVolumeUnit(this.props.unitSystem))} Used ({ Consumption.fromKey(this.props.periodLength).currentDisplayText }) : &nbsp;
                      <span className="digit">{ Number( this.props.totalGraphOptions.series[ 0 ].data.reduce( ( a, b ) => ( a + b ), 0) ).toLocaleString( "en" ) }</span>
                    </div>
                    <div className="previous-period">
                      Total {_.capitalize(displayLongVolumeUnit(this.props.unitSystem))} Used ({ Consumption.fromKey(this.props.periodLength).prevDisplayText }):&nbsp;
                      <span className="digit">{ Number( this.props.totalGraphOptions.series[ 1 ].data.reduce( ( a, b ) => ( a + b ), 0) ).toLocaleString( "en" ) }</span>
                    </div>
                  </div>

                </div>

                <ReactEChart option={ this.props.totalGraphOptions } ref="consumptionGraph" showLoading={ false } />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function isEmptySeries(series) {
  return series.data.reduce( ( a, b ) => a + b, 0 ) == 0;
}

export default connect( store => {
  const consumption = store.consumption;
  const series = consumption.totalGraphOptions.series.slice(0);

  if (( consumption.periodLength === "28_days" || consumption.periodLength === "1_week" )
    && ( !isEmptySeries(series[0]) || !isEmptySeries(series[1]) ) ) {
    series.push({
      name: "Goal",
      animation: false,
      type: "line",
      data: new Array(series[0].data.length).fill(store.user.currentLocation.gallonsPerDayGoal),
      lineStyle: { normal: { color: "#11CC14" } },
      itemStyle: { normal: { opacity: 0, color: "#11CC14" } }
    });
  }

  return {
    isSubscribedToFloProtect: store.subscription.isSubscribedToFloProtect,
    totalGraphOptions: {
      ...consumption.totalGraphOptions,
      series: series
    },
    periodLength: consumption.periodLength || "last_day",
    totalGraphDownloadData: consumption.totalGraphDownloadData,
    unitSystem: store.user.unitSystem,
  };
})( TotalConsumption );
