import React, { PureComponent } from 'react';
import PropTypes from "prop-types";
import Yup                          from 'yup';
import { Formik, Field }            from 'formik';

class CancelSubscriptionInnerForm extends PureComponent {

  handleClose = event => {
    event.preventDefault();
    this.props.onClose();
  };

  render() {
    const {
      values,
      errors,
      touched,
      handleSubmit,
      isSubmitting,
      errorMessage
    } = this.props;
    return (
      <form onSubmit={handleSubmit} className="form" noValidate>
        { errorMessage !== '' &&
        <div className="message-container m-3">
          <span className="error-message">{ errorMessage }</span>
        </div>
        }

        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="form-group">
              <Field
                component="textarea"
                rows="8"
                name="reason"
                placeholder=""
                className={ "form-control"  + ( (touched.reason && errors.reason) ? " invalid" : "" )  }
                value={values.reason}
              />
              { touched.reason && errors.reason &&
              <div className="message-container">
                  <span className="error-message">
                    {errors.reason}
                  </span>
              </div>
              }
            </div>

            <div className="center-block">
              <button type="submit" className="btn btn-lg btn-subscribe btn-cancel-subscription btn-back" onClick={ this.handleClose } disabled={ isSubmitting }>Keep me protected</button>
              <button type="submit" className="btn btn-lg btn-subscribe btn-cancel-subscription btn-continue" onClick={ handleSubmit } disabled={ isSubmitting }>Deactivate now</button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const formSchema = Yup.object().shape({
  reason: Yup
    .string()
    .required('Required')
    .test('len', 'Required to enter at least 5 characters', val => val && val.length >= 5)
});

class CancelSubscriptionForm extends PureComponent {

  render() {
    const { errorMessage, onClose } = this.props;

    return (
      <Formik
        initialValues={{
          reason: ""
        }}
        enableReinitialize={ true }
        validationSchema={ formSchema }
        onSubmit={(
          values,
          { setSubmitting }
        ) => {
          const result = this.props.onSubmit(values);

          if(Promise.resolve(result) == result) {
            result
              .then(_ => setSubmitting(false))
              .catch(_ => setSubmitting(false));
          } else {
            setSubmitting(false);
          }
        }}
        render={
          formikProps =>
            <CancelSubscriptionInnerForm
              { ...formikProps }
              errorMessage  = { errorMessage }
              onClose       = { onClose }
              handleChange  = { (event) => this.handleChange(formikProps, event) }
            />
        }
      />
    );
  }
}

CancelSubscriptionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
};

export default CancelSubscriptionForm;
