"use strict";

import React from 'react';

function getDefaultOptionText(inputName, defaultOptionText) {
  return defaultOptionText ? defaultOptionText : "Select a " + inputName;
}

const SelectField = ({
                       input,
                       label,
                       type,
                       options,
                       className,
                       disabled,
                       showDefaultOption,
                       defaultOptionText,
                       meta: { touched, error, warning }
                     }) => (
  <div>
    <label>
      {label}
    </label>
    <select
      {...input}
      disabled={disabled}
      className={ className + ( (touched && error) ? " invalid" : "" )  }
    >
      {(typeof showDefaultOption === 'undefined' || showDefaultOption || defaultOptionText) &&
        <option value="">
          { getDefaultOptionText(input.name, defaultOptionText) }
        </option>
      }

      {options.map(( item, i ) => (
        <option value={item.value} key={i}>
          {item.display}
        </option>
      ))}
    </select>
    <span>
        {touched &&
        ((error &&
        <span className="error-message">
          {error}
        </span>) ||
        (warning &&
        <span>
          {warning}
        </span>))}
      </span>
  </div>


);

export default SelectField;
