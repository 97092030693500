"use strict";

import {
  AUTH_RECEIVE_TOKEN,
  AUTH_LOGIN_START,
  AUTH_LOGIN_STOP,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_CLEAR_ERR,
  AUTH_EXPIRE_TOKEN,
  AUTH_PASS_RESET_FAILURE,
  UPDATE_USER_TOKEN_INFO
} from "constants/action";

const initialState = {
  retrievingToken: false,
  loginErr       : null,
  passResetErr   : null,
  authToken      : {
    value:null,
    expiration: null
  },
  userTokenInfo: {}
};

/**
 * Autehntication Reducer
 * @param { Object } state - State of the 'Authentication' Store
 * @param { Object } action - Action Object. Always has action.type, which should match a constant
 * @return { Object } - Updated Authentication Store
 */
export const authentication = ( state = initialState, action ) => {
  let newState = { ...state };

  switch( action.type ){
    case AUTH_RECEIVE_TOKEN: {
      if( action.token ) {
        newState = { ...newState, ...{ authToken: { value: action.token.value, expiration: action.token.expiration } } };
      }
    };break;
    case AUTH_LOGIN_START:{
      newState = { ...newState, ...{ retrievingToken: true } };
    };break;
    case AUTH_LOGIN_STOP:{
      newState = { ...newState, ...{ retrievingToken: false } };
    };break;
    case AUTH_LOGIN_FAILURE:{
      newState = { ...newState, ...{ loginErr: typeof action.message === "object" ? action.message.message : action.message } };
    };break;
    case AUTH_LOGIN_CLEAR_ERR:{
      newState = { ...newState, loginErr: null, passResetErr: null };
    };break;
    case AUTH_EXPIRE_TOKEN:{
      newState = { ...newState, ...{ auhtToken: { value: null, expiration: null } } };
    };break;
    case UPDATE_USER_TOKEN_INFO:{
      newState = { ...newState, userTokenInfo: { ...action.data } };
    };break;
    default: break;
  }
  return newState;
}

export default authentication;
