"use strict";

import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import SelectField from "elements/SelectField";
import InputField from "elements/InputField";
import { required } from 'redux-form-validators';

class LocationProfileForm extends PureComponent {

  timezonesToDropDownData = () => {
    return this.props.timezones.map( timezone => ({ display: timezone.display, value: timezone.tz }) );
  }

  countriesToDropDownData = () => {
    return this.props.countries.map( country => ({ display: country.name, value: country.locale }) );
  }

  statesToDropDownData = () => {
    return this.props.states.map( state => ({ display: state.name, value: state.abbrev }) );
  }

  hasCurrentCountryStates = () => {
    return this.props.states && this.props.states.length > 0;
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={ handleSubmit }>
        <div className="form-group">
          <Field
            label="Street Address 1"
            name="address"
            component={ InputField }
            type="text"
            className="form-control"
            validate={required()}
          />
        </div>
        <div className="form-group">
          <Field
            label="Street Address 2"
            name="address2"
            component={ InputField }
            type="text"
            className="form-control"
          />
        </div>
        <div className="form-group">
          <Field
            label="City"
            name="city"
            component={ InputField }
            type="text"
            className="form-control"
            validate={required()}
          />
        </div>
        <div className="row">
          <div className="col form-group">
            <Field
              label="Country"
              name="country"
              options={ this.countriesToDropDownData() }
              component={ SelectField }
              onChange={ this.props.onCountryChange }
              className="form-control"
              validate={required()}
            />
          </div>
          { this.hasCurrentCountryStates() &&
          <div className="col form-group">
            <Field
              label="State"
              name="state"
              options={ this.statesToDropDownData() }
              component={ SelectField }
              onChange={ this.props.onStateChange }
              className="form-control"
            />
          </div>
          }
        </div>
        <div className="form-group">
          <Field
            label="Postal Code"
            name="postalCode"
            component={ InputField }
            type="text"
            className="form-control"
            validate={required()}
          />
        </div>
        <div className="form-group">
          <Field
            label="Time Zone"
            name="timezone"
            options={ this.timezonesToDropDownData() }
            component={ SelectField }
            className="form-control"
            validate={required()}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          ref="save"
          disabled={ this.props.submitting }
        >
          Save
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'user-localtion-profile-form',
  enableReinitialize : true
})(LocationProfileForm);
