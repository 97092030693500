"use strict";

import {
  UPDATE_QUERY_METADATA
} from "constants/action";

const initialState = {
  metadata: {
    "User.getUserById": {
      isFetching: false,
      fetched: false,
      observableQuery: undefined
    },
    "Authentication.getUserTokenInfo": {
      isFetching: false,
      fetched: false,
      observableQuery: undefined
    },
    "Location.fetchLocationById": {
      isFetching: false,
      fetched: false,
      observableQuery: undefined
    },
    "Consumption.getMeasurements.getMetrics": {
      isFetching: false,
      fetched: false,
      observableQuery: undefined
    },
    "Consumption.getWeatherTemperatureToday.getMetrics": {
      isFetching: false,
      fetched: false,
      observableQuery: undefined
    },
    "Consumption.getMeasurements.getPrevMeasurements": {
      isFetching: false,
      fetched: false,
      observableQuery: undefined
    },
    "Consumption.getMeasurements.getCurrentMeasurements": {
      isFetching: false,
      fetched: false,
      observableQuery: undefined
    },
    "Consumption.getDailyGoalConsumption.getMeasurements": {
      isFetching: false,
      fetched: false,
      observableQuery: undefined
    },
  }
};

/**
 * Query Metadata Reducer
 * @param { Object } state - State of the 'Authentication' Store
 * @param { Object } action - Action Object. Always has action.type, which should match a constant
 * @return { Object } - Updated Authentication Store
 */
export const query = ( state = initialState, action ) => {

  switch( action.type ){

    case UPDATE_QUERY_METADATA: {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          [action.query]: {
            ...state.metadata[action.query],
            ...action.data
          }
        }
      };
    }

    default: break;
  }
  return { ...state };
};

export default query;
