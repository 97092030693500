import React from "react";
import PropTypes from "prop-types";

import ReactEchartsCore from 'echarts-for-react/lib/core';

// then import echarts modules those you have used manually.
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/pie';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/title';
import 'elements/EChartTheme';

/**
 *  ReactECharts component wrapper / container
 *  @extends React.Component
 */
export default class ReactEcharts extends React.Component {
  /**
   * React Lifecycle: Initilizes the component and sets the initial state
   * @param { Object } props - Initial incomming properties on the component
   */
  constructor(props) {
    super(props);
    this.state = {
      option: props.option
    };
  }

  getEchartsInstance() {
    return this.echarts_react.getEchartsInstance();
  }

  /**
   * React Lifecycle: render - Is fired when the <ReactECharts /> component is leveraged to draw to the screen.
   * @return { JSX } - a JSX Object
   */
  render() {
    const style = this.props.style || { height: "400px" };

    return (
      <ReactEchartsCore
        ref={(e) => { this.echarts_react = e; }}
        echarts={echarts}
        option={this.props.option}
        notMerge={this.props.notMerge}
        lazyUpdate={this.props.lazyUpdate}
        theme={"theme_name"}
        onChartReady={this.props.onChartReady}
        onEvents={this.props.onEvents || []}
        style={style}
      />
    );
  }
}

ReactEcharts.propTypes = {
  option: PropTypes.object.isRequired,  // eslint-disable-line react/forbid-prop-types
  notMerge: PropTypes.bool,
  lazyUpdate: PropTypes.bool,
  style: PropTypes.object,  // eslint-disable-line react/forbid-prop-types
  className: PropTypes.string,
  theme: PropTypes.string,
  onChartReady: PropTypes.func,
  showLoading: PropTypes.bool,
  loadingOption: PropTypes.object,  // eslint-disable-line react/forbid-prop-types
  onEvents: PropTypes.object  // eslint-disable-line react/forbid-prop-types
};

ReactEcharts.defaultProps = {
  notMerge: false,
  lazyUpdate: true,
  style: { height: "400px" },
  className: "",
  theme: null,
  onChartReady: () => {},
  showLoading: false,
  loadingOption: null,
  onEvents: {},
};
