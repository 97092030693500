import axios from 'axios';
import config from 'config';

const apiV2BaseUri = `${config.environment.api.uri}/api/v2`;
const unsubscribeWeeklyEmailUri = (userId, locationId) => `${apiV2BaseUri}/emails/unsubscribe/weekly/${userId}/${locationId}`;
const subscribeWeeklyEmailUri = (userId, locationId) => `${apiV2BaseUri}/emails/subscribe/weekly/${userId}/${locationId}`;
const getUnsubscribedEmailPreferencesUri = (userId) => `${apiV2BaseUri}/emails/unsubscribe/${userId}`;

export const unsubscribeWeeklyEmail = (userId, locationId) => {
  return axios.post(unsubscribeWeeklyEmailUri(userId, locationId)).then(success => success.data);
};

export const subscribeWeeklyEmail = (userId, locationId) => {
  return axios.post(subscribeWeeklyEmailUri(userId, locationId)).then(success => success.data);
};

export const getUserUnsubscribedPreferences = (userId) => {
  return axios.get(getUnsubscribedEmailPreferencesUri(userId))
    .then(success => {
      return success.data;
    })
    .catch(err => {
      if (err.response && err.response.status === 404 && err.response.data.message === 'email not found') {
        return Promise.resolve({
          emailTypes: []
        });
      }
      return Promise.reject(err);
    });
};
