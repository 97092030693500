"use strict";

import axios from "axios";
import config from "config";

const userRegistrationURI = `${config.environment.api.uri}/api/v1/userregistration`;
const emailAvailabilityURI = `${userRegistrationURI}/email`;
const registerUserWebURI = `${userRegistrationURI}/register/web`;
const verifyAccountURI = `${userRegistrationURI}/verify`;
const resendEmailConfirmationURI = `${userRegistrationURI}/resend`;

/**
 * UserRegistration API Interface
 */
class _UserRegistration {
  checkEmailAvailability(email) {
    return axios
      .post(emailAvailabilityURI, { email })
      .then(res => res.data);
  }

  verifyAccount(token) {
    return axios
      .post(verifyAccountURI, { token })
      .then(res => res.data);
  }

  resendEmailConfirmation(email) {
    return axios
      .post(resendEmailConfirmationURI, { email })
      .then(res => res.data);
  }

  registerUserOnWeb(userInfo) {
    return axios
      .post(registerUserWebURI, userInfo)
      .then(res => res.data);
  }
}

/**
 * @ignore
 */
export const UserRegistration = new _UserRegistration();
export default UserRegistration;
