import _ from 'lodash';
import {
  SEND_USER_CONFIRMATION,
  SEND_USER_CONFIRMATION_SUCCESS,
  SEND_USER_CONFIRMATION_ERROR,
} from "constants/action";

import Store from "store";
import UserAPI from "api/user";

const sendUserConfirmation = (token) => ({
  type: SEND_USER_CONFIRMATION,
  payload: { token }
});

const sendUserConfirmationSuccess = data => ({
  type: SEND_USER_CONFIRMATION_SUCCESS,
  payload: { data }
});

const sendUserConfirmationError = (message, status) => ({
  type: SEND_USER_CONFIRMATION_ERROR,
  payload: { message, status }
});

class _UserConfirmation {

  sendUserConfirmation(token) {
    Store.dispatch(sendUserConfirmation());

    return UserAPI.verifyUserRegistration(token)
      .then(response => {
        Store.dispatch(sendUserConfirmationSuccess(response));
        return response;
      })
      .catch(err => {
        if (err.response && err.response.status === 409) {
          Store.dispatch(sendUserConfirmationError(_.get(err, 'response.data.message', 'The new Email is already in use'), 'emailExists'));
        } else if (err.response && err.response.status === 400) {
          Store.dispatch(sendUserConfirmationError('Invalid confirmation token.', 'invalidKey'));
        } 
        else {
          Store.dispatch(sendUserConfirmationError('There was an error submitting an email change request', 'server'));
        }
      });
  }

};
/**
 * @ignore
 */
export const UserConfirmation = new _UserConfirmation();
export default UserConfirmation;
