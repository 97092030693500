"use strict";

import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import Device from "actions/device";
import Dialog from "elements/Dialog";

/**
 *  Progress bar for Health Test Widget
 */
class InProgress extends PureComponent {
  constructor( props ){
    super(props);
  }

  render() {
    const remainingProgressStyle = {
      width: `${this.props.value}%`
    };

    return (
      <div className="progress">
        <div className="progress-bar flo-blue"
             role="progressbar"
             style={ remainingProgressStyle }
             aria-valuenow={ this.props.value }
             aria-valuemin="0"
             aria-valuemax="100">
        </div>
      </div>
    );
  }
}
InProgress.propTypes = {
  value: PropTypes.number.isRequired,
};
InProgress.defaultProps = {
  value: 0
};

/**
 * Button to start Health Test
 */
class RunTestButton extends PureComponent {
  constructor( props ){
    super(props);
  }

  render(){
    return ( <button className="btn btn-green-border" onClick={ this.props.onClick }>Manual health test</button> );
  }
}

/**
 * Health Check widget for header
 * @extends Component
 */
export class HealthCheckWidget extends Component {
  constructor( props ){
    super();
    this.state = {
      ...props,
    };
  }
  
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.store) {
      return null;
    }
    const nextDevice = nextProps.store.device.dict[ nextProps.store.device.currentMacAddress ];
    const nextStatus = nextDevice && nextDevice.firestore && nextDevice.firestore.healthTest && nextDevice.firestore.healthTest.status;

    const prevDevice = prevState.store.device.dict[ prevState.store.device.currentMacAddress ];
    const prevStatus = prevDevice && prevDevice.firestore && prevDevice.firestore.healthTest && prevDevice.firestore.healthTest.status;

    const startingPoint = ['running', 'pending'];
    const expectation = ['completed', 'canceled'];
    if (startingPoint.includes(prevStatus) && expectation.includes(nextStatus)) {
      const roundId = prevDevice.firestore.healthTest.roundId;
      Device.fetchHealthTestInfo(roundId);
    }
    return { ...nextProps };
  }

  /**
   * React Lifecycle: Is responsible for whether the component should update
   * @param { Object } nextProps - incomming properties from a react update
   * @param { Object } nextState - next potential state if component updates
   * @return { boolean } - whether device dictionary has updated at all
   */
  shouldComponentUpdate( nextProps, nextState ) {
    return JSON.stringify( this.state.store.device ) !== JSON.stringify(nextProps.store.device);
  }
  /**
   * Determines if the disabled state is active
   */
  disabled() {
    const noDevice = this.state.store.device.list.length < 1;
    const deviceOffline = this.state.store.device.list.length > 0 && this.state.store.device.dict[ this.state.store.device.currentMacAddress ].status === "offline";
    return deviceOffline || noDevice;
  }
  /**
   * Render's dialog message for confirmation of:
   *  - Send 'start' command to device for health check
   */
  startHealthcheck() {
    if ( this.disabled() ) { return false; }

    new Dialog( "FloModal", "warning" )
          .confirm( <div className="inner-wrapper">Health test will take up to 4 minutes. Would you like to continue?</div> )
          .then( () => {
            Device.runHealthCheck();
          });
  }
  /**
   * Cancel ZIT test
   */
  cancelHealthCheck(){
    if ( this.disabled() ) { return false; }
    new Dialog( "FloModal", "warning" )
          .confirm( <div className="inner-wrapper">Are you sure you'd like to cancel your device's health check?</div> )
          .then(() => {
            Device.setValveState( "on" );
          });
  }

  /**
   * React Lifecycle: render - Is fired when the <HealthCheckWidget /> component is leveraged to draw to the screen.
   * @return { JSX } - a JSX Object
   */
  render(){
    const { healthTest } = this.state.store.device;

    if ( this.state.store.device.list.length > 0 ) {
      const device = this.state.store.device.dict[ this.state.store.device.currentMacAddress ];
      const firestore = (device && device.firestore) || {};
      if ( firestore.healthTest ) {
        const event = firestore.healthTest.status;
        const lastStartTime = firestore.healthTest.updated;
        //get percent complete with time calculation ( Use 4 Minutes as 'complete' time );
        const diff = firestore.telemetry ? moment(firestore.telemetry.current.updated).diff(moment(lastStartTime), 'minutes', true) : 0;
        const percentComplete = Math.round( (diff * 100 ) / 4 );

        const testCompleted = (event === "completed" || event === "canceled") && (diff >= 0) && (diff < 1);
        if ((event === "running" && percentComplete <= 100) || healthTest.fetching || (testCompleted && !healthTest.leakType)) {
          return ( <div className={ "healthcheck-widget " }>
                    <h6>health test</h6>
                    <InProgress value={ percentComplete } />
                    <button className="cancel btn btn-danger" onClick={ this.cancelHealthCheck.bind( this ) }>Cancel</button>
                  </div> );
        } else if (testCompleted && healthTest.leakType) {
          if (healthTest.leakType === -1) {
            return ( <div className="healthcheck-widget"><button className="btn btn-success" disabled>Test Successful</button></div> );
          } else if (healthTest.leakType <= 0) {
            return (<div className="healthcheck-widget"><button className="btn btn-warning" disabled>Test Interrupted</button></div>);
          } else {
            return (<div className="healthcheck-widget"><button className="btn btn-error" disabled>Leak Present</button></div>);
          }
        }
      }
    }
    return ( <div className="healthcheck-widget"><RunTestButton onClick={ this.startHealthcheck.bind( this ) } /></div> );
  }
}

export default connect( store =>
  ({
    store: {
      device:{
        dict: { ...store.device.dict },
        list: [ ...store.device.list ],
        currentMacAddress: store.device.currentMacAddress,
        healthTest: store.device.healthTest
      }
    }
  })
)( HealthCheckWidget );
