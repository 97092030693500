"use strict";

import React, { Component } from "react";
import { connect } from "react-redux";
import ReactEChart from "elements/ReactEChart";
import DataDownloader from '../elements/DataDownloader';
import deviceModels from 'constants/deviceModels';
import Consumption from 'actions/consumption';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Unit } from 'constants/UnitSystem';
import ProductType from 'constants/ProductType';

/**
 * View: Usage -> Vital Measurements
 */
export class VitalMeasurements extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.locationId !== nextProps.locationId && nextProps.devices.length > 0) {
      const index = nextProps.devices.findIndex(d => ProductType.fromValue(d.deviceType) === ProductType.SHUTOFF);
      Consumption.updateSelectedDeviceIndex(index);
      Consumption.getMeasurements(nextProps.periodLength, index);
      return { ...prevState, locationId: nextProps.locationId };
    }
    return null;
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  generateFileName(vitalName) {
    return `${vitalName}-${this.props.temperatureGraphOptions.period}.csv`.replace(/_/g , '-');
  }

  handleDeviceChanged(index) {
    Consumption.getMetrics(this.props.periodLength, index);
  }

  render() {
    const { devices } = this.props;
    const device = (devices.length > 0) ? devices[this.props.selectedDeviceIndex] : {};

    const dropdownItems = devices
        .map((d, i) => ({ ...d, index: i }))
        .filter(d => d.id !== device.id && ProductType.fromValue(d.deviceType) === ProductType.SHUTOFF)
        .map(d => (
          <DropdownItem onClick={() => this.handleDeviceChanged(d.index)} key={`device-setting-${d.id}`}>
            { d.nickname || deviceModels.getDeviceTypeLabel(d.deviceModel, d.deviceType) }
          </DropdownItem>
        ));

    return (
      <div className="usage vital-measurements">
        <div className="device-dropdown">
          <Dropdown
            isOpen={this.state.dropdownOpen}
            toggle={this.toggle}
            className="device-dropdown"
            disabled={!device.id}
          >
            <DropdownToggle caret>
              { device.id && (device.nickname || deviceModels.getDeviceTypeLabel(device.deviceModel, device.deviceType)) }
              { !device.id && "Loading... " }
            </DropdownToggle>
            { device.id && dropdownItems.length > 0 &&
              <DropdownMenu>
                { dropdownItems }
              </DropdownMenu>
            }
          </Dropdown>
        </div>

        { /* Flow rate has been disabled for now */}
        {/* <div className="row">
          <div className="col-12 bottom-margin">
            <div className="card">
              <div className="card-header">
                <span>Flow</span>
              </div>
              <div className="card-body">
                <div className="row flow-graph">
                  <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 align-self-center text-center">
                    <div className="flow">
                      <div className="icon-Faucet"></div>
                      <div className="title">Flow Rate</div>
                      <div className="unit">{Unit.displayLongFlowUnit(this.props.unitSystem)}</div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                    <DataDownloader
                      className="float-right"
                      isBlocked={!this.props.isSubscribedToFloProtect}
                      data={this.props.flowGraphDownloadData}
                      filename={this.generateFileName('flow')}
                    />
                    <ReactEChart
                      option={this.props.flowGraphOptions}
                      showLoading={false}
                      style={{ height: "240px", width: "90%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-12 bottom-margin">
            <div className="card">
              <div className="card-header">
                <span>Pressure</span>
              </div>
              <div className="card-body">
                <div className="row pressure-graph">
                  <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 align-self-center text-center">
                    <div className="pressure">
                      <div className="icon-PSI-Gauge"></div>
                      <div className="title">Pressure</div>
                      <div className="unit">{Unit.displayShortPressureUnit(this.props.unitSystem)}</div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                    <DataDownloader
                      className="float-right"
                      isBlocked={false}
                      data={this.props.pressureGraphDownloadData}
                      filename={this.generateFileName('pressure')}
                    />
                    <ReactEChart
                      option={this.props.pressureGraphOptions}
                      showLoading={false}
                      style={{ height: "240px", width: "90%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        { !device.hasBrokenTempSensor &&
          <div className="row">
            <div className="col-12 bottom-margin">
              <div className="card">
                <div className="card-header">
                  <span>Temperature</span>
                </div>
                <div className="card-body">
                  <div className="row temperature-graph">
                    <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 align-self-center text-center">
                      <div className="temperature">
                        <div className="icon-Thermometer"></div>
                        <div className="title">Temperature</div>
                        <div className="unit">{Unit.displayShortTempUnit(this.props.unitSystem)}</div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                      <DataDownloader
                        className="float-right"
                        isBlocked={false}
                        data={this.props.temperatureGraphDownloadData}
                        filename={this.generateFileName('temperature')}
                      />
                      <ReactEChart
                        option={this.props.temperatureGraphOptions}
                        showLoading={false}
                        style={{ height: "240px", width: "90%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default connect(store => {
    return {
      devices: store.device.list,
      locationId: store.user.currentLocation.id,
      selectedDeviceIndex: store.consumption.selectedDeviceIndex,
      periodLength: store.consumption.periodLength || "last_day",
      unitSystem: store.user.unitSystem,
      isSubscribedToFloProtect: store.subscription.isSubscribedToFloProtect,
      flowGraphOptions: {
        ...store.consumption.vitals.flowGraphOptions,
        series: [
          {
            ...store.consumption.vitals.flowGraphOptions.series[0],
            data: [
              ...store.consumption.vitals.flowGraphOptions.series[0].data
            ]
          }
        ]
      },
      flowGraphDownloadData: store.consumption.vitals.flowGraphDownloadData,
      pressureGraphOptions: {
        ...store.consumption.vitals.pressureGraphOptions,
        series: [
          {
            ...store.consumption.vitals.pressureGraphOptions.series[0],
            data: [
              ...store.consumption.vitals.pressureGraphOptions.series[0].data
            ]
          }
        ]
      },
      pressureGraphDownloadData: store.consumption.vitals.pressureGraphDownloadData,
      temperatureGraphOptions: {
        ...store.consumption.vitals.temperatureGraphOptions,
        series: [
          {
            ...store.consumption.vitals.temperatureGraphOptions.series[0],
            data: [
              ...store.consumption.vitals.temperatureGraphOptions.series[0].data
            ]
          }
        ]
      },
      temperatureGraphDownloadData: store.consumption.vitals.temperatureGraphDownloadData,
    };
  }
)(VitalMeasurements);
