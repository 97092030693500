"use strict";

import axios from "axios";
import config from "config";

const apiURIV2 = config.environment.apiV2.uri + "/api/v2";
const userByIdURI = userId => `${apiURIV2}/users/${userId}`;
const alarmSettingsURI = userId => `${userByIdURI(userId)}/alarmSettings`;

/**
 * User API Interface
 */
class _User {

  setUserData(userId, userData) {
    const data = {
      ...userData
    };
    return axios.post(userByIdURI(userId), data).then(success => success.data);
  }

  getUserById(userId, expand = []) {
    const parameters = (expand.length > 0) ? `?expand=${expand.join(",")}` : '';
    return axios.get(userByIdURI(userId) + parameters).then(success => success.data);
  }

  saveAlarmSettings(userId, deviceId, settings, smallDripSensitivity) {
    const payload = {
      items: [{
        deviceId,
        settings,
        smallDripSensitivity,
      }]
    };

    return axios.post(alarmSettingsURI(userId), payload).then(success => success.data);
  }

  confirmEmailChange(userId, id, key, type) {
    const payload = {
      confirmationId: parseInt(id, 10),
      confirmationKey: key,
      type,
    };
    return axios.post(`${userByIdURI(userId)}/email/verify-change`, payload).then(success => success.data);
  }

  requestEmailChange(userId, newEmail) {
    const payload = {
      email: newEmail,
    };
    return axios.post(`${userByIdURI(userId)}/email/request-change`, payload).then(success => success.data);
  }

  verifyUserRegistration(token) {
    const payload = {
      clientId: config.environment.apiV2.clientId,
      clientSecret: config.environment.apiV2.clientId,
      token,
    };
    return axios.post( `${apiURIV2}/users/register/verify`, payload).then(success => success.data);
  }
}

/**
 * @ignore
 */
export const User = new _User();
export default User;
