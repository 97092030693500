/** @module API#Authentication */
"use strict";

import axios  from "axios";
import config from "config";

const authorizePath = `${config.environment.api.uri}/api/v1/oauth2/authorize`;

/**
 * Authorization API Interface
 */
class _Authorization {
  /**
   * @param { string } email - Account email
   * @return { Promise } - return Promise of success.data
   */

  getAuthorizePathWithParams( clientId, redirectUri, state ) {
    return `${authorizePath}?client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&response_type=code`;
  }

  sendAnswer( clientId, redirectUri, state, accept ){
    const url = this.getAuthorizePathWithParams(clientId, redirectUri, state);

    return axios.post(url, { accept }).then(response => {
      window.location = response.data.redirect_uri;
    });
  }

  retrieveClientInfoAndScopes(clientId, redirectUri, state ) {
    const url = this.getAuthorizePathWithParams(clientId, redirectUri, state);
    return axios.get(url).then( success => success.data );
  }
}

/**
 * @ignore
 */
export const Authorization = new _Authorization();
export default Authorization;
