"use strict";

export const FEEDBACK_LIST = [
  { value: 0, text: 'Should be 0' },
  { value: 1, text: 'Should not be 0' },
  { value: 2, text: 'Too High' },
  { value: 3, text: 'Too Low' },
  { value: 4, text: 'Other' }
];

export const OTHER_VALUE = "4";

export const FIXTURE_MACHINES = "machines";

export const COMPUTATION_DURATION = 86400;
