"use strict";

import {
  UPDATE_SETTING,
  FETCH_ALARMS_SUCCESS,
  FETCH_ALARM_SETTINGS_SUCCESS,
  UPDATE_ALARM_SETTINGS,
  UPDATE_LEAK_SENSITIVITY,
  UPDATE_HOME_PROFILE_FIELDS,
  LOADING_HOME_PROFILE_FIELDS
} from "constants/action"
import _ from "lodash";
import UserAPI from "api/user";
import LocationSettingAPI from "api/setting/location";
import ContactsSettingAPI from "api/setting/contacts";
import NotificationsSettingAPI from "api/setting/notifications";
import { Authenticate } from "actions/authentication";
import LocationProfileAction from "./locationProfile";
import Store from "store";
import axios from "axios";
import AuthenticationAction from "./authentication";
import UserAction from "./user";
import DeviceAction from "./device";


const fetchAlarmsSuccess = data => ({
  type: FETCH_ALARMS_SUCCESS,
  data
});

const fetchAlarmSettingsSuccess = (alarms, alarmSettings) => ({
  type: FETCH_ALARM_SETTINGS_SUCCESS,
  alarms,
  alarmSettings
});

const updateAlarmSettings = (deviceId, settings) => ({
  type: UPDATE_ALARM_SETTINGS,
  deviceId,
  settings
});

const updateLeakSensitivityState = (deviceId, newSensitivity) => ({
  type: UPDATE_LEAK_SENSITIVITY,
  deviceId,
  newSensitivity
});

const updateHomeProfileFields = data => ({
  type: UPDATE_HOME_PROFILE_FIELDS,
  data,
});

const loadingHomeProfileFields = () => ({
  type: LOADING_HOME_PROFILE_FIELDS,
});

/**
 * Setting Actions module
 */
class _Setting {

  /**
   * Hydrates the store with setting data: Location, Contacts, Timezones, Countries List
   */
  @Authenticate()
  hydrateStore() {
    // Get Contacts
    const userDataPromise = UserAction.getUserData(true);

    // Get locations for logged user
    const locationPromise = LocationProfileAction.hydrateStore();

    const alarmsPromise = NotificationsSettingAPI.getAlarms();
    return axios.all([
      locationPromise,
      userDataPromise,
      alarmsPromise
    ]).then( ([ locationData, userData, alarmsData ]) => {
      Store.dispatch(fetchAlarmsSuccess(alarmsData.items));
      Store.dispatch(fetchAlarmSettingsSuccess(alarmsData.items, userData.alarmSettings));
    });
  }

  /**
   * Notification preferences
   * @param { Object } preferences - Dictionary of  Notifciation Preferences
   */
  @Authenticate()
  saveNotificationPreferences(userId, deviceId, settings) {
    Store.dispatch(updateAlarmSettings(deviceId, settings));
    const payload = settings.map(s => {
      const data = { ...s };
      delete data.severity;
      return data;
    });
    return UserAPI.saveAlarmSettings(userId, deviceId, payload)
      .catch(err => console.error(err))
	}

  @Authenticate()
  updateLeakSensitivity(sensitivity, deviceId) {
    Store.dispatch(updateLeakSensitivityState(deviceId, sensitivity + 1));

    return Promise.all([
        AuthenticationAction.getUserTokenInfo(),
        DeviceAction.get()
      ])
      .then(([userData, deviceData]) => UserAPI.saveAlarmSettings(userData.user_id, deviceData.id, undefined, sensitivity + 1))
      .catch(err => console.error(err));
  }

  @Authenticate()
  hydrateHomeProfileFields() {
    Store.dispatch(loadingHomeProfileFields());
    return LocationSettingAPI.getHomeProfileFields()
      .then((fields) => {
        const profileData = fields.items.filter(it => it.data).map(it => ({
          id: it.data.list_id ? it.data.list_id : it.key,
          label: it.longDisplay,
          type: it.data.type,
          min: it.data.min,
          max: it.data.max,
          step: it.data.step,
          multiple: !!it.data.multi,
          optional: it.data.optional,
          fieldName: it.data.apiProperty,
        }));
        return Promise.all(profileData.map(item => LocationSettingAPI.getListValues(item.id)))
          .then(optionsList => {
            const data = optionsList.map((list, index) => ({
              ...profileData[index],
              options: list
            }));
            Store.dispatch(updateHomeProfileFields(data));
          })
      })
  }

  @Authenticate()
  withUserAndLocationId(executor) {
    const locationPromise = LocationProfileAction.hydrateStore();
    const userDataPromise = AuthenticationAction.getUserTokenInfo();

    return axios.all([
      locationPromise,
      userDataPromise
    ]).then( ([ locationData, userData ]) => {
      const { [ "user_id" ]: userId } = userData;

      return executor(userId, locationData.id);
    });
  }
}
/**
 * @ignore
 */
export const Setting = new _Setting();
export default Setting;
