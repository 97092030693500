"use strict";

import axios from "axios";
import config from "config";
import _ from 'lodash';
import SystemMode from 'constants/SystemMode';
import DripAlarms from 'constants/DripAlarms';

const apiBaseURI = `${config.environment.api.uri}/api/v1`;

const userAlarmNotificationDeliveryRulesURI = `${apiBaseURI}/useralarmnotificationdeliveryrules`;
const userLocationAlarmNotificationDeliveryRulesURI = `${userAlarmNotificationDeliveryRulesURI}/userlocation`;

const icdAlarmNotificationDeliveryRulesURI = `${apiBaseURI}/icdalarmnotificationdeliveryrules`;
const icdAlarmNotificationDeliveryRulesScanURI = `${icdAlarmNotificationDeliveryRulesURI}/scan`;

const apiURIV2 = config.environment.apiV2.uri + "/api/v2";
const alarmsURI = `${apiURIV2}/alarms`;

/**
 * Settings->Notifications API INterface
 */
class _Notifications {

  /**
   * Get alarms
   */
  getAlarms() {
    return axios.get(alarmsURI).then(success => success.data);
  }

}

/**
 * @ignore
 */
export const Notifications = new _Notifications();
export default Notifications;
