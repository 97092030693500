import React, { useState, useEffect } from 'react';
import { Badge }                      from 'reactstrap';
import { Link }                       from "react-router";
import Cookies                        from 'universal-cookie';
import moment                         from 'moment';

const SHUTOFF_BANNER_COOKIE_KEY = 'isShutoffBannerVisible';

const getIsVisibleFromCookie = (cookieName) => {
  const cookies = new Cookies();
  const cookie = cookies.get(cookieName);

  return typeof cookie === 'undefined' ? true : cookie === 'true';
};

const AddShutoffBanner = () => {
  const [isVisible, setVisible] = useState(true);

  /**
   * Effect. Acts as ComponentDidMount.
   */
  useEffect(() => {
    setVisible(getIsVisibleFromCookie(SHUTOFF_BANNER_COOKIE_KEY));
  }, []);

  const onBannerDismiss = () => {
    const cookies = new Cookies();
    const isBannerVisible = false;

    cookies.set(
      SHUTOFF_BANNER_COOKIE_KEY,
      isBannerVisible,
      {
        path: '/',
        expires: moment().add(2, 'months').toDate()
      }
    );

    setVisible(isBannerVisible);
  };

  return ( isVisible &&
    <div className="add-shutoff-device-banner">
      <div className="background-box">
        <div className="header-section">
          <div className="close-icon-wrapper">
            <img
              onClick={(e) => {
                e.preventDefault();
                onBannerDismiss();
              }}
              src="/assets/images/banner_close_icon.png"
            />
          </div>
        </div>
        <div className="row content-section">
          <div className="col-12 col-lg-8 content-wrapper">
            <div className="row">
              <div className="col-2 drop-icon"><img src="/assets/images/drop_icon.png"/></div>
              <div className="col-10 shutoff-text"><span>In the event of a leak, we are unable to automatically turn off your home's water.</span></div>
            </div>
          </div>
          <div className="button-wrapper col-12 col-lg-4">
            <a target="_blank" href="https://meetflo.com/product/smart-water-shutoff">
              <Badge pill className="add-shutoff-button">Add a Smart Water Shutoff</Badge>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddShutoffBanner;
