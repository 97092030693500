"use strict";

import React, { Component } from "react";
import PropTypes from "prop-types";
import Setting              from "actions/setting";

/**
 * FloCheckbox
 */
export default class FloCheckbox extends Component {
	/**
   * React Lifecycle: Initilizes the component and sets the initial state
   * @param { Object } props - Initial incomming properties on the component
   */
  constructor( props ) {
    super();
    this.state = { ...props };
  }

  /**
   * Changes the component's "checked" state to the value of the checkbox:checked
   * @param { Proxy } proxy - event, target, and handler proxy object
   */
  change( proxy ) {
    if ( !this.props.onClick ) {
      proxy.persist();
      this.setState( { checked: proxy.target.checked }, () => {
        this.state.onChange( proxy, { ...this.state } );
      });
    }
  }
	/**
   * React Lifecycle: Handles incoming property updates, if the store is connected. Every store update.
   * - parses the store's device and attributes, and updates the state on all values.
   * @param { Object } nextProps - The incomming properties due to react properties update
   */
  componentWillReceiveProps( nextProps ) {
    this.setState({ ...nextProps });
  }

  /**
   * Set the component disabled state to true
   */
  disable(){
    this.setState( { disabled: true } );
  }
  /**
   * Set the component disabled state to false
   */
  enable(){
    this.setState( { disabled: false } );
  }
	/**
   * React Lifecycle: render - Is fired when the <FloCheckbox /> component is leveraged to draw to the screen.
   * @return { JSX } - a JSX Object
   */
  render(){
    const checked = typeof this.state.checked === "boolean" ? this.state.checked ? 1 : 0 : this.state.checked;
    const classList = this.state.className || "";
    return (<div className={ ( this.state.checked ? "checked" : "" ) + ( this.state.disabled ? " disabled" : "" ) } onClick={ this.props.onClick }>
              <div className={ classList } data-active={ Boolean( checked ).toString() }></div>
              <input className={ classList } type="checkbox" name={ this.state.name } checked={ !!checked } onChange={ this.change.bind( this ) } value={ this.state.value } ref="input" disabled={ this.state.disabled } />
            </div>)
  }
}

/**
 * Set FloCheckbox incomming property types
 */
FloCheckbox.propTypes = {
  disabled: PropTypes.bool,
  name    : PropTypes.string,
  value   : PropTypes.string,
  onChange: PropTypes.func,
  checked : PropTypes.bool,
}

/**
 * Set FloCheckbox incomming property defaults
 */
FloCheckbox.defaultProps = {
  disabled: false,
  name    : "",
  value   : "",
  onChange: () => {},
  checked : false,
}
