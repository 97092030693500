"use strict";

import React, { PureComponent } from 'react';

import { createSkeletonProvider, createSkeletonElement } from '@trainline/react-skeletor';

const H3 = createSkeletonElement('h3', 'pending-div');
const H4 = createSkeletonElement('h4', 'pending-div');
const LI = createSkeletonElement('li', 'pending-div');

const dummyData = {
  title: '___ _____ __ __ _______ ____ ___ _____ __ __ _______ ____',
  subTitle: '___ _____ __ __ ',
  scopes: [
    { description: '___ _____ __ __ _______ ____' },
    { description: '___ _____ __ __ _______ ____' },
    { description: '___ _____ __ __ _______ ____' }
  ]
};
const pendingPredicate = (props) => props.clientName === undefined || props.clientName == '';

class AuthorizationDetails extends PureComponent {

  render() {
    const {
      clientId,
      clientName,
      scopes,
      handleAccept,
      handleCancel
    } = this.props;

    const title = clientName ? `Do you authorize ${ clientName } to use your account?` : this.props.title;
    const subTitle = clientName ? 'This app requires:' : this.props.subTitle;

    return (
      <div className="row">
        <div className="card-container col-xs-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
          <div className="card">
            <div className="card-body">

              <div className="floIcon text-center">
                <img src="/assets/images/FloByMoenLogo.png" /><h2 className="title">My Account</h2>
              </div>
              <div className="form" ref="formWrapper">
                <H3>{ title }</H3>

                <div className="details">
                  <H4>{ subTitle }</H4>
                  <ul className="details-list">
                    {
                      (scopes || []).map((scope, index) => (
                        <LI key={index}>
                          { scope.description }
                        </LI>
                      ))
                    }
                  </ul>
                </div>
                {clientName &&
                <div className="buttons">
                  <button onClick={handleAccept} className="btn btn-primary save">Accept</button>
                  <button onClick={handleCancel} className="btn btn-secondary reset">Cancel</button>
                </div>
                }
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default createSkeletonProvider(dummyData, pendingPredicate)(AuthorizationDetails);
