"use strict";

import {
  UPDATE_USER_DATA,
  UPDATE_EMAIL_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTION_STATUS
} from "constants/action";
import { Authenticate } from "actions/authentication";
import SubscriptionStatus from 'constants/SubscriptionStatus';
import Store from "store";
import UserAction from "actions/user";
import { unsubscribeWeeklyEmail, subscribeWeeklyEmail, getUserUnsubscribedPreferences } from "api/email";

const updateSubscriptionStatus = data => ({
  type: UPDATE_SUBSCRIPTION_STATUS,
  data
});

const updateEmailsSubscriptions = data => ({
  type: UPDATE_EMAIL_SUBSCRIPTIONS,
  data
});

const updateUserData = data => ({
  type: UPDATE_USER_DATA,
  data: data
});

const emailTypes = {
  WEEKLY_EMAIL: 1
};

const supportedEmails = [emailTypes.WEEKLY_EMAIL];

const emailPrefMap = {
  [emailTypes.WEEKLY_EMAIL]: {
    description: "Weekly Report",
    email_id: "weekly_report",
    name: "Weekly Report",
    subscribed: null
  }
}

class _EmailPreferences {
  @Authenticate()
  hydrateStore() {
    return Promise.all([
      UserAction.getUserData(true)
    ]).then(([user]) => {
      const userId = user.id;
      return getUserUnsubscribedPreferences(userId).then((userUnsubscribePreferences) => {
        const emailPreferences = supportedEmails.map(emailTypeId => {
          return {
            ...emailPrefMap[emailTypeId],
            subscribed: !userUnsubscribePreferences.emailTypes.includes(emailTypeId)
          }
        });

        Store.dispatch(updateEmailsSubscriptions(emailPreferences));
        Store.dispatch(updateUserData(user));
      });
    });
  }

  @Authenticate
  subscribeWeeklyReport(userId, locationId) {
    return subscribeWeeklyEmail(userId, locationId)
      .then(() => {
        Store.dispatch(updateSubscriptionStatus(SubscriptionStatus.SUBSCRIBED));
      });
  }

  @Authenticate
  unsubscribeWeeklyReport(userId, locationId) {
    return unsubscribeWeeklyEmail(userId, locationId)
      .then(() => {
        Store.dispatch(updateSubscriptionStatus(SubscriptionStatus.UNSUBSCRIBED));
      });
  }

  @Authenticate
  unsubscribeByEmbeddedLink(userId, locationId) {
    Store.dispatch(updateSubscriptionStatus(SubscriptionStatus.UNSUBSCRIBING));
    return unsubscribeWeeklyEmail(userId, locationId)
      .then(() => {
        Store.dispatch(updateSubscriptionStatus(SubscriptionStatus.UNSUBSCRIBED));
      })
      .catch(() => {
        Store.dispatch(updateSubscriptionStatus(SubscriptionStatus.UNSUBSCRIBE_FAILURE));
      });
  }
};
/**
 * @ignore
 */
export const EmailPreferences = new _EmailPreferences();
export default EmailPreferences;
