"use strict";

import React, { Component } from "react";
import { connect }          from "react-redux";

import config               from "config";

import Device               from "actions/device";
import Setting              from "actions/setting";

import Dialog               from "elements/Dialog";
import TableList            from "elements/TableList";
import {Helmet}                                   from "react-helmet";

/**
 * Settings Devices view component
 */
export class Settings_Devices extends Component {
  /**
   * React lifecycle: Constructor, Build's component's state
   * @constructor
   */
	constructor( props ){
    super();
    this.state = { ...props }
  }
	/**
   * React Lifecycle: Just before component mounts do this:
   */
  componentWillMount() {
    Setting.hydrateStore();
  }
	/**
   * React Lifecycle: Handles incoming property updates, if the store is connected. Every store update.
   * - parses the store's device and attributes, and updates the state on all values.
   */
  componentWillReceiveProps( nextProps ){
    this.setState( { ...nextProps } );
  }
  /**
   * React Lifecycle: tells the component to redraw or not based onreceive next properties and state, allowing for inspection
   * @param { nextProps } - Incomming properties based on thier update
   * @param { nextState } - Next state based on update
   * @return { boolean } - value of if component should update
   */
  shouldComponentUpdate( nextProps ){
    return JSON.stringify( this.state.store.device.list ) !== JSON.stringify ( nextProps.store.device.list );
  }
  /**
   * Display's via a dialog the QR code as an image as well as a download link
   * @param { string<Base64> } - Base64 enconded string value of qr-code for render & download
   */
  showQRCode = assetBase64 => {
    new Dialog( "FloModal", "info" ).ok(
      <div className="inner-wrapper qr-code">
        <img src={ "data:image/gif;base64," + assetBase64 } /><br />
        <a href={ "data:image/octet-stream;base64," + assetBase64 } target="_blank" >Download</a>
      </div>);
  }
  /**
   * Invokes action in settings to fetch the QR code Base64 img
   * @return { undefined } - last action is a response to a succesful promise inside the function
   */
  fetchQRCode = macAddress => {
    Device
      .fetchQRCode(macAddress)
      .then( asset => {
        const width = this.refs.qrCanvas.width;
        const height = this.refs.qrCanvas.height;
        const context = this.refs.qrCanvas.getContext( "2d" );
        const source = new Image();
        source.onload = () => {
          context.clearRect(0, 0, width, height);
          context.drawImage( source, 0, 0, 120, 120 );
          this.showQRCode( this.refs.qrCanvas.toDataURL().replace( /data:image\/png;base64,/, "") );
				}
				source.src = "data:image/svg+xml;base64," + btoa( asset );
      });
  }
  /**
   * React Lifecycle: Build's JSX for when <Settings_Devices /> component is to draw to the screen.
   * @return { JSX } - block to be rendered to the screen as HTML
   */
  render() {
    const devices = this.state.store.device.list;

    return (
      <section className="container settings-devices">
        <Helmet>
          <title>Flo - Devices</title>
        </Helmet>

        <div className="row">
          <div className="col-12 bottom-margin">
            <div className="card">
              <div className="card-header">
                Devices
              </div>
              <div className="card-body">
                <table className="table">
                  <thead>
                  <tr className="text-center">
                    <th style={{ width: '14%' }}>Name</th>
                    <th>ID</th>
                    <th>QR</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  { devices.map(device => (
                    <tr key={device.device_id}>
                      <td style={{ width: '14%' }}>
                        { device.nickname || 'FloDevice' }
                      </td>
                      <td>
                        { device.device_id }
                      </td>
                      <td>
                        <button className={ "btn btn-primary " + ( this.state.store.device.list.length === 0 ? " disabled" : "") } onClick={ () => this.fetchQRCode(device.device_id) } >View QR Code</button>
                      </td>
                      <td>
                        For emergecy or re-pairing case, please download your QR code by clicking 'View QR Code' then the 'Download' link.
                      </td>
                    </tr>
                  )) }
                  { devices.length === 0 &&
                    <tr>
                      <td>
                      </td>
                      <td>No Devices</td>
                      <td>
                        <button className="btn btn-primary disabled" onClick={ this.fetchQRCode } disabled>View QR Code</button>
                      </td>
                      <td>
                        For emergecy or re-pairing case, please download your QR code by clicking 'View QR Code' then the 'Download' link.
                      </td>
                    </tr>  
                   }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <canvas ref="qrCanvas" className="hidden" width="120px"></canvas>
      </section>
    );
  }
}
/**
 * @ignore
 */
export default connect( store =>
  ({
    store: {
      device: {
        list: [ ...store.device.list ],
        currentMacAddress: store.device.currentMacAddress
      }
    }
  })
)( Settings_Devices );
