"use strict";

import axios  from "axios";
import config from "config";

const apiURIV2 = config.environment.apiV2.uri + "/api/v2";
const deviceByIdV2URI = deviceId => `${apiURIV2}/devices/${deviceId}`;
const lastHealthTestV2URI = deviceId => `${deviceByIdV2URI(deviceId)}/healthTest/latest `;

/**
 * Device Interface
 */
class _Device {

  /**
   * Fetch QRCode for a specific device
   * @param { deviceId } - ID of the device being queried against
   * @return { Promise } - a list of devices
   */
  fetchQRCode( deviceId ) {
    return axios.get( config.environment.api.uri + "/api/v1/stockicds/device/" + deviceId + "/qrcode" ).then( success => success.data[ "qr_code_data_svg" ] );
  }

  /**
   * Set device valve state
   * @param { string } deviceId - ID of the device to set valve state for
   * @param { string } verb - open|close ( valve set value )
   * @return { Promise } - the directive was sent
   */
  setValveState(icdId, verb) {
    const target = (verb === 'open') ? 'open' : 'closed';
    const payload = { valve: { target } };
    return axios.post(deviceByIdV2URI(icdId), payload);
  }
}

/**
 * @ignore
 */
export const Device = new _Device();
export default Device;
