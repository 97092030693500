"use strict";

import thunk from "redux-thunk";
import { browserHistory } from "react-router";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { syncHistoryWithStore, routerReducer } from "react-router-redux";
import reducers from "../reducers";
import { reducer as formReducer } from 'redux-form'

/**
 * Main store Object for App consumption
 */
export const Store = createStore(
  combineReducers({
    ...reducers,
    routing: routerReducer,
    form: formReducer
  }),
  applyMiddleware( ...[ thunk ] ),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export default Store;

/**
 * History initilized for App consumption
 */
export const History = syncHistoryWithStore( browserHistory, Store );
