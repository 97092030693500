import React from 'react';
import PropTypes from "prop-types";
import CreditCardForm from './CreditCardForm';

class CheckoutForm extends React.Component {
  constructor(props, context) {
    super(props, context);
		this.state = {
      enterNewCreditCard: false,
		};
  }

  handleUpdatePaymentMethod = event => {
    event.preventDefault();
    this.setState({ enterNewCreditCard: true })
  };

  render() {
    const { paymentInfo, handleSubmit } = this.props;
    return (
      <div className={ 'checkout ' + this.props.stateClass }>
        <div className="credit-card-payment">

          <div className="row">
            <div className="col-8">
              <h2>Active my FloProtect Plan</h2>
            </div>
            <div className="col-4 text-right">
              <span className="badge price">${this.props.plan.monthly_cost}/mo</span>
            </div>
          </div>

          { paymentInfo && !this.state.enterNewCreditCard &&
          <div className="row current-credit-card">
            <div className="col-md-12 credit-card-info">
              <span className="cc-title"><strong>Current Credit Card Number on File</strong></span><span className="cc-number">****{paymentInfo.last4}</span><span className="cc-expiration">{`${paymentInfo.expMonth}/${paymentInfo.expYear}`}</span>
            </div>
            <div className="col-md-12 update-payment-link">
              <a href="#" onClick={this.handleUpdatePaymentMethod}>Update Payment Method</a>
            </div>
            <div className="col-md-12">
              <button onClick={handleSubmit()} className="btn btn-lg btn-subscribe btn-subscribe-pro btn-checkout">Turn On Protection</button>
            </div>
          </div>
          }
          { (!paymentInfo || this.state.enterNewCreditCard) &&
          <CreditCardForm 
            handleSubmit      = { this.props.handleSubmit }
            errorMessage      = { this.props.errorMessage }
            submitButtonTitle = "Add FloProtect"
          />
          }

          <div className="text-center">
            <small className="floprotect-terms">
              By adding FloProtect, you agree to our <a
                href="https://support.meetflo.com/hc/en-us/articles/360005317174-Terms-and-Conditions-FloProtect-"
                target="_blank"
              >
                terms
              </a>
            </small>
          </div>

          <div className="row plan-details">
            <div className="col-md-6">
              <ul>
                <li><span className="plan-icon"><i className="fas fa-check"></i></span><span className="plan-text">No long-term contract</span></li>
                <li><span className="plan-icon"><i className="fas fa-check"></i></span><span className="plan-text">Billed month-to-month</span></li>
                <li><span className="plan-icon"><i className="fas fa-check"></i></span><span className="plan-text">Cancel anytime</span></li>
              </ul>
            </div>
            <div className="col-md-6">
              <ul>
                <li><span className="plan-icon"><i className="fas fa-check"></i></span><span className="plan-text">We-Pay $5,000 Deductible Guarantee</span></li>
                <li><span className="plan-icon"><i className="fas fa-check"></i></span><span className="plan-text">5-year Extended Warranty</span></li>
                <li><span className="plan-icon"><i className="fas fa-check"></i></span><span className="plan-text">LIVE Smart Water Assistant</span></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="success">
          <div className="icon">
            <svg width="84px" height="84px" viewBox="0 0 84 84" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <circle className="border" cx="42" cy="42" r="40" strokeLinecap="round" strokeWidth="4" stroke="#000" fill="none"></circle>
              <path className="checkmark" strokeLinecap="round" strokeLinejoin="round" d="M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338" strokeWidth="4" stroke="#000" fill="none"></path>
            </svg>
          </div>
          <h3 className="title">Payment successful</h3>
          <p className="message"><span>Thank you for adding FloProtect. Your subscription is active immediately.</span></p>
          <button className="btn btn-lg btn-subscribe btn-subscribe-pro" onClick={this.props.handleClose}>Close</button>
        </div>
      </div>
    );
  }
}

CheckoutForm.propTypes = {
  stateClass: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  paymentInfo: PropTypes.shape({
    last4: PropTypes.string,
    expMonth: PropTypes.number,
    expYear: PropTypes.number
  })
};

export default CheckoutForm;
