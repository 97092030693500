"use strict";

import React, { Component } from "react";
import Header from "layout/Header";

/*
 * Invalid Path - Extend's ReactJS's Component class
 * @extends { Component }
 */
export class InvalidPath extends Component {
  /*
   * React Lifecycle: render - Is fired when the <InvliadPath /> Component is leveraged is used to draw to the screen.
   */
	render(){
		return (
						<section>
              <Header error={ true } />
              <main role="main">
                <img className="logo" src="/assets/images/FloByMoenLogo.png" />
                <div className="content">
                  <h2>404: This page does not exist...</h2>
                  <h5>Check your Address bar for the correct path, or follow one of the navigation links back to the application.</h5>
                </div>
              </main>
						</section>
					);
	}
}
export default InvalidPath;
