"use strict";

import {
  UPDATE_TELEMETRY_AVERAGES,
  UPDATE_DAILY_GOAL_CONSUMPTION,
  UPDATE_MEASUREMENTS,
  UPDATE_SELECTED_DEVICE_INDEX,
  UPDATE_METRICS,
  UPDATE_DEVICE_WEATHER_TEMP
} from "constants/action";

import ConsumptionAPI from "api/consumption";
import { Authenticate } from "actions/authentication";
import Device from "actions/device";
import Setting from "actions/setting";
import LocationProfileAction from "actions/locationProfile";
import Store from "store";
import moment from 'moment-timezone';
import SafeQuery from "./common/SafeQuery";

const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';

const updateDailyGoalConsumption = (timezone, usage, unitSystem) => ({
  type: UPDATE_DAILY_GOAL_CONSUMPTION,
  payload: {
    timezone,
    usage,
    unitSystem
  }
});

const updateMeasurements = (period, timezone, previousData, currentData, unitSystem, vitalsData) => ({
  type: UPDATE_MEASUREMENTS,
  period,
  timezone,
  previousData,
  currentData,
  unitSystem,
  vitalsData
});

const updateMetrics = (period, timezone, unitSystem, vitalsData, selectedDeviceIndex) => ({
  type: UPDATE_METRICS,
  period,
  timezone,
  unitSystem,
  vitalsData,
  selectedDeviceIndex
});

const updateSelectedDeviceIndex = selectedDeviceIndex => ({
  type: UPDATE_SELECTED_DEVICE_INDEX,
  selectedDeviceIndex,
});

const updateDeviceWeatherTemp = (macAddress, tempF) => ({
  type: UPDATE_DEVICE_WEATHER_TEMP,
  tempF,
  macAddress
});

const getSettingsByPeriod = (period, tz, previous = false) => {
  switch (period) {
    case "last_day": {
      const start = previous ? moment().subtract(24, 'hours') : moment();
      const end = previous ? moment().subtract(24, 'hours') : moment();
      return {
        start: start.startOf('day').format(dateFormat),
        end: end.endOf('day').format(dateFormat),
        interval: '1h',
      }
    }
    case "last_24_hours": {
      const start = previous ? moment().tz(tz).subtract(48, 'hours') : moment().tz(tz).subtract(24, 'hours');
      const end = previous ? moment().tz(tz).subtract(24, 'hours') : moment().tz(tz);
      return {
        start: start.startOf('hour').format(dateFormat),
        end: end.startOf('hour').format(dateFormat),
        interval: '1h',
      }
    }
    case "this_week": {
      const start = previous ? moment().subtract(7, 'days').startOf('week') : moment().startOf('week');
      const end = previous ? moment().subtract(7, 'days').endOf('week') : moment().endOf('week');
      const today = previous ? moment().subtract(7, 'days') : moment();
      start.subtract(1, 'day');
      end.subtract(1, 'day');
      const isBetween = today.isBetween(start.clone(), end.clone());
      return {
        start: isBetween ? start.format(dateFormat) : start.add(1, 'week').format(dateFormat),
        end: isBetween ? end.format(dateFormat) : end.add(1, 'week').format(dateFormat),
        interval: '1d',
      }
    }
    case "last_28_days": {
      const start = previous ? moment().subtract(56, 'days') : moment().subtract(28, 'days');
      const end = previous ? moment().subtract(28, 'days') : moment();
      return {
        start: start.add(1, 'days').startOf('day').format(dateFormat),
        end: end.endOf('day').format(dateFormat),
        interval: '1d',
      }
    }
    case "last_12_months": {
      const start = moment().subtract(12, 'months');
      const end = moment();
      return {
        start: start.add(1, 'month').startOf('month').format('YYYY-MM-DD'),
        end: end.endOf('month').format('YYYY-MM-DD'),
        interval: '1m',
      }
    }
    default:
      return getSettingsByPeriod('last_24_hours', tz);
  }
}

/**
 * Consumption Action Module
 */
class _Consumption extends SafeQuery {

  updateSelectedDeviceIndex(selectedDeviceIndex) {
    Store.dispatch(updateSelectedDeviceIndex(selectedDeviceIndex));
  }

  @Authenticate()
  getDailyGoalConsumption() {
    const period = "last_day";
    const userState = Store.getState().user;
    if (userState.locations.length < 1) {
      return Promise.reject("Current location not set");
    }

    const tz = userState.currentLocation.timezone;
    const locationId = userState.currentLocation.id;
    const current = getSettingsByPeriod(period, tz);

    const getMeasurementsQuery = this.createQuery('Consumption.getDailyGoalConsumption.getMeasurements', ConsumptionAPI.getMeasurements);
    return getMeasurementsQuery(current.start, current.end, locationId, current.interval)
      .then((data)  => {
        const timezone = data.params.tz || tz;
        Store.dispatch(updateDailyGoalConsumption(timezone, data.items, userState.unitSystem));
      })
      .catch(error => console.log(error));
  }

  @Authenticate()
  getMeasurements(period = "last_day", selectedDeviceIndex = 0) {
    const userState = Store.getState().user;
    if (userState.locations.length < 1) {
      return Promise.reject("Current location not set");
    }

    const locationId = userState.currentLocation.id;
    const index = selectedDeviceIndex > userState.currentLocation.devices.length - 1 ? 0 : selectedDeviceIndex;
    const device = userState.currentLocation.devices.length > 0 ? userState.currentLocation.devices[index] : null;

    const tz = userState.currentLocation.timezone;

    const current = getSettingsByPeriod(period, tz);
    const previous = getSettingsByPeriod(period, tz, true);
  
    const getPrevMeasurementsQuery = this.createQuery('Consumption.getMeasurements.getPrevMeasurements', ConsumptionAPI.getMeasurements);
    const getCurrentMeasurementsQuery = this.createQuery('Consumption.getMeasurements.getCurrentMeasurements', ConsumptionAPI.getMeasurements);
    const getMetricsQuery = this.createQuery('Consumption.getMeasurements.getMetrics', ConsumptionAPI.getMetrics);

    return Promise.all([
        getPrevMeasurementsQuery(previous.start, previous.end, locationId, previous.interval),
        getCurrentMeasurementsQuery(current.start, current.end, locationId, current.interval),
        device ? getMetricsQuery(current.start, current.end, device.macAddress, current.interval, tz) : Promise.resolve(null)
      ])
      .then(([previousData, currentData, vitalsData])  => {
        const timezone = currentData.params.tz || tz;
        Store.dispatch(updateMeasurements(period, timezone, previousData.items, currentData.items, userState.unitSystem, vitalsData));
      })
      .catch(error => console.log(error));
  }

  @Authenticate()
  getWeatherTemperatureToday(macAddress) {
    const userState = Store.getState().user;
    if (userState.locations.length < 1) {
      return Promise.reject("Current location not set");
    }

    if (!macAddress) {
      return Promise.resolve();
    }

    const tz = userState.currentLocation.timezone;

    const period = "last_24_hours";
    const current = getSettingsByPeriod(period, tz);

    const getMetricsQuery = this.createQuery('Consumption.getWeatherTemperatureToday.getMetrics', ConsumptionAPI.getMetrics);
    return getMetricsQuery(current.start, current.end, macAddress, current.interval, tz)
      .then((vitalsData)  => {
        const tempF = vitalsData.length > 0 ? vitalsData[vitalsData.length - 1].averageTempF : 0;
        Store.dispatch(updateDeviceWeatherTemp(macAddress, tempF));
      })
      .catch(error => console.log(error));
  }

  @Authenticate()
  getMetrics(period = "last_day", selectedDeviceIndex = 0) {
    const userState = Store.getState().user;
    if (userState.locations.length < 1) {
      return Promise.reject("Current location not set");
    }

    const locationId = userState.currentLocation.id;
    const device = userState.currentLocation.devices.length > 0 ? userState.currentLocation.devices[selectedDeviceIndex] : null;
    if (!device) {
      return Promise.reject("Device does not exist");
    }

    const tz = userState.currentLocation.timezone;
    const current = getSettingsByPeriod(period, tz);

    return ConsumptionAPI.getMetrics(current.start, current.end, device.macAddress, current.interval, tz)
      .then(vitalsData => {
        const timezone = currentData.params.tz || tz;
        Store.dispatch(updateMetrics(period, timezone, userState.unitSystem, vitalsData, selectedDeviceIndex));
      })
      .catch(error => console.log(error));
  }
}

/**
 * @ignore
 */
export const Consumption = new _Consumption();
export default Consumption;
