import React, { PureComponent }  from 'react';
import { Formik, Field }         from 'formik';
import Yup                       from 'yup';
import  _                        from 'lodash';

const emailRegEx = /^\S+@\S+\.\S+$/;

class EmailChangeRequestInnerForm extends PureComponent {
  render() {
    const {
      values,
      handleSubmit,
      errorMessage,
      isValid,
      touched,
      errors,
      errorStatus,
      submittingRequest,
      requestResult,
    } = this.props;

    return (
      <form className="form" novalidate>
        { errorMessage &&
          <div className="message-container mb-3">
            <span className="error-message">{ errorMessage }</span>
          </div>
        }

        <div className="form-group">
          <label>New Email</label>
          <Field
            type="email"
            name="email"
            placeholder="New Email"
            className={ "form-control"  + ( (touched.email && errors.email) ? " invalid" : "" )  }
            value={values.email}
          />
          { touched.email && errors.email &&
          <div className="message-container">
              <span className="error-message">
                {errors.email}
              </span>
          </div>
          }
        </div>
        <div className="form-group">
          <label>Confirm New Email</label>
          <Field
            type="email"
            name="emailConfirm"
            placeholder="Confirm New Email"
            className={ "form-control"  + ( (touched.emailConfirm && errors.emailConfirm) ? " invalid" : "" )  }
            value={values.emailConfirm}
          />
          { touched.emailConfirm && errors.emailConfirm &&
          <div className="message-container">
              <span className="error-message">
                {errors.emailConfirm}
              </span>
          </div>
          }
        </div>

        { !errorStatus && !submittingRequest && requestResult.userId &&
          <p className="text-success">
            Next step: We've sent you two confirmation emails to your new ({requestResult.newEmail}) and current ({requestResult.oldEmail}) addresses to validate them. Please go to your inboxes and click the links inside to complete the process.
          </p>
        }

        <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={!isValid || submittingRequest} >
          Save
        </button>
      </form>
    );
  }
}

const formSchema = Yup.object().shape({
  email: Yup.string().matches(emailRegEx, 'Invalid email format').required('The "New Email" is a required field'),
  emailConfirm: Yup.string().when("email", {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("email")], 'Both emails should match')
  }).matches(emailRegEx, 'Invalid email format').required('The "Confirm New Email" is a required field'),
});


class EmailChangeRequestForm extends PureComponent {
  render() {
    const { errorMessage, errorStatus, submittingRequest, requestResult, initialValues } = this.props;
    return (
      <Formik
        initialValues       = {initialValues}
        enableReinitialize  = {true}
        validationSchema    = {formSchema}
        onSubmit            = {(values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          this.props.onSubmit(values).then(data => {
            if (data.userId) {
              resetForm(initialValues);
            }
          });
        }}
        render={
          formikProps =>
            <EmailChangeRequestInnerForm
              { ...formikProps }
              errorMessage      = {errorMessage}
              errorStatus       = {errorStatus}
              submittingRequest = {submittingRequest}
              requestResult     = {requestResult}
            />
        }
      />
    );
  }
}

export default EmailChangeRequestForm;
