"use strict";

import React, { Component } from "react";
import { connect }          from "react-redux";
import Footer               from "layout/Footer";
import Authorization        from "actions/authorization";
import AuthorizationDetails from "elements/AuthorizationDetails";

/**
 * AuthorizationConfirmation View
 */
export class AuthorizationConfirmation extends Component {

	constructor( props ) {
    super(props);
  }

  componentDidMount(){
    const query = this.props.location.query;
    Authorization.hydrateStore( query.client_id, query.redirect_uri, query.state );
  }

  handleCancel = event => {
    const query = this.props.location.query;
    Authorization.sendAnswer( query.client_id, query.redirect_uri, query.state, false );
  }

  handleAccept = event => {
    const query = this.props.location.query;
    Authorization.sendAnswer( query.client_id, query.redirect_uri, query.state, true );
  }

  render() {
    return (
      <section className="authorization">
        <AuthorizationDetails
          clientId={ this.props.clientId }
          clientName={ this.props.clientName }
          scopes={ this.props.scopes }
          handleAccept={ this.handleAccept }
          handleCancel={ this.handleCancel }
        />
        <Footer />
      </section>
    )
  }
}

export default connect( state => (
  {
    clientId: state.authorization.clientId,
    clientName: state.authorization.clientName,
    scopes: state.authorization.scopes
  }
))( AuthorizationConfirmation );
