"use strict";

import axios from "axios";
import config from "config";

/**
 * Settings->Contacts API Interfact
 */
class _Contacts {
  /**
   * Get all contacts
   * @return { Promise } - the contacts list
   */
	fetchAll(){
    // Endpoint not yet available
    return axios.all([]);
    /*new Promise( ( fulfill, reject ) => {
    axios
      .get( config.environment.api.uri + "/api/v1/contacts/me" )
      .then( success => {
        fulfill( success.data );
      })
      .catch( reject );
    });
    */
  }
}
/**
 * @ignore
 */
export const Contacts = new _Contacts();
export default Contacts;
