"use strict";

import React, { PureComponent } from "react";
import { Link }                 from "react-router";
import ShieldLogo               from 'elements/ShieldLogo';
import moment                   from 'moment-timezone';
import { createSkeletonProvider, createSkeletonElement } from '@trainline/react-skeletor';
import _ from "lodash";
import { displayShortVolumeUnit } from 'constants/UnitSystem';

const SpanWithLoading = createSkeletonElement('span', 'pending-div');
const StrongWithLoading = createSkeletonElement('strong', 'pending-div');
const PWithLoading = createSkeletonElement('p', 'pending-div');

const timelineItem = (key, fixtureDetail, showDate, showLine, timezone) => {
  const fixtureStart = fixtureDetail.start ? moment.utc(fixtureDetail.start).tz(timezone).format('hh:mm a') : '';
  const fixtureEnd = fixtureDetail.end ? moment.utc(fixtureDetail.end).tz(timezone).format('hh:mm a') : '';
  const fixtureGallons = fixtureDetail.flow ? `${fixtureDetail.flow.toFixed(2)} ${displayShortVolumeUnit(fixtureDetail.unitSystem)} ${fixtureDetail.fixture}` : fixtureDetail.fixture;
  const date = showDate && fixtureDetail.start ? `${moment.utc(fixtureDetail.start).tz(timezone).format('ddd. MM/DD')}: ` : '';
  const timeDetails = `${date}${fixtureStart} - ${fixtureEnd}`;

  return (
    <div className="row" key={key}>
      <div className="col-auto text-center flex-column d-none d-flex py-0">
        <h5 className="m-0">
          <SpanWithLoading className={"badge badge-pill timeline-mark fixture-" + fixtureDetail.type}>&nbsp;</SpanWithLoading>
        </h5>
        <div className="row timeline-line h-100">
          <div className={"col " + (showLine ? 'border-right' : '')}>&nbsp;</div>
          <div className="col">&nbsp;</div>
        </div>
      </div>
      <div className="col p-0">
        <div>
          <StrongWithLoading>{fixtureGallons}</StrongWithLoading>
          <PWithLoading className="text-muted tiny-height">
            { timeDetails }
          </PWithLoading>
        </div>
      </div>
    </div>
  );
};

const messageValues = {
  learning: {
    title: 'Learning',
    text: 'We need a couple weeks of usage data before we’re able to detect specific fixtures.'
  },
  noData: {
    title: 'No Data',
    text: 'There is no data to display.'
  }
};

class FixtureUsageDetailsCard extends PureComponent {
  constructor(props) {
    super(props);
  }

  handleShowMore = (e) => {
    e.preventDefault();
    this.props.onShowMore();
  }

  renderMessage = (message) => (
    <div>
      <h3>{message.title}</h3>
      <p>
        {message.text}
      </p>
    </div>
  );

  render() {
    const message = this.props.isLearning ? messageValues.learning : this.props.hasNoDataToDisplay ? messageValues.noData : {};
    if (!this.props.isLoading && this.props.floProtectEnabled && (this.props.isLearning || this.props.hasNoDataToDisplay )) {
      return (
        <div className="card fixture-usage-details h-100">
          <div className="card-body h-100 text-center">
            <div className="row h-100 align-items-center banner">
              <div className="col">
                <img
                  className="img-fluid image-size-15"
                  src="/assets/images/drip@2x.png"
                />
                { this.renderMessage(message) }
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.props.isLoading || this.props.floProtectEnabled && !this.props.isLearning) {
      return (
        <div className="card fixture-usage-details h-100">
          <div className="card-body">
            <div className="timeline">
              {this.props.fixtureUsageDetails.map((fixtureUsageDetail, i) => {
                return timelineItem(
                  i, fixtureUsageDetail, this.props.showDate || false, this.props.fixtureUsageDetails.length - i > 1, this.props.timezone
                );
              })}
              { this.props.hasMoreFixtureUsageDetails &&
                <div className="show-more"><a href="#" onClick={this.handleShowMore}>Show more</a></div>
              }
              { this.props.isNewPageLoading &&
                <div className="loader-wrapper"><div className="loader"></div></div>
              }
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="card fixture-usage-details h-100">
          <div className="card-body h-100 text-center">
            <div className="row h-100 align-items-center banner">
              <div className="col">
                <ShieldLogo isActive={true} size="bigger" />
                <h3>LIVE Smart Water Assistant</h3>
                <p>Unlock 5-start plumbing assistance with a new chat feature directly in your Flo by Moen app. Diagnose alerts in real-time, troubleshoot your fixture use and get expert advice on plumbing maintenance from our team of experts.</p>
                <Link to="/floprotect">Activate now in your FloProtect Plan</Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

}

const dummyData = {
  fixtureUsageDetails: _.range(15).map(i => ({
    fixture: '_______',
    showLine: true,
    key: i,
    type: 1
  }))
};

export default createSkeletonProvider(dummyData, props => props.isLoading)(FixtureUsageDetailsCard);
