"use strict";

import React, { Component, PureComponent }   from "react";
import Yup                        from 'yup';
import { Formik, Field, Form } from 'formik';
import { displayLongVolumeUnit } from "../../constants/UnitSystem";

class ConsumptionGoalsInnerForm extends PureComponent {
  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
    } = this.props;
    return (
      <form onSubmit={handleSubmit} className="form" novalidate>
        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <div className="goal">
              <div className="form-group">
                <input
                  type="number"
                  name="gallonsPerPersonPerDay"
                  className={ "form-control"  + ( (touched.gallonsPerPersonPerDay && errors.gallonsPerPersonPerDay) ? " invalid" : "" )  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gallonsPerPersonPerDay}
                />
                {touched.gallonsPerPersonPerDay && errors.gallonsPerPersonPerDay && <div className="message-container">
              <span className="error-message">
                {errors.gallonsPerPersonPerDay}
              </span>
            </div>}
                <label>
                  {displayLongVolumeUnit(this.props.unitSystem)} per day / <span>per person*</span>
                </label>
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <div className="goal">
              <div className="form-group">
                <input
                  type="number"
                  name="gallonsPerHomePerDay"
                  className={ "form-control"  + ( (touched.gallonsPerHomePerDay && errors.gallonsPerHomePerDay) ? " invalid" : "" )  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gallonsPerHomePerDay}
                />
                {touched.gallonsPerHomePerDay && errors.gallonsPerHomePerDay && <div className="message-container">
              <span className="error-message">
                {errors.gallonsPerHomePerDay}
              </span>
            </div>}
                <label>
                  {displayLongVolumeUnit(this.props.unitSystem)} per day / <span>total home</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-end">
          <div className="col-10">
            <div className="buttons">
              <button className="btn btn-primary save" onClick={ handleSubmit } disabled={isSubmitting}>
                Save
              </button>
            </div>
           </div>
        </div>
      </form>
    );
  }
}

class ConsumptionGoalsForm extends Component {

  handleCancel = (formikProps, event) => {
    formikProps.resetForm();
    this.props.onCancel(event);
  }

  handleChange = (formikProps, event) => {
    formikProps.handleChange(event);

    const fieldName = event.target.name;
    const value = event.target.value;

    if(fieldName == 'gallonsPerPersonPerDay') {
      formikProps.setFieldValue('gallonsPerHomePerDay', ~~(value * this.props.occupants))
    } else {
      formikProps.setFieldValue('gallonsPerPersonPerDay', ~~(value / this.props.occupants))
    }
  }

  render() {
    return (
      <Formik
        initialValues={ this.props.initialValues }
        enableReinitialize={ true }
        validationSchema={
          Yup.object().shape({
            gallonsPerPersonPerDay: Yup
              .number()
              .required("Required")
              .min(1, "Should be greater than 0")
              .integer("Your goal must be a whole number"),
            gallonsPerHomePerDay: Yup
              .number()
              .required("Required")
              .min(1, "Should be greater than 0")
              .integer("Your goal must be a whole number")
          })
        }
        onSubmit={(
          values,
          { setSubmitting }
        ) => {
          const result = this.props.onSubmit(values);

          if(Promise.resolve(result) == result) {
            result
              .then(_ => setSubmitting(false))
              .catch(_ => setSubmitting(false));
          } else {
            setSubmitting(false);
          }
        }}
        render={
          formikProps =>
            <ConsumptionGoalsInnerForm
              { ...formikProps }
              handleCancel={ (event) => this.handleCancel(formikProps) }
              handleChange={ (event) => this.handleChange(formikProps, event) }
              unitSystem={ this.props.unitSystem }
            />
        }
      />
    );
  }
}

export default ConsumptionGoalsForm;
