"use strict";

import React, { PureComponent } from "react";
import Toggle                   from "elements/Toggle";

const toggleValues = [ "on", "off" ];

export default class WaterValveStatusCard extends PureComponent {
  constructor(props) {
    super(props);
  }

  startAnimating = () => {
    this.refs.deviceState.startAnimating();
  }

  stopAnimating = () => {
    this.refs.deviceState.stopAnimating();
  }

  render() {
    return (
      <div className="card h-100">
        <div className="card-header">
          Water Valve Status
        </div>
        <div className="card-body">
          <div className="row align-items-center h-100">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto text-center">
              <Toggle
                ref="deviceState"
                nodes={ toggleValues }
                controlName="Water"
                className="state"
                active={ this.props.valveState }
                onToggle={ this.props.onSetValveState }
                disabled={ this.props.disabled }
                message="Please confirm you want the Flo device to turn ${newState} your water"
                animatingClass="transitioning"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
