"use strict";

import React, { Component }   from "react";
import Header                 from "layout/landing/Header";
import Footer                 from "layout/landing/Footer";
import AccountCreationForm    from "elements/AccountCreationForm";
import {browserHistory}       from "react-router";
import UserRegistration       from "actions/userRegistration";
import {Helmet}               from "react-helmet";
import PropTypes              from "prop-types";
import GoogleTag              from "utils/GoogleTag";
import ReactUtils             from "utils/ReactUtils";

/**
 * AccountCreation View
 */
export class AccountCreation extends Component {

	constructor( props ) {
    super(props);
    this.state = {
      address: '',
      latitude: 0,
      longitude: 0,
      errorMessage: ""
    };
  }

  componentDidMount() {
    const accountInfo = JSON.parse(localStorage.getItem('accountCreationInfo'));

    if(accountInfo.address === '') {
      browserHistory.push('/l/all-included');
    } else {
      this.setState({
        ...accountInfo
      });

      GoogleTag.gtag('event', 'PageView', { 'url': ReactUtils.getFullPathFromProps(this.props) });
      GoogleTag.gtag('event', 'conversion', {'send_to': 'AW-836984716/2t5eCObZoIMBEIy_jY8D'});
    }
  }

  save = (formValues) => {
    UserRegistration
      .checkEmailAvailability(formValues.email)
      .then(availability => {
        const isInUse = availability.is_registered || availability.is_pending;

        this.setState({
          errorMessage: isInUse ? 'A user is already registered with that email, please use another one.' : ''
        });

        if(!isInUse) {
          const unitNumber = this.state.unitNumber !== '' ?  ` #${this.state.unitNumber}` : '';

          const userInfo = {
            email: formValues.email,
            password: formValues.password,
            password_conf: formValues.passwordConfirmation,
            address: `${this.state.streetNumber.short_name} ${this.state.streetName.short_name}${unitNumber}`,
            city: this.state.city.short_name,
            state: this.state.state.short_name.toLowerCase(),
            country: this.state.country.short_name.toLowerCase(),
            postalcode: this.state.zipCode.short_name
          };

          UserRegistration
            .registerUserOnWeb(userInfo)
            .then(() => {
              localStorage.setItem('accountCreationInfo', JSON.stringify({
                ...JSON.parse(localStorage.getItem('accountCreationInfo')),
                email: formValues.email
              }));

              browserHistory.push('/l/email/confirmation');
            });
        }
      }).catch(ex => {
        console.log(ex);
      });
  }

  render() {
    return (
      <section className="account-creation-landing user-creation">
        <Helmet>
          <title>Flo - Guarantee Your Home's Protection from Water Damage</title>
        </Helmet>

        <Header />

        <div className="container main-container">
          <div className="row multi-step-indicator">
            <div className="col-12 text-center">
              <i className="fas fa-circle"></i><i className="fas fa-circle active"></i><i className="fas fa-circle"></i>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <i className="far fa-check-circle fa-7x"></i>
              <h1>Your Home is Compatible with Flo!</h1>
              <h2>To get your customized Home Water Security plan from Flo, please create a Free account.</h2>

              <AccountCreationForm
                errorMessage={ this.state.errorMessage }
                onSubmit={ this.save }
              />
              <small>By creating an account you agree to Flo's
                <a href="https://support.meetflo.com/hc/en-us/sections/206598868-Terms" target="_blank">
                  terms & conditions
                </a>
              </small>
            </div>
          </div>
        </div>

        <Footer />
      </section>
    )
  }
}

AccountCreation.contextTypes = {
  mixpanel: PropTypes.object.isRequired
};

export default AccountCreation;
