"use strict";

import axios from "axios";
import config from "config";

// API V2 endpoints
const apiURIV2 = config.environment.apiV2.uri + "/api/v2";
const alertsV2URI = `${apiURIV2}/alerts`;

const pendingAlertsURI = (locationId, severities, status, page, size) => {
  const severityParams = (severities.length > 0) ? "&" + severities.map(s => `severity=${s}`).join("&") : "";
  const pageParam = page ? `&page=${page}` : "";
  const sizeParam = size ? `&size=${size}` : "";
  const statusParam = status ? `&status=${status}` : "";
  return `${alertsV2URI}?isInternalAlarm=false&locationId=${locationId}${statusParam}${severityParams}${pageParam}${sizeParam}`;
};

const actionV2URI = `${alertsV2URI}/action`;
const PENDING_ALERTS_SIZE = 100;

/**
 * Alert API Interface
 */
class _Alert {

  /**
   * Get the pending alerts for the location passed by parameter.
   * 
   * @param {*} locationId The location id
   */
  getPendingAlerts(locationId) {
    const severities = ["warning", "critical"];
    return axios.get(pendingAlertsURI(locationId, severities, "triggered", 1, PENDING_ALERTS_SIZE)).then(success => success.data);
  }

  /**
   * Get cleared alerts for the location passed by parameter.
   * 
   * @param {String} locationId The location id
   * @param {number} pageNumber The page number to be retrieved.
   */
  getClearedAlerts(locationId, pageNumber, pageSize = 10) {
    return axios.get(pendingAlertsURI(locationId, [], null, pageNumber, pageSize)).then(success => success.data);
  }

  /**
   * Send an action to clear one or more alerts for a certain amount of time.
   * 
   * @param {*} locationId 
   * @param {*} alarmIds 
   * @param {*} snoozeSeconds 
   */
  sendAction(locationId, alarmIds, snoozeSeconds) {
    const payload = {
      locationId,
      alarmIds,
      snoozeSeconds
    };
    return axios.post(actionV2URI, payload).then(success => success.data);
  }
}

/**
 * @ignore
 */
export const Alert = new _Alert();
export default Alert;
