"use strict";

import React, { Component }         from "react";
import { connect }                  from "react-redux";
import Authentication               from "actions/authentication";
import Dialog                       from "elements/Dialog";
import LocationProfile              from "views/LocationProfile";
import EmailPreferences             from "views/EmailPreferences";
import EmailChangeRequest           from "views/EmailChangeRequest";
import { passwordComplexityRegex }  from "constants/regex";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import {Helmet}                                   from "react-helmet";
import classnames from 'classnames';

/**
 * Setting -> User Profile Component
 */
export class Settings_UserProfile extends Component {

	/**
   * React Lifecycle: Initilizes the component and sets the initial state
   * @param { props } - Initial incomming properties on the component
   */
  constructor( props ){
		super( props );

		this.state = {
      ...props,
      currPass      : "",
      newPass       : "",
      newPassConf   : "",
      activeTab: '1'
		}
	}

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  /**
   * Verify the password meets the complexity requirements / (?=.*[A-Z]{1})(?=.*[\d]{1})(?=.{8}).* /
   * @param { string } password - password string to check against
   */
  passwordComplexityTest( password ){
    if ( !passwordComplexityRegex.test(password ) ) {
      new Dialog( "FloModal", "warning" ).ok( (<div className="inner-wrapper"><span>Please make sure your password meets the complexity requirements:</span><div>minimum 8 characters, 1 Uppercase and 1 Number</div></div> ) );
      return false;
    }
    return true;
  }
  /**
   * Sends 'set password' action
   */
  setPassword = () => {
    if ( this.state.currPass && this.state.newPass && this.state.newPassConf ) {
      if ( this.state.newPass !== this.state.newPassConf ) {
        new Dialog( "FloModal", "warning" ).ok( <div className="innerWrapper">Please make sure your new password and confirmation match</div> );
      } else {
        if ( this.passwordComplexityTest( this.state.newPass ) ){
          Authentication
            .setPassword( this.state.currPass, this.state.newPass, this.state.newPassConf )
            .then( success => success )
            .catch( message => new Dialog( "FloModal", "warning" ).ok( <div className="inner-wrapper">{ (typeof message === "string" ? message : message.message ) }</div> ) );
        }
      }
    } else {
      new Dialog( "FloModal", "warning" ).ok( <div className="inner-wrapper">Please make sure you have filled out all fields before attempting to update.</div>);
    }
  }
  /**
   * Updates the password reset values
   * @param { Proxy } proxy - Event, Target, Handler proxy object
   */
  updatePassReset = proxy => {
    const prop = proxy.target.getAttribute( "name" );
    this.setState( { [ prop ]: proxy.target.value } );
  }

	/**
   * React Lifecycle: render - Is fired when the <Settings_UserProfile /> component is leveraged to draw to the screen.
   */
  render() {
    return (
      <div className="container settings-user-profile">
        <Helmet>
          <title>Flo - User Profile</title>
        </Helmet>

        <Nav className="nav-pills nav-inner-view">
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              Security
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              Location
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => { this.toggle('3'); }}
            >
              Change Email
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '4' })}
              onClick={() => { this.toggle('4'); }}
            >
              Email Preferences
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={ this.state.activeTab }>
          <TabPane tabId="1">
            <div className="row">
              <div className="col-12">
                <div className="card h-100">
                  <div className="card-header">
                    <span>Security Profile</span>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="form-group">
                        <label>Current Password</label>
                        <input
                          type="password"
                          id="currPass"
                          name="currPass"
                          className="form-control"
                          placeholder="Old password"
                          value={ this.state.currPass }
                          onChange={ this.updatePassReset }
                        />
                      </div>
                      <div className="form-group">
                        <label>New Password</label>
                        <input
                          type="password"
                          id="newPass"
                          name="newPass"
                          className="form-control"
                          placeholder="New password"
                          value={ this.state.newPass }
                          onChange={ this.updatePassReset }
                        />
                      </div>
                      <div className="form-group">
                        <label>Confirm New Password</label>
                        <input
                          type="password"
                          id="newPassConf"
                          name="newPassConf"
                          className="form-control"
                          placeholder="Password confirmation"
                          value={ this.state.newPassConf }
                          onChange={ this.updatePassReset }
                        />
                      </div>
                      <p className="text-danger" ref="passChangeMsg">You will need to login again after successfully changing your password.</p>
                      <button type="button" className="btn btn-primary" onClick={ this.setPassword }>Save</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="row">
              <div className="col-12">
                <LocationProfile />
              </div>
            </div>
          </TabPane>
          <TabPane tabId="3">
            <div className="row">
              <div className="col-12">
                <EmailChangeRequest />
              </div>
            </div>
          </TabPane>
          <TabPane tabId="4">
            <div className="row">
              <div className="col-12">
                <EmailPreferences />
              </div>
            </div>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default connect( store =>
	({
		store: {
			settings: {
				...store.settings
			}
		}
	})
)( Settings_UserProfile );
