import React from 'react';
import PropTypes from "prop-types";
import CreditCardForm from './CreditCardForm';


class UpdatePaymentMethod extends React.Component {

  render() {
    const { paymentInfo } = this.props;
    return (
        <div className={ 'checkout ' + this.props.stateClass }>
        <div className="credit-card-payment">

          <div className="row">
            <div className="col-12">
              <h2>Update FloProtect Payment</h2>
            </div>
          </div>

          { paymentInfo &&
          <div className="row current-credit-card">
            <div className="col-md-12">
              <span className="cc-title"><strong>Current Credit Card Number on File:</strong></span><span className="cc-number">****{paymentInfo.last4}</span><span className="cc-expiration">{`${paymentInfo.expMonth}/${paymentInfo.expYear}`}</span>
            </div>
          </div> 
          }

          <CreditCardForm 
            handleSubmit      = { this.props.handleSubmit }
            errorMessage      = { this.props.errorMessage }
            submitButtonTitle = "Update"
          />

        </div>

        <div className="success">
          <div className="icon">
            <svg width="84px" height="84px" viewBox="0 0 84 84" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <circle className="border" cx="42" cy="42" r="40" strokeLinecap="round" strokeWidth="4" stroke="#000" fill="none"></circle>
              <path className="checkmark" strokeLinecap="round" strokeLinejoin="round" d="M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338" strokeWidth="4" stroke="#000" fill="none"></path>
            </svg>
          </div>
          <h3 className="title">Payment Successfully Updated</h3>
          <p className="message"><span></span></p>
          <button className="btn btn-lg btn-subscribe btn-subscribe-pro" onClick={this.props.handleClose}>Return to Manage FloProtect</button>
        </div>
      </div>
    );
  }
}

UpdatePaymentMethod.propTypes = {
  stateClass: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  paymentInfo: PropTypes.shape({
    last4: PropTypes.string,
    expMonth: PropTypes.number,
    expYear: PropTypes.number
  })
};

export default UpdatePaymentMethod;
