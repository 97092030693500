import {
  SEND_EMAIL_CHANGE_CONFIRMATION,
  SEND_EMAIL_CHANGE_CONFIRMATION_SUCCESS,
  SEND_EMAIL_CHANGE_CONFIRMATION_ERROR,
  SEND_EMAIL_CHANGE_REQUEST,
  SEND_EMAIL_CHANGE_REQUEST_SUCCESS,
  SEND_EMAIL_CHANGE_REQUEST_ERROR,
  CLEAN_EMAIL_CHANGE_REQUEST_DATA,
} from "constants/action";

const initialState = {
  processingConfirmation: false,
  confirmationResult: {},
  errorMessage: null,
  errorStatus: null,
  submittingRequest: false,
  requestResult: {},
};

const emailPreferences = (state = initialState, action) => {
  switch(action.type) {
    case SEND_EMAIL_CHANGE_CONFIRMATION: {
      return {
        ...state,
        processingConfirmation: true,
        errorMessage: null,
        errorStatus: null,
      };
    }

    case SEND_EMAIL_CHANGE_CONFIRMATION_SUCCESS: {
      return {
        ...state,
        processingConfirmation: false,
        errorMessage: null,
        errorStatus: null,
        confirmationResult: action.payload.data,
      };
    }

    case SEND_EMAIL_CHANGE_CONFIRMATION_ERROR: {
      return {
        ...state,
        processingConfirmation: false,
        errorMessage: action.payload.message,
        errorStatus: action.payload.status,
        confirmationResult: {},
      };
    }

    case SEND_EMAIL_CHANGE_REQUEST: {
      return {
        ...state,
        submittingRequest: true,
        errorMessage: null,
        errorStatus: null,
        requestResult: {},
      }
    }

    case SEND_EMAIL_CHANGE_REQUEST_SUCCESS: {
      return {
        ...state,
        submittingRequest: false,
        errorMessage: null,
        errorStatus: null,
        requestResult: action.payload.data,
      }
    }

    case SEND_EMAIL_CHANGE_REQUEST_ERROR: {
      return {
        ...state,
        submittingRequest: false,
        errorMessage: action.payload.message,
        errorStatus: action.payload.status,
        requestResult: {},
      }
    }

    case CLEAN_EMAIL_CHANGE_REQUEST_DATA: {
      return {
        ...state,
        ...initialState,
      }
    }

    default:
      return state;
  }
  return state;
};

export default emailPreferences;
