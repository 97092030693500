"use strict";

import { UPDATE_LOCATION_PROFILE }  from "constants/action";
import UserRegistrationAPI          from "api/userRegistration";
import Store                        from "store";

/**
 * UserRegistration Actions module
 */
class UserRegistration {
  checkEmailAvailability(email) {
    return UserRegistrationAPI
      .checkEmailAvailability(email);
  }

  verifyAccount(token) {
    return UserRegistrationAPI
      .verifyAccount(token);
  }

  resendEmailConfirmation(email) {
    return UserRegistrationAPI
      .resendEmailConfirmation(email);
  }

  registerUserOnWeb(userInfo) {
    return UserRegistrationAPI
      .registerUserOnWeb(userInfo);
  }
}

export default new UserRegistration();
