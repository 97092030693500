"use strict";

import React, { Component } from "react";
import {Helmet}             from "react-helmet";
import Iframe               from 'react-iframe';
import { connect }          from "react-redux";
import User                 from "actions/user";

export class Referrals extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    User.hydrateStore();
  }

  geteReferralDashboardUrl(email) {
    return `https://meetflo.referralrock.com/access/?programidentifier=e8b65043-c41e-4feb-ac1d-4be363af1040&view=iframe&firstname=FLO&lastname=USER&email=${encodeURIComponent(email)}`;
  }

  render() {
    const hasValidUrl = this.props.email != '';
    const referralDashboardUrl = this.geteReferralDashboardUrl(this.props.email);

    return (
      <div className="container home">
        <Helmet>
          <title>Flo - Referrals</title>
        </Helmet>

        <div className="card">
          <div className="card-body m-0">
            { hasValidUrl &&
              <Iframe url={ referralDashboardUrl }
                      width="100%"
                      height="800px"
                      className="embed-responsive"
                      frameBorder="0"
                      scrolling="no"
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => {
  return {
    email: state.user.email
  };
})(Referrals);

