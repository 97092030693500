"use strict";

import React, { PureComponent } from "react";

export class Header extends PureComponent {

  constructor( props ) {
    super(props);
  }

  render() {
    return (
      <div className="container header landing-header">
        <div className="d-inline p-2">
          <img className="header-logo" src="/assets/images/FloByMoenLogo.png" />
        </div>
        <div className="d-inline p-2">
          <span className="header-text d-none d-sm-inline align-middle">
            Guaranteed water damage protection for your home
          </span>
        </div>
      </div>
    )
  }
}

export default Header;
