"use strict";

import { Authenticate } from "actions/authentication";

// Constants
import { AUTHORIZATION_RETRIEVE_CLIENT_INFO_AND_SCOPES } from "constants/action";

// API
import AuthorizationAPI from "api/authorization";

// Store
import Store from "store";

// Reducer formatters
const receivedClientInfoAndScopes = ( { client_id, client_name, scopes} ) =>
  ({
    type: AUTHORIZATION_RETRIEVE_CLIENT_INFO_AND_SCOPES,
    clientId: client_id,
    clientName: client_name,
    scopes
  });

/**
 * Authorization Actions module
 */
class _Authorization {

  @Authenticate()
  hydrateStore( clientId, redirectUri, state ) {
    AuthorizationAPI
      .retrieveClientInfoAndScopes( clientId, redirectUri, state )
      .then(result => {
        Store.dispatch(receivedClientInfoAndScopes(result));
      });
  }

  @Authenticate()
  sendAnswer( clientId, redirectUri, state, accept ) {
    AuthorizationAPI
      .sendAnswer( clientId, redirectUri, state, accept );
  }
}

/**
 * @ignore
 */
export const Authorization = new _Authorization();
export default Authorization;
