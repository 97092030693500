export const deviceTypes = {
  flo_device_v2: {
    longDisplay: "Flo Device",
    key: "flo_device_v2",
  },
  puck_oem: {
    longDisplay: "Puck",
    key: "puck_oem"
  }
};

export const deviceModels = {
  flo_device_075_v2: {
    longDisplay: '3/4" Flo Device',
    key: "flo_device_075_v2"
  },
  flo_device_125_v2: {
    longDisplay: '1 1/4" Flo Device',
    key: "flo_device_125_v2"
  }
};

const getDeviceTypeLabel = (deviceModel, deviceType) => {
  if (deviceType === deviceTypes.puck_oem.key) {
    return deviceTypes.puck_oem.longDisplay;
  }

  if (deviceModels[deviceModel]) {
    return deviceModels[deviceModel].longDisplay;
  } else {
    console.error("Could not find device type", deviceType);
  }
};

export default {
  getDeviceTypeLabel,
};
