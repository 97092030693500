const DeviceStatus = {
  ONLINE: "device online",
  OFFLINE: "device offline",
  NO_DEVICE: "no device",
  UNKNOWN: "unknown"
};

function isDisabled(status) {
  return status === DeviceStatus.NO_DEVICE || status === DeviceStatus.UNKNOWN || status === DeviceStatus.OFFLINE;
}

export default {
  ...DeviceStatus,
  isDisabled
};
