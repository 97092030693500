"use strict";

import {
  SUBSCRIBED_TO_FLO_PROTECT,
  UPDATE_SUBSCRIPTION_INFO,
  UPDATE_PLAN_INFO,
  UPDATE_SOURCE_ID,
  SUBSCRING_TO_FLO_PROTECT,
  UPDATE_USER_DATA,
  FLO_PROTECT_SUBSCRIPTION_FAILED,
  UPDATE_COUPON_INFO,
  UPDATE_PAYMENT_METHOD_FORM_INFO,
  UNSUBSCRIBING_FROM_FLO_PROTECT,
  UNSUBSCRIBED_FROM_FLO_PROTECT,
  FLO_PROTECT_UNSUBSCRIPTION_FAILED,
  CLEAN_PAYMENT_FORM_STATE,
} from "constants/action";

import { Authenticate } from "actions/authentication";
import Store from "store";
import SubscriptionAPI from "api/subscription";
import AuthenticationAction from "./authentication";
import UserAction from "./user";
import LocationProfile from "./locationProfile";
import LocationSettingAPI from "api/setting/location";

const updateUserData = data => ({
  type: UPDATE_USER_DATA,
  data: data
});

const subscribedToFloProtect = () => ({
  type: SUBSCRIBED_TO_FLO_PROTECT
});

const subscribingToFloProtect = () => ({
  type: SUBSCRING_TO_FLO_PROTECT
});

const floProtectSubscriptionFailed = errorMessage => ({
  type: FLO_PROTECT_SUBSCRIPTION_FAILED,
  payload: {
    errorMessage: errorMessage
  }
});

const unsubscribedFromFloProtect = () => ({
  type: UNSUBSCRIBED_FROM_FLO_PROTECT
});

const unsubscribingFromFloProtect = () => ({
  type: UNSUBSCRIBING_FROM_FLO_PROTECT
});

const floProtectUnsubscriptionFailed = errorMessage => ({
  type: FLO_PROTECT_UNSUBSCRIPTION_FAILED,
  payload: {
    errorMessage: errorMessage
  }
});

const cleanPaymentFormState = () => ({
  type: CLEAN_PAYMENT_FORM_STATE
});

const updateSubscriptionInfo = data => ({
  type: UPDATE_SUBSCRIPTION_INFO,
  data: data
});

const updatePlanInfo = data => ({
  type: UPDATE_PLAN_INFO,
  data: data
});

const updateSourceId = sourceId => ({
  type: UPDATE_SOURCE_ID,
  data: { sourceId }
});

const updateCouponInfo = data => ({
  type: UPDATE_COUPON_INFO,
  data: data
});

const updatePaymentMethodFormInfo = data => ({
  type: UPDATE_PAYMENT_METHOD_FORM_INFO,
  data
});

/**
 * User Actions module
 */
class _FloProtect {

  updatePaymentMethodFormInfo(data) {
    Store.dispatch(updatePaymentMethodFormInfo(data));
  }

  clearErrorMessage() {
    Store.dispatch(floProtectSubscriptionFailed(""));
  }

  clearPaymentInfo() {
    Store.dispatch(updatePaymentMethodFormInfo({ paymentInfo: undefined }));
  }

  updatePaymentInfo(data) {
    Store.dispatch(updatePaymentMethodFormInfo({ paymentInfo: data }));
  }

  cleanPaymentFormState() {
    Store.dispatch(cleanPaymentFormState());
  }

  @Authenticate()
  hydrateStore(planId, sourceId, couponId) {
    return UserAction
      .getUserData()
      .then(data => {
        const userId = data.id;

        const couponPromise = couponId ? SubscriptionAPI.retieveCouponInfo(couponId) : Promise.resolve({});
        const planIdPromise = SubscriptionAPI.retrieveSubscriptionPlan(planId);
        Promise.all([couponPromise, planIdPromise]).then(([couponInfo, planInfo]) => {
          Store.dispatch(updateSourceId(sourceId));
          Store.dispatch(updateUserData(data));
          Store.dispatch(updateCouponInfo(couponInfo));
          Store.dispatch(updatePlanInfo(planInfo));
        });
      });
  }

  _fetchLocationSubscriptionInfo() {
    return LocationProfile.hydrateStore()
      .then(currentLocation => {
        return LocationSettingAPI.fetchLocationById(currentLocation.id, false, true);
      })
      .then(locationData => {
        if (locationData.subscription) {
          Store.dispatch(updateSubscriptionInfo(locationData.subscription));
          if (locationData.subscription.plan) {
            Store.dispatch(updatePlanInfo({ plan_id: locationData.subscription.plan.id }));
          }
          return locationData.subscription;
        }

        Store.dispatch(updateSubscriptionInfo({
          id: null,
          status: 'inactive',
          providerInfo: {
            cancelAtPeriodEnd: false
          }
        }));
        return null;
      });
  }

  @Authenticate()
  getLocationSubscriptionInfo() {
    return this._fetchLocationSubscriptionInfo();
  }

  @Authenticate()
  subscribe(stripe, userId, planId, sourceId, couponId) {
    this._subscribeOrUpdatePaymentMethod(stripe, userId, planId, sourceId, couponId, true);
  }

  @Authenticate()
  updatePaymentMethod(stripe, userId) {
    this._subscribeOrUpdatePaymentMethod(stripe, userId);
  }

  _subscribeOrUpdatePaymentMethod(stripe, userId, planId, sourceId, couponId, isSubscribing = false) {
    const errorMessage = 'Invalid credit card information. Please verify and try again.';

    return Promise.all([
      stripe.createToken(),
      LocationProfile.hydrateStore()
    ]).then(([{ token }, currentLocation]) => {
      if(token) {
        Store.dispatch(subscribingToFloProtect());

        const promise = (isSubscribing) ?
          SubscriptionAPI.subscribe(token.id, userId, currentLocation.id, planId, sourceId, couponId) :
          SubscriptionAPI.updatePaymentMethod(token.id, userId);

        return promise
          .then(() => this._fetchLocationSubscriptionInfo())
          .then(() => {
            Store.dispatch(subscribedToFloProtect());
          })
          .catch(err => {
            Store.dispatch(floProtectSubscriptionFailed('An error occurred while processing your request.'));
          });
      } else {
        Store.dispatch(floProtectSubscriptionFailed(errorMessage));

        return Promise.reject(new Error(errorMessage));
      }
    });
  }

  @Authenticate()
  subscribeUsingCreditCardOnFile(userId, planId, sourceId, couponId) {
    const errorMessage = 'Invalid credit card information. Please verify and try again.';
    Store.dispatch(subscribingToFloProtect());
    return LocationProfile.hydrateStore()
      .then(currentLocation => SubscriptionAPI.subscribe(null, userId, currentLocation.id, planId, sourceId, couponId))
      .then(() => this._fetchLocationSubscriptionInfo())
      .then(() => {
        Store.dispatch(subscribedToFloProtect());
      })
      .catch(err => {
        Store.dispatch(floProtectSubscriptionFailed(errorMessage));
        return Promise.reject(err);
      });
  }

  @Authenticate()
  loadPaymentInfo(userId) {
    this.clearPaymentInfo();
    return SubscriptionAPI.retrievePaymentInfo(userId).then(result => {
      if (result && result.items) {
        const defaultCC = result.items.find(it => it.isDefault);
        this.updatePaymentInfo(defaultCC);
      }
    });
  }

  @Authenticate()
  cancelSubscription(subscriptionId, reason) {
    Store.dispatch(unsubscribingFromFloProtect());
    SubscriptionAPI.cancelSubscription(subscriptionId, reason)
      .then(() => this._fetchLocationSubscriptionInfo())
      .then(() => {
        Store.dispatch(unsubscribedFromFloProtect())
      })
      .catch(err => Store.dispatch(floProtectUnsubscriptionFailed(err.response.data.message)));
  }
};
/**
 * @ignore
 */
export const FloProtect = new _FloProtect();
export default FloProtect;
