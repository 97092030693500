"use strict";

import axios from "axios";
import config from "config";
import moment from 'moment/moment';
import FloDetectResponse from '../constants/FloDetectResponse';
import _ from 'lodash';

const twentyFourHours = 86400;

const apiURIV2 = config.environment.apiV2.uri + "/api/v2";
const getFixturesV2URI = (deviceId, from, to) =>
  `${apiURIV2}/flodetect/fixtures?macAddress=${deviceId}&from=${from}&to=${to}`;
const getEventsV2URI = (deviceId, to, limit) =>
`${apiURIV2}/flodetect/events?macAddress=${deviceId}&to=${to}&limit=${limit}`;

class _FloDetect {

  getFixtures(deviceId, hoursInSeconds) {
    const to = moment().toISOString();
    const from = (hoursInSeconds === twentyFourHours) ? moment().subtract(24, 'hours').toISOString() : moment().subtract(7, 'days').toISOString();

    return axios
      .get(getFixturesV2URI(deviceId, from, to),
      {
        validateStatus: function (status) {
          return status < 400 || status == 404;
        }
      })
      .then(res => {
        const fixtures = _.get(res, 'data.items.0.fixtures', []);
        if (res.data.isStale || _.isEmpty(fixtures) ||
            res.data.status == FloDetectResponse.INSUFFICENT_DATA || 
            res.data.status == FloDetectResponse.INTERNAL_ERROR ||
            res.status == 404) {
          return { hasNoDataToDisplay: true };
        }

        return { ...res.data, isLearning: false, hasNoDataToDisplay: false };
      });
  }

  retrieveFlowEvents(deviceId, to = moment().toISOString(), size) {
    return axios.get(getEventsV2URI(deviceId, to, size));
  }
}

export const floDetect = new _FloDetect();
export default floDetect;
