"use strict";

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";

import { Badge } from 'reactstrap';

/**
 * Alert Notifications Header Widget
 */
export class AlertNotification extends Component {
  constructor( props ) {
		super();
		this.state = {
      store: { ...props.store },
      displayClass: "header-widget alerts" + ( props.count && props.count > 0 ? " insecure" : ""),
			count: props.count ? props.count : 0,
		};
	}

  render() {
    const alertsText = this.props.count > 1 ? "alerts" : "alert";
    const badgeText = this.props.count > 0 ? alertsText : this.props.deviceStatus;
    const statusClass = this.props.deviceStatus.replace(/\s/g, "-");
    const hasAlertsClass = this.props.count > 0 ? "has-alerts" : "";

    return (
      <Link className="alert-notification" to={ this.props.url }>
        <Badge className={ `${statusClass} ${hasAlertsClass}` } pill>
          { (statusClass !== 'no-device' && this.props.count > 0) &&
            <Badge pill>{ this.props.count }</Badge>
          } { badgeText }
        </Badge>
      </Link>
    )
  }
}

/**
 * Type check properties
 * @param { number } count - current alert count
 */
AlertNotification.propTypes = {
  count: PropTypes.number.isRequired,
  deviceStatus: PropTypes.string.isRequired,
};
/**
 * Default Properties
 * @param { number } count - default = 0
 */
AlertNotification.defaultProps = {
  count: 0,
  deviceStatus: 'no device',
};

export default AlertNotification;
