"use strict";

import React, { Component } from "react";

/**
 * Header Telemetry Widget
 */
export default class HeaderTelemetryWidget extends Component {
	/**
   * React Lifecycle: Initilizes the component and sets the initial state
   * @param { Object } props - Initial incomming properties on the component
   */
	constructor( props ) {
		super();
		this.state = {...props };
	}
  /**
   * React Lifecycle: Exposes the incomming property updates to the component
   * @param { Object } nextProps - The incomming properties due to react properties update
   */
	componentWillReceiveProps( props ) {
		this.setState( { ...props } );
	}
	/**
   * React Lifecycle: render - Is fired when the <HeaderTelemetryWidget /> component is leveraged to draw to the screen.
   * @return { JSX } - a JSX Object
   */
  render() {
    return (<div className={ "telemetry-widget telemetry-" + this.state.context + " icon-" + this.state.icon }>
							<div className="wrapper" title={ this.state.unitToolTip }>
								<div className="context">{ this.state.context }</div>
								<div className="value">
									{ typeof this.state.value === "number" ? Number( this.state.value ).toLocaleString( "en", { style: "decimal", maximumFractionDigits: 2 }) : this.state.value }
                  <span className="unit">{ this.state.unit }</span>
								</div>
							</div>
						</div>)
  }
}
