"use strict";

import React, { Component }         from "react";
import { connect }                  from "react-redux";
import { Link }                     from "react-router";
import {Helmet}                     from "react-helmet";
import Dialog                       from "elements/Dialog";
import ShieldLogo                   from "elements/ShieldLogo";
import AlertsPanel                  from "elements/AlertsPanel";
import LocationProfileActions       from "actions/locationProfile";
import Device                       from "actions/device";
import Setting                      from "actions/setting";
import FloDetect                    from "actions/floDetect";
import Consumption                  from "actions/consumption";
import InsuranceLetter              from "actions/insuranceLetter";
import DeviceStatus                 from 'constants/DeviceStatus';
import SystemMode                   from 'constants/SystemMode';
import LeakDetectionCard            from 'elements/home/LeakDetectionCard';
import DailyUsageGoalCard           from 'elements/home/DailyUsageGoalCard';
import WaterValveStatusCard         from 'elements/home/WaterValveStatusCard';
import CurrentDeviceStatsCard       from 'elements/home/CurrentDeviceStatsCard';
import FixtureUsageSummaryCard      from 'elements/home/FixtureUsageSummaryCard';
import FixtureUsageDetailsCard      from 'elements/home/FixtureUsageDetailsCard';
import { Badge, Alert }             from 'reactstrap';
import Cookies                      from 'universal-cookie';
import moment                       from 'moment-timezone';
import _                            from 'lodash';
import UnitSystem, { convertFromGallons }      from 'constants/UnitSystem';
import AddShutoffDeviceBanner       from '../../components/elements/AddShutoffDeviceBanner';

const healthTestAlarmsSensitivityLevels = {
  0: {
    details: "You’ll be alerted to the biggest drips",
    dripLogoSize: 60
  },
  1: {
    details: "You’ll be alerted to bigger drips",
    dripLogoSize: 50
  },
  2: {
    details: "You’ll be alerted to small drips",
    dripLogoSize: 40
  },
  3: {
    details: "You’ll be alerted to all drips",
    dripLogoSize: 30
  }
};

const twentyFourHours = 86400;
const sevenDaysHours = 604800;

export class Home extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      showHealthTestInfo: false,
      fixtureActivePeriod: '24_hours',
      isInsuranceLetterBannerVisible: false,
      isFriendRewardBannerVisible: false,
    };
  }

  componentDidMount() {
    InsuranceLetter.loadInsuranceLetter();
    LocationProfileActions.hydrateStore().then(location =>
      FloDetect.retrieveLatestInLastHours(
        twentyFourHours,
        (location && location.timezone !== '') ? location.timezone : undefined
      )
    );

    this.setState({
      isInsuranceLetterBannerVisible: this.getIsVisibleFromCookie('isInsuranceLetterBannerVisible'),
      isFriendRewardBannerVisible: this.getIsVisibleFromCookie('isFriendRewardBannerVisible'),
    });
  }

  getIsVisibleFromCookie(cookieName) {
    const cookies = new Cookies();
    const cookie = cookies.get(cookieName);

    return typeof cookie === 'undefined' ? true : cookie === 'true';
  }

  onSetValveState = newState => {
    if ( this.disabled() ) { return false }
    this.refs.currentDeviceStatsCard.startAnimatingDeviceState();
    Device.setValveState( newState );
    const interval = setInterval( () => {
      if (this.props.device.valveState === newState ) {
        this.refs.currentDeviceStatsCard.stopAnimatingDeviceState();
        clearInterval( interval );
      }
    }, 500 );
  }

  disabled() {
    return DeviceStatus.isDisabled(this.props.device.status);
  }

  onHealthTestAlarmsSensitivity = (value) => {
    Setting.updateLeakSensitivity(value, this.props.device.id);
  }

  onToggleShowHealthTestInfo = () => {
    this.setState({
      showHealthTestInfo: !this.state.showHealthTestInfo
    });
  }

  onFixtureActivePeriodChange = (period) => e => {
    this.setState({
      fixtureActivePeriod: period
    });

    if(period == '24_hours') {
      FloDetect.retrieveLatestInLastHours(twentyFourHours, this.props.timezone);
    } else {
      FloDetect.retrieveLatestInLastHours(sevenDaysHours, this.props.timezone);
    }
  }

  handleShowMoreFlowEvents = () => {
    const { fixtureUsageDetails } = this.props;
    FloDetect.retrieveMoreFlowEvents(fixtureUsageDetails );
  }

  onInsuranceLetterBannerDismiss = () => {
    const cookies = new Cookies();
    const isInsuranceLetterBannerVisible = false;

    cookies.set(
      'isInsuranceLetterBannerVisible',
      isInsuranceLetterBannerVisible,
      {
        path: '/',
        expires: moment().add(2, 'weeks').toDate()
      }
    );

    this.setState({ isInsuranceLetterBannerVisible });
  }

  onFriendRewardBannerDismiss = () => {
    const cookies = new Cookies();
    const isFriendRewardBannerVisible = false;

    cookies.set(
      'isFriendRewardBannerVisible',
      isFriendRewardBannerVisible,
      {
        path: '/',
        expires: moment().add(2, 'months').toDate()
      }
    );

    this.setState({ isFriendRewardBannerVisible });
  }

  /**
   * React Lifecycle: render - Is fired when the <Settings_UserProfile /> component is leveraged to draw to the screen.
   */
  render() {
    const lastTimeHealthTestRan = moment(this.props.device.lastTimeHealthTestRan).calendar(null, {
      sameElse: 'MM/DD/YY [at] HH:mm'
    });

    const healTestAlarmSelectedSensitivityLevel =
      healthTestAlarmsSensitivityLevels[this.props.leakSensitivity];

    const floProtectEnabled = this.props.floProtect.enabled;
    const redeemed = this.props.date_redeemed || false;
    const isInsuranceLetterBannerVisible = !redeemed && this.state.isInsuranceLetterBannerVisible;
    const locationHasOnlyDetector = (this.props.deviceList.length > 0) && !this.props.locationHasShutoff;

    return (
      <div className="container home">
        <Helmet>
          <title>Flo - Home</title>
        </Helmet>

        <div className="row">
          <div className="col-12">
            <Alert
              color="success" isOpen={isInsuranceLetterBannerVisible}
              toggle={ floProtectEnabled ? null : this.onInsuranceLetterBannerDismiss }
              fade={false}
            >
              <Link className="link-unstyled" to="insurance-letter">
                Click here to download your Flo Verified Insurance Letter now!
              </Link>
            </Alert>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-12">
            <Alert
              color="warning" isOpen={this.state.isFriendRewardBannerVisible}
              toggle={ this.onFriendRewardBannerDismiss }
              fade={false}
            >
              <a className="link-unstyled" href="https://meetflo.com/friends" target="_blank">
                <strong>Get $100 when your friends join the family and they'll receive two free Smart Water Detectors!</strong>
              </a>
            </Alert>
          </div>
        </div> */}

        { this.props.alerts.count > 0 &&
        <div className="row">
          <div className="col-12">
            <AlertsPanel
              count={ this.props.alerts.count }
              severity={ this.props.alerts.severity }
            />
          </div>
        </div>
        }

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 bottom-margin">
            <CurrentDeviceStatsCard
              ref="currentDeviceStatsCard"
              wf={ this.props.device.telemetry.wf }
              p={ this.props.device.telemetry.p }
              t={ this.props.device.telemetry.t }
              humidity={ this.props.device.telemetry.humidity }
              battery={ this.props.device.telemetry.battery }
              lastReported={ this.props.device.telemetry.lastReported }
              disabled={ this.disabled() }
              valveState={ this.props.device.valveState }
              onSetValveState={ this.onSetValveState  }
              healthTestAlarmsSensitivity={ this.props.leakSensitivity }
              healTestAlarmSelectedSensitivityDetails={ healTestAlarmSelectedSensitivityLevel.details }
              lastTimeHealthTestRan={ lastTimeHealthTestRan }
              dripLogoSize={ healTestAlarmSelectedSensitivityLevel.dripLogoSize }
              showHealthTestInfo={ this.state.showHealthTestInfo }
              healthTestAlarmsSensitivityLevels={ healthTestAlarmsSensitivityLevels }
              onHealthTestAlarmsSensitivity={ this.onHealthTestAlarmsSensitivity }
              onToggleShowHealthTestInfo={ this.onToggleShowHealthTestInfo }
              deviceList={ this.props.deviceList }
              device={ this.props.device }
              isLocationInfoLoading={ this.props.isLocationInfoLoading }
              unitSystem={ this.props.unitSystem }
              currentDeviceWeatherTemp={this.props.currentDeviceWeatherTemp}
            />
          </div>
        </div>

        { !locationHasOnlyDetector && !this.props.isLocationInfoLoading &&
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 bottom-margin">
              <DailyUsageGoalCard
                gallonsPerDayGoal={ this.props.gallonsPerDayGoal }
                gallonsConsumedToday={ this.props.gallonsConsumedToday }
                gallongsConsumedTodayGraphOptions={ this.props.gallongsConsumedTodayGraphOptions  }
                unitSystem={ this.props.unitSystem }
              />
            </div>
          </div>
        }

        { !locationHasOnlyDetector &&
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bottom-margin">
              <FixtureUsageSummaryCard
                isLearning={ this.props.isLearning }
                hasNoDataToDisplay= { this.props.hasNoDataToDisplay }
                floProtectEnabled={ true } //force fixture events for all users
                fixturesUsage={ this.props.fixturesUsage }
                activePeriod={ this.state.fixtureActivePeriod }
                devicePaired={ this.props.devicePaired }
                isLoading={ this.props.isFlodetectLoading }
                onActivePeriodChange={ this.onFixtureActivePeriodChange }
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bottom-margin">
              <FixtureUsageDetailsCard
                isLearning={ this.props.isLearning }
                hasNoDataToDisplay= { this.props.hasNoDataToDisplay }
                isLoading={ this.props.isFlodetectLoading }
                floProtectEnabled={ true } //force fixture events for all users
                timezone={ this.props.timezone }
                fixtureUsageDetails={ this.props.fixtureUsageDetails }
                showDate={ this.state.fixtureActivePeriod !== '24_hours' }
                onShowMore={ this.handleShowMoreFlowEvents }
                hasMoreFixtureUsageDetails={this.props.hasMoreFixtureUsageDetails}
                isNewPageLoading = {this.props.isUsageDetailsLoading}
              />
            </div>
          </div>
        }

        { locationHasOnlyDetector && <AddShutoffDeviceBanner /> }

        <div className="row">
          <div className="col-12 bottom-margin">
            <div className="card h-100 home-protect">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                    <p className="name">
                      <div className="d-inline">
                        <ShieldLogo isActive={true} size="medium" ref="floprotect"/>
                      </div>
                      <div className="d-inline align-middle">
                        FloProtect Plan
                      </div>
                    </p>
                    <ul>
                      <li>Add the We-Pay Deductible Guarantee of $5,000</li>
                      <li>Add the 5-year Extended Warranty</li>
                      <li>Add the LIVE Smart Water Assistant</li>
                    </ul>
                    <Link to="/floprotect" >{ this.props.floProtect.enabled ?
                      ("Manage your Plan") : ("Activate my FloProtect Plan")
                    } </Link>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
                    <p>Your FloProtect status: {this.props.floProtect.enabled ? (
                      <Badge className="flo-green" pill>On</Badge>
                    ) : (
                      <Badge pill>Off</Badge>
                    )}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function getSeverity(pending, count) {
  if(pending.critical && pending.critical.length > 0) {
    return "critical";
  } else if(pending.warning && pending.warning.length > 0) {
    return "warning";
  } else if(count > 0) {
    return "info";
  } else {
    return "no-alerts"
  }
}

export default connect( state => {
  const consumption = state.consumption;
  const series = consumption.dailyGoalTotals.series;
  const timezone = state.locationProfile.location.timezone;
  const unitSystem = state.user.unitSystem;

  const currentDevice = (state.device.list.length > 0 && state.device.currentMacAddress) ? state.device.list.find(d => d.device_id === state.device.currentMacAddress) : {};
  const leakSensitivitySettings = state.user.smallDripSensitivitySettings.find(device => device.deviceId === currentDevice.id);
  const leakSensitivity = leakSensitivitySettings ? leakSensitivitySettings.smallDripSensitivity - 1 : 0;

  var remainingGallonsInGoal = convertFromGallons(state.locationProfile.location.gallonsPerDayGoal, unitSystem) || 0;
  return {
    timezone: timezone != '' ? timezone : 'Etc/UTC',
    floProtect: {
      enabled: state.subscription.isSubscribedToFloProtect,
      status: state.subscription.status
    },
    leakSensitivity,
    device: {
      id: currentDevice.id,
      systemMode: state.device.systemMode,
      valveState: state.device.valveState,
      status: state.device.status,
      lastTimeHealthTestRan: state.device.lastTimeHealthTestRan,
      ...currentDevice,
      telemetry: state.device.telemetry,
    },
    deviceList: state.device.list,
    gallonsPerDayGoal: (convertFromGallons(state.locationProfile.location.gallonsPerDayGoal, unitSystem) || 0).toFixed(2),
    gallonsConsumedToday: convertFromGallons(consumption.gallonsConsumedToday, unitSystem).toFixed(2) || 0,
    gallongsConsumedTodayGraphOptions: {
      ...consumption.dailyGoalTotals,
      series: [
        {
          ...series[0],
          data: series[0]
            .data
            .map(valueInGallons => {
              const value = convertFromGallons(valueInGallons, unitSystem);
              if(remainingGallonsInGoal - value >= 0) {
                remainingGallonsInGoal = remainingGallonsInGoal - value;
              } else {
                remainingGallonsInGoal = 0;
              }
              return remainingGallonsInGoal.toFixed(2);
            })
        }
      ]
    },
    alerts: {
      count: state.alert.pendingTotal,
      severity: getSeverity(state.alert.pending, state.alert.pendingTotal)
    },
    isLearning: state.fixtureDetection.isLearning,
    hasNoDataToDisplay: state.fixtureDetection.hasNoDataToDisplay,
    isFlodetectLoading: state.fixtureDetection.isLoading,
    fixtureUsageDetails: state.fixtureDetection.fixtureUsageDetails,
    hasMoreFixtureUsageDetails: state.fixtureDetection.hasMoreFixtureUsageDetails,
    deviceId: state.fixtureDetection.deviceId,
    isUsageDetailsLoading: state.fixtureDetection.isUsageDetailsLoading,
    fixturesUsage: state.fixtureDetection.fixturesUsage,
    devicePaired: state.device && state.device.status && state.device.status !== 'no device',
    date_redeemed: state.insuranceLetter.date_redeemed,
    locationId: state.locationProfile.location.id,
    isLocationInfoLoading: !state.query.metadata['User.getUserById'].fetched,
    unitSystem,
    locationHasShutoff: state.locationProfile.metadata.locationHasShutoff,
    currentDeviceWeatherTemp: state.device.currentDeviceWeatherTemp
  };
})( Home );
