"use strict";

import React, { Component }   from "react";
import Header                 from "layout/landing/Header";
import Footer                 from "layout/landing/Footer";
import {
  withGoogleMap,
  GoogleMap,
  Marker
}                             from "react-google-maps";
import {browserHistory}       from "react-router";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
}                             from 'reactstrap';
import {Helmet}               from "react-helmet";
import PropTypes              from "prop-types";
import GoogleTag              from "utils/GoogleTag";
import ReactUtils             from "utils/ReactUtils";

const MyMapComponent = withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{ lat: props.latitude, lng: props.longitude }}
  >
    {props.isMarkerShown && <Marker position={{ lat: props.latitude, lng: props.longitude }} />}
  </GoogleMap>
)

function updateUnitNumberInAddress(address, unitNumber) {
  const addressParts = address.split(',').map(x => x.trim());
  const lastAddressParts = addressParts.slice(1, addressParts.length).join(', ');
  var unitNumberText = '';

  if(unitNumber && unitNumber !== '') {
    unitNumberText = `#${unitNumber}`;
  }

  return `${addressParts[0].split('#')[0].trim()} ${unitNumberText}, ${lastAddressParts}`;
}

/**
 * LocationConfirmation View
 */
export class LocationConfirmation extends Component {

	constructor( props ) {
    super(props);
    this.state = {
      address: '',
      latitude: 0,
      longitude: 0,
      showUnitModal: false,
      unitNumber: ''
    };
  }

  componentDidMount() {
	  const accountInfo = JSON.parse(localStorage.getItem('accountCreationInfo'));

	  if(accountInfo.address === '') {
      browserHistory.push('/l/all-included');
    } else {
      this.setState({
        ...accountInfo
      });

      GoogleTag.gtag('event', 'PageView', { 'url': ReactUtils.getFullPathFromProps(this.props) });
    }
  }

  handleConfirmMyAddress = () => {
    if(this.state.address !== '') {
      localStorage.setItem('accountCreationInfo', JSON.stringify({
        ...this.state
      }));

      const mixpanel = this.context.mixpanel;

      mixpanel.track(
        "Clicked Confirm Address"
      );

      browserHistory.push('/l/user/creation');
    }
  }

  handleFixAddress = () => {
    browserHistory.push('/l/all-included');
  }

  handleUnitNumberChange = (event) => {
    this.setState({
      unitNumber: event.target.value,
      address: updateUnitNumberInAddress(this.state.address, event.target.value)
    });
  }

  toggleUnitModal = () => {
    this.setState({
      showUnitModal: !this.state.showUnitModal
    });
  }

  render() {
    return (
      <section className="account-creation-landing">
        <Helmet>
          <title>Flo - Guarantee Your Home's Protection from Water Damage</title>
        </Helmet>

        <Header />

        <div className="container main-container">
          <div className="row multi-step-indicator">
            <div className="col-12 text-center">
              <i className="fas fa-circle active"></i><i className="fas fa-circle"></i><i className="fas fa-circle"></i>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-10">
              <MyMapComponent
                isMarkerShown
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `250px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                latitude={this.state.latitude}
                longitude={this.state.longitude}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-center">
              <h3 className="address">{ this.state.address }</h3>
              <p className="confirmation-question">
                Is this the correct address? <a href="#" onClick={ this.toggleUnitModal }>Add unit number</a>
              </p>

              <div className="buttons">
                <button
                  type="button"
                  className="btn-landing btn-primary-landing btn-bg"
                  onClick={ this.handleConfirmMyAddress }
                >
                  Yes, that's right
                </button>
                <button
                  type="button"
                  className="btn-landing btn-secondary-landing btn-bg"
                  onClick={ this.handleFixAddress }
                >
                  Fix address
                </button>
              </div>
            </div>
          </div>

          <Modal isOpen={this.state.showUnitModal} toggle={this.toggleUnitModal}>
            <ModalHeader toggle={this.toggle}>Unit #</ModalHeader>
            <ModalBody>
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    name="unit-number"
                    className="form-control"
                    onChange={ this.handleUnitNumberChange }
                    value={ this.state.unitNumber }
                  />
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn-landing btn-primary-landing btn-bg"
                onClick={this.toggleUnitModal}
              >
                Update
              </button>
            </ModalFooter>
          </Modal>

        </div>

        <Footer />
      </section>
    )
  }
}

LocationConfirmation.contextTypes = {
  mixpanel: PropTypes.object.isRequired
};

export default LocationConfirmation;
