import _ from 'lodash';

const ProductType = {
  DETECTOR: 'puck_oem',
  SHUTOFF: 'flo_device_v2'
};

function fromValue(type) {
  return _.values(_.pickBy(ProductType, (value, k) => {
    return value === type;
  }))[0];
}

export default {
  ...ProductType,
  fromValue,
};
