"use strict";

import React, { Component } from "react";
import PropTypes from "prop-types";
import FloCheckbox from "elements/FloCheckbox";

/**
 * RowItem element
 */
export class RowItem extends Component {
	/**
   * React Lifecycle: Initilizes the component and sets the initial state
   * @param { Object } props - Initial incomming properties on the component
   */
  constructor( props ) {
		super();
    this.state = {
      columns: props.columns,
      ...props.item,
      editMode: props.editing || false,
      selected: props.selected ? props.selected : false,
    };
	}
	/**
   * React Lifecycle: Exposes the incomming property updates to the component
   * @param { Object } nextProps - The incomming properties due to react properties update
   */
  componentWillReceiveProps( nextProps ){
    this.setState( { ...nextProps.item,  selected: nextProps.selected ? nextProps.selected : false } );
  }

	/**
   * Edit/Submit handler
   */
  editSubmit() {
    if ( !this.state.editMode ) {
      this.setState( { editMode: true } );
    } else {
      this.props.onSubmit( () => {
        this.setState( { editMode: false } );
      });
    }
  }
  /**
   * Edit Column decider & Render
   * @return { JSX|null } - table cell or nothing
   */
  showEdit(){
    return this.props.editable ? ( <td className="column actions" onClick={ this.editSubmit.bind( this ) }>{ ( this.state.editMode ? "Submit" : "Edit" ) }</td> ) : null;
  }
  /**
   * Passes click event proxy up to onClick property
   * @param { Proxy } proxy - event, target, and value React proxy for event
   */
  handleClick( proxy ){
    proxy.persist();
    this.props.onClick( proxy, this );
  }
	/**
   * React Lifecycle: render - Is fired when the <RowItem /> component is leveraged to draw to the screen.
   * @return { JSX } - a JSX Object
   */
	render() {
		return (<tr className={ "table " + ( this.props.editable ? "editable" : "" ) } onClick={ this.handleClick.bind( this ) } >
              { Object.keys( this.state.columns ).map( ( column, i ) => {
                const col = this.state.columns[ column ];
                if ( typeof col === "string" ){
                  return ( <td key={ i } className={ column + " column" }>{ this.state[ column ] }</td> );
                } else if ( typeof col === "object" ) {
                  if ( this.state.editMode && this.state.columns[ column ].editable ) {
                    return (
                      <td key={ i } className={ column + " column" }>
                        <div className="form-group">
                          <input type="text" className="form-control" name={ column } value={ this.state[ column ] } onChange={ this.props.onEdit } />
                        </div>
                      </td> );
                  } else {
                    if ( col.type && col.type === "image" ) {
                      return ( <td key={ i } className={ column + " column" }><img src={ this.state[ column ] }/></td> );
                    } else if ( col.type && col.type === "checkbox" ) {
                      return ( <td key={ i } className={ column + " column" }>
                                <FloCheckbox name={ this.state[ column ] } value={ this.state[ column ] } checked={ this.state.selected } ref="checkbox" />
                              </td> );
                    } else if ( col.type && col.type === "date" ) {
                      return ( <td key={ i } className={ column + " column datetime" } >
                                {
                                  new Date( this.state[ column ] ).toLocaleDateString("en-US", {
                                    year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit"
                                  }).toString().replace( /\,/g, "" )
                                }
                              </td> );
                    } else {
                      return ( <td key={ i } className={ column + " column" }>{ this.state[ column ] }</td> );
                    }
                  }
                }
              })}
              { this.showEdit() }
						</tr>)
	}
}

/**
 * TableList element
 */
export class TableList extends Component {
  /**
   * Page Count
   */
  PER_PAGE_MAX = 10;
	/**
   * React Lifecycle: Initilizes the component and sets the initial state
   * @param { Object } props - Initial incomming properties on the component
   */
	constructor( props ) {
		super();
    this.state = {
      ...props,
      className   : "table-list " + ( props.className ? props.className : "" ),
			columnNames : props.columns ? Object.keys( props.columns ).map( key => {
        return typeof props.columns[ key ] === "string"
                ? props.columns[ key ]
                : ( typeof props.columns[ key ] === "object" && props.columns[ key ].display );
      }) : [],
      totalItems  : 0,
      selected    : [],
      page        : 1,
      zeroCountMsg: "No Items",
		};
  }
  /**
   * React Lifecycle: Exposes the incomming property updates to the component
   * @param { Object } nextProps - The incomming properties due to react properties update
   */
  componentWillReceiveProps( nextProps ){
    this.setState( {
      items: nextProps.items,
      totalItems: nextProps.totalItems
    } );
  }
	/**
   * Clear the selected array
   */
  clearSelected(){
    this.setState( { selected: [] } );
  }
  /**
   * Event handlers for paginating the data set in the displayed list
   * @param { Proxy } proxy - event, target, and handler proxy
   */
  paginate = proxy => {
    const num = proxy.target.getAttribute( "data-num" );
    let page = parseInt( this.state.page, 10 );
    if ( isNaN( parseInt( num, 10 ) ) ){
      if ( /prev/.test( num ) && page > 1 ){
				page -= 1;
			} else if( /next/.test( num ) && page + 1 <= Math.ceil( this.state.totalItems / this.PER_PAGE_MAX ) ){
				page += 1;
			}
    } else {
      page = ~~num;
    }
    this.setState( { page }, () => {
      if ( typeof this.props.onPaginate === "function" ) {
        this.props.onPaginate( this.state.page );
      }
    });
  }
  /**
   * Builds pagination
   * @return { JSX } - of the pagination
   */
  renderPagination = () => {
    return this.state.totalItems > this.PER_PAGE_MAX ?
      (
        <div className="row pagination" onClick={ this.paginate }>
          <div className="col-3 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <span className="first" data-num="1">first</span>
          </div>
          <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-center">
              <span className="show-page">page: { this.state.page } of { Math.ceil( this.state.totalItems / this.PER_PAGE_MAX ) }</span>
          </div>
          <div className="d-none d-sm-block col-3 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-right">
            <span className="previous" data-num="prev">prev</span>
            <span className="next" data-num="next">next</span>
          </div>
          <div className="d-sm-none col-3 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-right">
            <span className="previous" data-num="prev">
              <i className="fas fa-angle-left"></i>
            </span>
            <span className="next" data-num="next">
              <i className="fas fa-angle-right"></i>
            </span>
          </div>
        </div>
      ) : (<div></div>);
  }
  /**
   * If edit column is on
   * @return { JSX|null } - for the edit column header
   */
  showEditCol(){
    return this.props.onEdit ? (<th></th>) : null;
  }
  /**
   * Row click event handler passed into the RowItem for handling
   * Passed action up to property 'onRowClick'
   * @param { Proxy } proxy - for event, target, and handler
   * @param { RowItem<Object> } - row element that was clicked
   */
  clickRow( proxy, row ){
    if ( row && row.refs.checkbox && proxy.target === row.refs.checkbox.refs.input ) {
      const val = row.refs.checkbox.state.name;
      if ( this.state.selected.indexOf( val ) > -1 ) {
        this.setState( { selected: this.state.selected.filter( item => item !== val ) });
      } else {
        this.setState( { selected: [ ...this.state.selected, val ] } );
      }
    } else {
      if ( this.props.onRowClick ) {
        this.props.onRowClick( row );
      }
    }
  }
	/**
   * Builds the body of the table with <RowItem />s
   */
  renderBody(){
    if ( this.state.items && this.state.items.length > 0 ) {
    	return this.state.items.map( ( item, i ) => {
							return <RowItem
											key={ i }
											item={ item }
											columns={ this.state.columns }
											onEdit={ this.props.onEdit }
											onSubmit={ this. props.onSubmit }
											onClick={ this.clickRow.bind( this ) }
                      selected={ this.state.selected.indexOf( item.incident_id ) > -1 }
											editable={ this.props.onEdit ? true : false } ref={ "row-" + i }
											/>
            	})
    } else {
      return ( <tr className="text-center">
                <td colSpan="100%">{this.state.zeroCountMsg}</td>
              </tr> );
    }
  }
	/**
   * React Lifecycle: render - Is fired when the <TableList /> component is leveraged to draw to the screen.
   * Calls: {@link renderBody} & {@link showEditCol} & {@link renderPagination}
   * @return { JSX } - a JSX Object
   */
	render() {
		return (
			<section className={ this.state.className }>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                { this.state.columnNames.map( ( column, i ) => {
                  return ( <th scope="col" key={ i } className={ column }>{ column }</th> )
                } ) }
                { this.showEditCol() }
              </tr>
            </thead>
            <tbody>
              { this.renderBody() }
            </tbody>
          </table>
        </div>
        { this.renderPagination() }
			</section>
		)
	}
}
TableList.propTypes = {
  items: PropTypes.array.isRequired,
}

export default TableList;
