"use strict";

import axios  from "axios";
import config from "config";

const apiURIV2 = config.environment.apiV2.uri + "/api/v2";
const firestoreTokenUri = `${apiURIV2}/session/firestore`;

class _FirebaseToken {
  issueToken() {
    return axios.post(firestoreTokenUri).then(success => success.data);
  }
}

/**
 * @ignore
 */
export const FirebaseToken = new _FirebaseToken();
export default FirebaseToken;
