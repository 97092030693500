import React, { PureComponent } from 'react';
import PropTypes from "prop-types";
import {injectStripe} from 'react-stripe-elements';
import {CardNumberElement, CardExpiryElement, CardCVCElement, PostalCodeElement} from 'react-stripe-elements';

const style = {
  base: {
    color: '#32325D',
    fontWeight: 500,
    fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
    fontSize: '16px',
    fontSmoothing: 'antialiased',

    '::placeholder': {
      color: '#CFD7DF',
    },
    ':-webkit-autofill': {
      color: '#e39f48',
    },
  },
  invalid: {
    color: '#E25950',

    '::placeholder': {
      color: '#E25950',
    },
  },
};

class CreditCardForm extends PureComponent {

  constructor(props, context) {
    super(props, context);

    this.state = {
      invalidCardNumberError: "",
      invalidCardExpirationError: "",
      invalidCVCError: "",
      invalidZipCodeError: ""
    };
  }

  onCardNumberChange = (event) => {
    if(event.error && event.error.message) {
      this.setState({ invalidCardNumberError: event.error.message });
    } else {
      this.setState({ invalidCardNumberError: "" });
    }
  }

  onCardExpirationChange = (event) => {
    if(event.error && event.error.message) {
      this.setState({ invalidCardExpirationError: event.error.message });
    } else {
      this.setState({ invalidCardExpirationError: "" });
    }
  }

  onCVCChange = (event) => {
    if(event.error && event.error.message) {
      this.setState({ invalidCVCError: event.error.message });
    } else {
      this.setState({ invalidCVCError: "" });
    }
  }

  onZipCode = (event) => {
    if(event.error && event.error.message) {
      this.setState({ invalidZipCodeError: event.error.message });
    } else {
      this.setState({ invalidZipCodeError: "" });
    }
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.props.stripe)} className="form clearfix">
        { this.props.errorMessage &&
          <span className="message-container">
            <span className="error-message">{ this.props.errorMessage }</span>
          </span>
        }
        <div className="row">
          <div className="col-md-12">
            <label for="card-number-element">
              Credit Card Number
            </label>
            <CardNumberElement
              style={style}
              onChange = { this.onCardNumberChange }
            />
            { this.state.invalidCardNumberError !== "" &&
              <span className="message-container">
                <span className="error-message">{ this.state.invalidCardNumberError }</span>
              </span>
            }
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <label for="card-expiry-element">
              Expiration
            </label>
            <CardExpiryElement
              style={style}
              onChange = { this.onCardExpirationChange }
            />
            { this.state.invalidCardExpirationError !== "" &&
              <span className="message-container">
                <span className="error-message">{ this.state.invalidCardExpirationError }</span>
              </span>
            }
          </div>
          <div className="col-md-4">
            <label for="card-cvc-element">
              CVC
            </label>
            <CardCVCElement
              style={style}
              onChange = { this.onCVCChange }
            />
            { this.state.invalidCVCError !== "" &&
              <span className="message-container">
                <span className="error-message">{ this.state.invalidCVCError }</span>
              </span>
            }
          </div>
          <div className="col-md-4">
            <label for="postal-code-element">
              Zip Code
            </label>
            <PostalCodeElement
              style={style}
              onChange = { this.onZipCode }
            />
            { this.state.invalidZipCodeError !== "" &&
              <span className="message-container">
                <span className="error-message">{ this.state.invalidZipCodeError }</span>
              </span>
            }
          </div>
        </div>

        <div className="center-block">
          <button className="btn btn-lg btn-subscribe btn-subscribe-pro btn-checkout">{ this.props.submitButtonTitle }</button>
        </div>
      </form>
    );
  }
}

CreditCardForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  stripe: PropTypes.object.isRequired,
  submitButtonTitle: PropTypes.string.isRequired,
};

export default injectStripe(CreditCardForm);
