import React, { PureComponent } from 'react';
import { connect }              from 'react-redux';
import {Helmet}                 from 'react-helmet';
import Footer                   from "layout/Footer";
import { Link }                 from "react-router";
import UserConfirmationActions  from 'actions/userConfirmation';
import config                   from "config";

/**
 * AccountConfirmation View
 */
export class AccountConfirmation extends PureComponent {

	constructor( props ) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    UserConfirmationActions.sendUserConfirmation(this.props.params.token);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.processingConfirmation && this.props.processingConfirmation && !nextProps.errorStatus) {
      if (navigator.userAgent.search( /Android|iPhone|iPod/ ) >= 0) {
        setTimeout(() => {
          window.location = `${config.environment.app.protocol}registration2/${this.props.params.token}`;
        }, 2000);
      }
    }
  }

  renderLoading() {
    return (
      <div className="row">
        <div className="loader-wrapper col-12">
          <div className="loader"></div>
        </div>
      </div>
    );
  }

  renderError() {
    const { errorStatus, errorMessage } = this.props;
    let title;
    let message;
    if (errorStatus === 'emailExists') {
      title = errorMessage;
      message = 'The confirmation process could not be completed as the new email is already in use. Please go to "Settings" -> "User Profile" -> "Change Email" and request a new email change.';
    } else if (errorStatus === 'invalidKey') {
      title = errorMessage;
      message = 'The provided confirmation token is invalid, has expired or used. For further information please contact our support team.';
    } else {
      title = 'There was an error confirming your email address';
      message = 'For further information please contact our support team.';
    }

    return (
      <div className="row confirmation-message">
        <div className="col-12 text-center">
          <img className="envelope" src="/assets/images/mail@2x.png" />
          <h1 className="title-error">{title}</h1>
          <h2>{message}</h2>
        </div>
      </div>
    );
  }

  renderConfirmationCompleted() {
    const { newEmail } = this.props.confirmationResult;
    return (
      <div className="row confirmation-message">
        <div className="col-12 text-center">
          <img className="envelope" src="/assets/images/mail@2x.png" />
          <h1 className="title-success">{'Your email address has been verified.'}</h1>
          <h2>
            { navigator.userAgent.search( /Android|iPhone|iPod/ ) < 0 ?
              <span>You can now go to your Flo by Moen APP and login with your user and password or click <Link to="/login">here</Link> to log into the User Portal.</span> :
              <span>You will be redirected shortly to your Flo by Moen APP..</span>
            }
          </h2>
        </div>
      </div>
    );
  }

  renderConfirmation() {
    const { confirmationResult, errorStatus } = this.props;

    if (errorStatus) {
      return this.renderError();
    } else {
      return this.renderConfirmationCompleted();
    }
  }

  render() {
    const { processingConfirmation } = this.props;

    return (
      <section className="account-confirmation">
        <Helmet>
          <title>Flo - Account Confirmation</title>
        </Helmet>
        <img src="/assets/images/FloByMoenLogo.png" alt="Flo Technologies" className="img-fluid flo-logo" />
        <div className="container main-container text-center">
          { processingConfirmation ? this.renderLoading() : this.renderConfirmation() }
        </div>
        <Footer />
      </section>
    )
  }
}

export default connect(state => ({
  processingConfirmation: state.userConfirmation.processingConfirmation,
  confirmationResult: state.userConfirmation.confirmationResult,
  errorMessage: state.userConfirmation.errorMessage,
  errorStatus: state.userConfirmation.errorStatus,
}))(AccountConfirmation);
