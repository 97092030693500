"use strict";

import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "layout/Header";
import LocationSelector from "layout/LocationSelector";
import Footer from "layout/Footer";
import DeviceStatus from 'constants/DeviceStatus';

/**
 *  This is the application root.
 */
class AppRoot extends Component {
  /**
   *  AppRoot constructor: This recieves props from the Router & Store through react-redux's "connect"
   *  @param { Object } props - Object of properties from the Router & Store through react-redux's  "connect"
   */
  constructor( props ){
    super(props);
  }

  getDevicePresenceClass() {
    return this.props.deviceStatus != DeviceStatus.ONLINE ? "no-device" : "";
  }

  render() {
    return (
      <section className={ this.getDevicePresenceClass() }>
        <Header />
        <LocationSelector />
        <main role="main">
          { this.props.children }
        </main>
        <Footer />
        <div id="FloModal"></div>
      </section> );
  }
}
/**
 * @ignore
 */
export default connect( state => ({
  deviceStatus: state.device.status
}))( AppRoot );
