"use strict";

import axios  from "axios";
import config from "config";

const insuranceLetterUrl = `${config.environment.api.uri}/api/v1/insurance-letter`;
const generateInsuranceLetterUrl = `${insuranceLetterUrl}/generate`;
const getDownloadInfoByLocationIdUrl = locationId => `${insuranceLetterUrl}/download/info/${locationId}`;
const redeemByLocationIdUrl = `${insuranceLetterUrl}/download/redeem`;

class _InsuranceLetter {

  generate(locationId) {
    return axios.post(generateInsuranceLetterUrl, { location_id: locationId }).then(result => result.data);
  }

  getDownloadInfo(locationId) {
    return axios.get(getDownloadInfoByLocationIdUrl(locationId)).then(result => result.data);
  }

  redeem(locationId) {
    return axios.post(redeemByLocationIdUrl, { location_id: locationId }).then(result => result.data);
  }
}

export const InsuranceLetter = new _InsuranceLetter();
export default InsuranceLetter;
