"use strict";

import React, { Component }         from "react";
import { connect }                  from "react-redux";
import { Badge }                    from 'reactstrap';
import { Link }                     from "react-router";

const supportLink = "https://solutions.moen.com/Smart_Water_Security_Products/Help_Center/Terms_and_Conditions/Terms_and_Conditions(FloProtect)";

export class DeductibleGuarantee extends Component {

  constructor(props, context) {
    super(props, context);
  }

  renderNonSubscribed() {
    return (
      <div className="row">
        <div className="col-12">
          <div className="inner-card non-subscribed">
            <div className="title"><span>Financial Security from Costly and Catastrophic Accidents</span></div>
            <div className="description"><span>Immediately add additional peace of mind with our We-Pay Deductible Guarantee. During a water damage event, you'll have a lot to deal with. Don't let the cost of your insurance deductible be one of them.</span></div>
            <div className="flo-protect-link"><Link to="/floprotect" >Activate now in your FloProtect Plan</Link></div>
          </div>
        </div>
      </div>
    );
  }

  renderSubscribed(alertsCount = 0) {
    return (
      <div className="row">
        <div className="col-12" style={{ padding: "0 30px" }}>
          <div className="inner-card subscribed-alerts row">
            <div className="col-12 col-lg-9" style={{ padding: "0" }}>
              <div className="title">
                { (alertsCount > 0) ?
                  (<div className="alerts"><Badge pill className={`alerts-count ${this.props.severity}`}>{ alertsCount }</Badge></div>)
                : (
                  <div className="icon">
                    <svg width="42px" height="42px" viewBox="0 0 84 84" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <circle className="border" cx="42" cy="42" r="40" strokeLinecap="round" strokeWidth="4" stroke="#000" fill="none"></circle>
                      <path className="checkmark" strokeLinecap="round" strokeLinejoin="round" d="M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338" strokeWidth="4" stroke="#000" fill="none"></path>
                    </svg>
                  </div>
                )}
                <div className={`label${(alertsCount > 0) ? ' with-alerts' : ''}`}><span>{ alertsCount > 0 ? '' : 'No ' }Alert{(alertsCount === 1) ? '' : 's'} Detected{ alertsCount > 0 ? '' : '!' }</span></div>
              </div>
              <div className="description"><span>{ alertsCount > 0 ? 'Fix all ' : 'Keep fixing ' }issues Flo alerts you about, and you're eligible for the $5,000 Deductible Guarantee.</span></div>
              <div className="see-all">
                <span>See all <a target="_blank" href={supportLink}>Deductible Guarantee terms</a></span>
              </div>
            </div>
            { (alertsCount > 0) &&
            <div className="col-12 col-lg-3 view-alerts">
              <Link to="/alerts">
                <Badge pill className="alerts-button">View Alerts</Badge>
              </Link>
            </div>
            }
          </div>
        </div>
      </div>
    );
  }

  renderInnerCard() {
    const { alertsCount, floProtectEnabled } = this.props;
    if (!floProtectEnabled) {
      return this.renderNonSubscribed();
    } else {
      return this.renderSubscribed(alertsCount);
    }
  }

  render() {
    const { alertsCount, floProtectEnabled, loading } = this.props;
    const statusClass = (floProtectEnabled) ? ((alertsCount > 0) ? 'badge-warning' : 'badge-on') : '';
    const statusText = (floProtectEnabled) ? ((alertsCount > 0) ? 'NEEDS ATTENTION' : 'ON') : 'OFF';

    return (
      <div className="container deductible-guarantee">
        { !loading &&
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="row">
                <div className="col-12">
                  <h2>$5,000 We-Pay Deductible Guarantee</h2>
                  <div>
                    <p className="subtitle">Even a little water in your home can become a costly headache. With our guarantee, you can rest even easier knowing that you will be protected from financial responsibility should you find yourself in an unfortunate water damage situation. See all terms  <a target="_blank" href={supportLink}>here</a>.</p>
                  </div>
                </div>
              </div>
              <div className="row status">
                <div className="col-12">
                  <span className="status-title">$5,000 Coverage</span>
                  <Badge pill className={`status-badge ${statusClass}`}>{statusText}</Badge>
                </div>
              </div>
              { this.renderInnerCard() }
            </div>
          </div>
        </div>
        }
      </div>
    );
  }
}

function getSeverity(pending, count) {
  if(pending.critical && pending.critical.length > 0) {
    return "critical";
  } else if(pending.warning && pending.warning.length > 0) {
    return "warning";
  } else if(count > 0) {
    return "info";
  } else {
    return "no-alerts";
  }
}

const mapStateToProps = state => ({
  alertsCount: state.alert.pendingTotal,
  severity: getSeverity(state.alert.pending, state.alert.pendingTotal),
  floProtectEnabled: state.subscription.isSubscribedToFloProtect,
  loading: state.alert.fetchingPendingAlerts
});

export default connect(mapStateToProps)(DeductibleGuarantee);
