"use strict";

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import floDetect from "actions/floDetect";
import _ from 'lodash';
import Toggle from "elements/Toggle";
import { Helmet } from "react-helmet";
import moment from 'moment/moment';
import {
  FEEDBACK_LIST,
  OTHER_VALUE,
  FIXTURE_MACHINES,
  COMPUTATION_DURATION
} from 'constants/FeedbackFloDetect';
import {
  Table,
  Alert,
  Container,
  Row,
  Col
} from 'reactstrap';

class FloDetectValidation extends PureComponent {

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.fixtureDetection, nextProps.fixtureDetection)) {
      this.setState({
        validations: nextProps.fixtureDetection.fixtures === undefined ? [] : nextProps.fixtureDetection.fixtures.map((fixture) =>
          ({
            index: fixture.index,
            gallons: fixture.gallons,
            name: fixture.name,
            ratio: fixture.ratio,
            type: fixture.type,
            num_events: fixture.num_events,
            feedback: {
              isSelected: true,
              reason: null,
              text: null
            }
          })
        )
      })
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      validations: this.props.fixtureDetection.fixtures.map((fixture) =>
        ({
          index: fixture.index,
          gallons: fixture.gallons,
          name: fixture.name,
          ratio: fixture.ratio,
          type: fixture.type,
          num_events: fixture.num_events,
          feedback: {
            isSelected: true,
            reason: null,
            text: null
          }
        })
      )
    };
  }

  onSelectDevice() {
    return (event) => {
      const deviceId = event.target.value;
      this.setState({ currentDeviceId: deviceId });
      this.setState({ feedbackSent: undefined });
      floDetect.retrieveLatestInRange(deviceId, COMPUTATION_DURATION);
    };
  }

  getRadioValue(i) {

    return this.state.validations.find(function (element) {
      return element.index === i;
    })["feedback"]["isSelected"]
  }

  onToggle(i) {
    return () => {
      this.setState({
        validations: this.state.validations.map((element) => {
          if (i === element.index) {
            return {
              ...element,
              feedback: {
                isSelected: !element.feedback.isSelected,
                reason: null,
                text: null
              }
            }
          }
          return element
        })
      })
    }
  }

  onSelectFeedback(index) {

    return (event) => {
      const value = event.target.value;

      const selectedFeedback = FEEDBACK_LIST.find(feedback => {
        return feedback.value == value
      });

      if (selectedFeedback !== undefined) {
        const text = selectedFeedback["text"];
        this.setState({
          validations: this.state.validations.map((element) => {
            if (index === element.index) {
              return {
                ...element,
                feedback: {
                  isSelected: element.feedback.isSelected,
                  reason: value,
                  text: text
                }
              }
            }
            return element
          })
        })
      }
    }
  }

  renderDropDown(index) {
    return this.getRadioValue(index) ?
      false :
      (
        <select onChange={this.onSelectFeedback(index)}>
          <option selected disabled hidden>What&#39;s wrong?</option>
          {FEEDBACK_LIST.map((feedback) => {
            return <option key={feedback.value} value={feedback.value}>{feedback.text}</option>
          })}
        </select>
      )
  }

  handleChangeOtherReason(index) {
    return (event) => {
      const otherReasonText = event.target.value;
      this.setState({
        validations: this.state.validations.map((element) => {
          if (index === element.index) {
            return {
              ...element,
              feedback: {
                ...element.feedback,
                text: otherReasonText
              }
            }
          }
          return element
        })
      });
    };
  }

  renderOtherReason(index) {
    return this.state.validations.map(val => {
      if(val.index === index && !val.feedback.isSelected && val.feedback.reason === OTHER_VALUE) {
        return <input type="text" name="otherReasonText" placeholder="Please specify reason"
                      onChange={this.handleChangeOtherReason(index)} />
      }
    });
  }

  renderSpecifiedMachines(name) {
    if(name === FIXTURE_MACHINES) {
      return <div>
        (dish washer/ washing machine/ ice maker/ refrigerator water)
      </div>
    }
  }

  renderTableBody(fixtures) {
    return (
      fixtures.map((fixture) => {
        return (
          <tr key={fixture.index}>
            <td className="looks-good-column">
              <Row>
                <Col className="toggle-container">
                  <span>
                    <Toggle nodes={["YES", "NO"]}
                      active={this.getRadioValue(fixture.index) ? 'YES' : 'NO'}
                      ref="deviceState"
                      className="state"
                      onToggle={this.onToggle(fixture.index)}
                      skipModal />
                  </span>
                  <div className="feedback-dropdown-container">
                    {this.renderDropDown(fixture.index)}
                  </div>
                  <div className="feedback-dropdown-container">
                    {this.renderOtherReason(fixture.index)}
                  </div>
                </Col>
              </Row>
            </td>
            <td className="fixture-type-column">
              {fixture.name}
              {this.renderSpecifiedMachines(fixture.name)}
            </td>
            <td>{fixture.num_events}</td>
            <td>{fixture.gallons}</td>
            <td>{Math.round(fixture.ratio * 100)}</td>
          </tr>
        )
      })
    )
  }

  submitDetections() {
    return () => {
      const validations =
        {
          fixtures: this.state.validations
            .map(validation => {
              const reason = validation.feedback.reason;
              return {
                ...validation,
                feedback: {
                  reason: reason != null ? Number(reason) : reason,
                  accurate: validation.feedback.isSelected,
                  other_reason: reason === OTHER_VALUE ? validation.feedback.text : null
                }
              }
            })
        };
      floDetect.saveValidations(validations, this.props.fixtureDetection.computationId,
        encodeURIComponent(moment(this.props.fixtureDetection.startDate).toISOString()))
        .then((response) => {
          this.setState({ feedbackSent: true });
        })
        .catch((error) => {
          this.setState({ feedbackSent: false });
        })
    }
  }


  renderTable(fixtures) {
    if (!Array.isArray(fixtures) || !fixtures.length) {
      return (
        <Alert color="primary">
          There is <strong>nothing</strong> to show
          </Alert>
      )
    }
    if (this.state.feedbackSent === undefined) {
      return (
        <Col>
          <Row className="select-device-container">
            <Table>
              <thead>
                <tr >
                  <th>Looks Good</th>
                  <th>Fixture Type</th>
                  <th>Events</th>
                  <th>Gallons Used</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                {this.renderTableBody(fixtures)}
              </tbody>
            </Table>
          </Row>
          <Row>
            <Col className="submit-button-container">
              <button onClick={this.submitDetections()}
                className="btn btn-lg btn-subscribe-pro">Submit
              </button>
            </Col>
          </Row>
        </Col>
      )
    }
    if (this.state.feedbackSent === true) {
      return (
        <Alert color="success">
          Feedback sent <strong>successfully</strong>
        </Alert>
      )
    }
    return (
      <Alert color="danger">
        There was an <strong>error</strong> sending the feedback
        </Alert>
    )
  }

  render() {
    const { fixtures, endDate } = this.props.fixtureDetection;
    let range = null
    if (endDate) {
      const endDateFormatted = moment(endDate).format("MM/DD/YYYY HH:mm");
      const label = `Calculations from the last 24 hours, last updated at ${endDateFormatted}`;
      range = <Row>
        <span className="select-device-label">{label}</span>
      </Row>
    }

    return (
      <div className="container usage">
        <Helmet>
          <title>Flo - Usage</title>
        </Helmet>
        <div className="row">
          <div className="col-12 bottom-margin">
            <div className="card h-100">

              <div className="card-header">
                <span>Flo Detect Validation</span>
              </div>

              <div className="card-body">
                <section className="usage fixture-consumption flo-detect">
                  <Container>
                    <Row className="select-device-container">
                      <span className="select-device-label">Please select a device Id:</span>

                      <select onChange={this.onSelectDevice()}>
                        <option selected disabled hidden>Device Id</option>
                        {this.props.devices.map((device) => {
                          return <option key={device} value={device}>{device}</option>
                        })}
                      </select>
                    </Row>
                    {
                      range
                    }
                    <Row>
                      <div className="table-responsive">
                        <table className="table">
                          {this.renderTable(fixtures)}
                        </table>
                      </div>
                    </Row>
                  </Container>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => {

  return (
    {
      fixtureDetection: { ...store.fixtureDetection },
      devices: [...store.device.list.map(device => device.device_id)]
    }
  );
})(FloDetectValidation);
