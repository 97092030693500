"use strict";
  
import React, { Component } from "react";
import { connect }          from "react-redux";
import { Link }             from "react-router";
  
import config               from "config";
  
import Device               from "actions/device";
  
import FloTabBar            from "layout/FloTabBar";
import FloCheckbox          from "elements/FloCheckbox";

const DAY_MINS = 24 * 60;
const TIMES = [...new Array( 24 ).keys()].reduce( ( arr, num ) => num < 10 ? (arr.push("0"+num+":00"), arr.push("0"+num+":30"),arr) : (arr.push(num+":00"), arr.push(num+":30"),arr), []);
TIMES.push( "23:59" );
const getStep = time => Math.ceil( ((parseInt( time.split(/:/)[ 1 ] ) + ( parseInt( time.split(/:/)[ 0 ] ) * 60 ))/(DAY_MINS))*48 );
const buildState = props => ({ ...props, step: getStep( props.time ) });

/**
 * Settings->Health Test->View
 */
export class Settings_HealthTest extends Component {
  SUBMIT_TIMEOUT = null;
  constructor( props ) {
    super();
    this.state = buildState( props );
  }
  componentWillMount() {
    const waiter = setInterval( () => {
      if ( this.state.icdId !== "" ) {
        clearInterval( waiter );
        Device.requestHealthTestConfig( this.state.icdId );  
      }  
    });
  }
  componentWillReceiveProps( nextProps ){
    this.setState( buildState( nextProps ) );
  }
  shouldComponentUpdate( nextProps, nextState ){
    return JSON.stringify( nextState ) !== JSON.stringify( this.state );
  }
  setTime( proxy ) {
    this.setState( buildState( { time: proxy.target.value } ), this.submitNewStartTime );
  }
  setStep( proxy ) {
    this.setState( buildState( { time: TIMES[ proxy.target.value ] } ), this.submitNewStartTime );
  }
  toggleDisabled( proxy, { checked } ) {
    Device.disableHealthTest( this.state.icdId, checked );
  }
  submitNewStartTime() {
    clearTimeout( this.SUBMIT_TIMEOUT );
    this.SUBMIT_TIMEOUT = setTimeout( () => {
      Device.setHealthTestStartTime( this.state.icdId, this.state.time );
      clearTimeout( this.SUBMIT_TIMEOUT );
      this.SUBMIT_TIMEOUT = null;
    }, 500 );
  }
  render() {
    return (<section className="settings-health-test">
              <FloTabBar path="/settings" items={ config.navigation.settings } selected="health-test" />
              <div className="wrapper">
                <h5 className="title">Health Tests</h5>
                <p>
                  Health test inspects your water system every evening at the same time. This is where you set the time, the time where it runs. The time here can be changed to be any time you want it to run, but once you set it you can't go back. Unitl you set it again of course, then it will be what ever time you've set it. If you wanted to have it run twice a day you could come change the start time after it runs the first time...
                </p>
                <p>OR, you could just press the Run Health Test button at the top of your page.</p>
                <div className="row controls">
                  <div className="col-md-2 manual">
                    <input type="time" value={ this.state.time } onChange={ this.setTime.bind( this ) } />
                  </div>
                  <div className="col-md-10 range">
                    <input type="range" min="0" max="48" step="1" value={ this.state.step } onChange={ this.setStep.bind( this ) }/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-10 ticks">
                    <div style={{width:"100.6%",marginLeft:"-0.25%"}}>
                      { new Array( 49 ).fill( 0 ).map((z,i) => <span key={i} className="tick">|</span>) }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-10 ticks-time">
                    <div style={{width:"100.6%",marginLeft:"-0.25%"}}>
                      { new Array( 49 ).fill( 0 ).map((z,i) => <span key={i} className="tick">{ i === 0 ? "12:00am" : i === 24 ? "12:00pm" : i === 48 ? "11:59pm" : ""  }</span>) }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 disable-test">
                    <FloCheckbox onChange={ this.toggleDisabled.bind( this ) } checked={ this.state.autoTestDisabled } /><label>I don't want the health test to be run authomatically.</label>
                  </div>
                </div>
              </div>
            </section>);
  }
}

export default connect( store => {
  const currentMacAddress = store.device.currentMacAddress;
  const index = store.device.list.length > 0 ? store.device.list.findIndex(device => device.device_id === currentMacAddress) : undefined;
  const { start_time: time, enabled } = store.device.list.length > 0
                                        && store.device.dict[ currentMacAddress ]
                                        && store.device.dict[ currentMacAddress ].healthTestConfig
                                         ? store.device.dict[ currentMacAddress ].healthTestConfig : { start_time: "01:00", enabled: true }
  return ({ 
    icdId: store.device.list.length > 0 ? store.device.list[ index ].id : "",
    time: time,
    autoTestDisabled: !enabled,
  })
})( Settings_HealthTest );
