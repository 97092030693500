import React, { PureComponent }     from 'react';
import PropTypes                    from 'prop-types';

class NoDevicesCard extends PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { title, description } = this.props;

    return (
      <div className="card no-device-stats-card h-100">
        <h2>{title}</h2>
        <div>
          <p className="subtitle">{description}</p>
        </div>
        <div>
          <a href="https://apps.apple.com/us/app/flo-by-moen/id1114650234" target="_blank"><img className="img-fluid" src="/assets/images/app_store.png" /></a>
          <a href="https://play.google.com/store/apps/details?id=com.flotechnologies&hl=en" target="_blank"><img className="img-fluid" src="/assets/images/google_play.png" /></a>
        </div>
      </div>
    );
  }
}

NoDevicesCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

NoDevicesCard.defaultProps = {
  title: 'Add a Device to this Location using the Flo Mobile App',
  description: 'This home currently has no connected devices. To start protecting your home, please use the Flo by Moen Mobile App to add devices.'
};

export default NoDevicesCard;
