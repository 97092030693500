"use strict";

import axios from "axios";
import config from "config";

const apiURIV2 = config.environment.apiV2.uri + "/api/v2";
const healthTestUri = deviceId => `${apiURIV2}/devices/${deviceId}/healthTest`;
const runHealthTestUri = deviceId => `${healthTestUri(deviceId)}/run`;
const getHealthTestUri = (deviceId, roundId) => `${healthTestUri(deviceId)}/${roundId}`;


/**
 * Health Test API Interface
 */
class _HealthTest {

  /**
   * Runs the health test for the device passed as argument.
   * @param {*} deviceId Device id (ICD id)
   */
  runHealthTest(deviceId) {
    return axios.post(runHealthTestUri(deviceId)).then(success => success.data);
  }

  /**
   * Gets the health test info by device id and round id.
   * @param {*} deviceId 
   * @param {*} roundId 
   */
  getHealthTestInfo(deviceId, roundId) {
    return axios.get(getHealthTestUri(deviceId, roundId)).then(success => success.data);
  }
}
/**
 * @ignore
 */
export const HealthTest = new _HealthTest();
export default HealthTest;
