"use strict";

import {
  UPDATE_USER_DATA,
  UPDATE_USER_METADATA,
  UPDATE_NEXT_LOCATION
} from "constants/action";
import { Authenticate } from "actions/authentication";
import Store from "store";
import { from } from "rxjs";
import UserAPI from "api/user";
import AuthenticationAction from "./authentication";
import SafeQuery from "./common/SafeQuery";

const updateUserData = data => ({
  type: UPDATE_USER_DATA,
  data: data
});

const updateNextLocation = locationId => ({
  type: UPDATE_NEXT_LOCATION,
  locationId
});

/**
 * User Actions module
 */
class _User extends SafeQuery {

  /**
   * Fetch User data and populate the store
   */
  @Authenticate
  hydrateStore(){
    return this.getUserData()
      .then(userData => {
        Store.dispatch(updateUserData(userData));
      })
      .catch(err => console.log(err));
  }
	/**
   * Set a peice of user data and populate the store with the change
   * @param { {email: string, password: string, phone: string} } User - Set User Data [ email, & phone ].
   */
  @Authenticate
  setUserData(data) {
    const filteredData = _.pickBy(data, _.identity);

    return AuthenticationAction
      .getUserTokenInfo()
      .then(userTokenInfo => {
        return UserAPI.setUserData(userTokenInfo.user_id, filteredData);
      })
      .then(() => {
        Store.dispatch(updateUserData(filteredData));
      })
      .catch(error => {
        const status = (error.response && error.response.status) ? error.response.status : 500;
        
        if (status === 400) {
          const error = Error('Invalid phone number');
          return Promise.reject(error);
        }
      });
  }

  @Authenticate
  getUserData(forceRefresh = false) {
    return AuthenticationAction.getUserTokenInfo()
      .then(userToken => {
        const userState = Store.getState().user;
        if (!forceRefresh && userState.locations.length > 0 && userState.id) {
          return Promise.resolve(userState);
        }

        const expand = ['locations', 'alarmSettings'];
        return this.getUserById(userToken.user_id, expand)
      })
      .catch(error => console.log("Cannot retrieve user data"));
  }

  /**
   * Subscribes to API call to avoid calling get Users endpoint about 10 times when loading the page.
   */
  @Authenticate()
  getUserById(userId, expand) {
    const getUserByIdQuery = this.createQuery('User.getUserById', UserAPI.getUserById);
    return getUserByIdQuery(userId, expand);
  }

  updateNextLocation(locationId) {
    Store.dispatch(updateNextLocation(locationId));
  }

};
/**
 * @ignore
 */
export const User = new _User();
export default User;
