"use strict";

import React, { PureComponent }   from 'react';
import { connect }                from "react-redux";
import EmailChangeActions         from 'actions/emailChange';
import EmailChangeRequestForm     from 'components/elements/EmailChangeRequestForm';

class EmailChangeRequest extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      initialFormValues: {
        email: '',
        emailConfirm: '',
      }
    }
  }

  componentDidMount() {
    this.setState({ initialFormValues: { email: '', emailConfirm: '' } });
    EmailChangeActions.cleanEmailChangeRequestData();
  }

  save = (formValues) => {
    if (formValues.email) {
      return EmailChangeActions.sendEmailChangeRequest(formValues.email);
    }
  }

  render() {
    return (
      <div className="card h-100">
        <div className="card-header">
          <span>Change Email</span>
        </div>
        <div className="card-body">
          <EmailChangeRequestForm
            initialValues       = {this.state.initialFormValues}
            errorMessage        = {this.props.errorMessage}
            errorStatus         = {this.props.errorStatus}
            submittingRequest   = {this.props.submittingRequest}
            requestResult       = {this.props.requestResult}
            onSubmit            = {this.save}
          />
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  errorMessage: state.emailChange.errorMessage,
  errorStatus: state.emailChange.errorStatus,
  submittingRequest: state.emailChange.submittingRequest,
  requestResult: state.emailChange.requestResult,
}))(EmailChangeRequest);

