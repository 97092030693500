"use strict";

import React from 'react';

function getDefaultOptionText(inputName, defaultOptionText) {
  return defaultOptionText ? defaultOptionText : "Select a " + inputName;
}

const ComboTextField = ({
    input,
    label,
    type,
    options,
    className,
    disabled,
    showDefaultOption,
    defaultOptionText,
    meta: { touched, error, warning }
  }) => {

  const optionList = [ ...options ];
  optionList.push({ value: '', display: 'Other'});

  const value = input.value;
  const selectValue = (options.map(v => v.value).includes(value) || value === undefined) ? value : '';

  return (
    <div>
      <label>
        {label}
      </label>
      <div className="row">
        <div className={(selectValue === '') ? 'col-6' : 'col-12'}>
          <select
            {...input}
            value={selectValue}
            disabled={disabled}
            className={ className + ( (touched && error) ? " invalid" : "" )  }
          >
            {(typeof showDefaultOption === 'undefined' || showDefaultOption || defaultOptionText) &&
              <option value="">
                { getDefaultOptionText(input.name, defaultOptionText) }
              </option>
            }

            {optionList.map(( item, i ) => (
              <option value={item.value} key={i}>
                {item.display}
              </option>
            ))}
          </select>
        </div>
        { selectValue === '' &&
          <div className="col-6">
            <input
              {...input}
              disabled={disabled}
              className={ className + ( (touched && error) ? " invalid" : "" )  }
              placeholder={ label }
              type={type}
            />
          </div>
        }
      </div>
      <span>
          {touched &&
          ((error &&
          <span className="error-message">
            {error}
          </span>) ||
          (warning &&
          <span>
            {warning}
          </span>))}
        </span>
    </div>
  );
};

export default ComboTextField;
