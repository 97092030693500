"use strict";

import React, { Component, PureComponent }  from "react";
import { connect }                          from "react-redux";
import { Link }                             from "react-router";
import Setting                              from "actions/setting";
import Location                             from 'actions/locationProfile';
import Dialog                               from "elements/Dialog";
import {Helmet}                             from "react-helmet";
import ConsumptionGoalsForm                 from "elements/ConsumptionGoalsForm";
import { getFormValues }                    from 'redux-form';
import { convertFromGallons, displayLongVolumeUnit, convertToGallons } from "../../constants/UnitSystem";

export class Settings_ConsumptionGoals extends Component {
  constructor(props) {
    super();
    this.state = {
      submitting: false
    };
  }

  componentDidMount(){
    Setting.hydrateStore();
  }

  save = (formValues) => {
    const { unitSystem } = this.props;
    const volumeValuePerHomePerDay = formValues.gallonsPerHomePerDay;
    const gallonsPerHomePerDay = convertToGallons(volumeValuePerHomePerDay, unitSystem);

    if ( gallonsPerHomePerDay < this.props.occupants ) {
      new Dialog("FloModal", "error" )
        .ok( "The goal must be a minimum of 1 gal per day for every occupant. Your occupancy is currently set to: " + this.props.occupants );
      return;
    }

    return Location.saveLocationProfile(formValues.locationId, {
      gallonsPerDayGoal: Math.round(gallonsPerHomePerDay) || 1
    });
  }

  render() {
    return (
      <section className="container settings-goals">
        <Helmet>
          <title>Flo - Consumption Goals</title>
        </Helmet>

        <div className="row">
          <div className="col-12 bottom-margin">
            <div className="card">
              <div className="card-header">
                Consumption Goals
              </div>
              <div className="card-body">
                <ConsumptionGoalsForm
                  initialValues={{
                    locationId: this.props.locationId,
                    gallonsPerPersonPerDay: this.props.gallonsPerPersonPerDay,
                    gallonsPerHomePerDay: this.props.gallonsPerHomePerDay,
                  }}
                  occupants={ this.props.occupants }
                  onSubmit={ this.save }
                  unitSystem={ this.props.unitSystem }
                />
              </div>
              <div className="card-footer text-muted">
                <small>
                  * You have <b>{ this.props.occupants } person(s)</b> in your home. <em>(You can change the number of persons in your <Link to="/settings/home-profile">Home Profile</Link> page.)</em>
                </small>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default connect(state => {
  const unitSystem = state.user.unitSystem;
  const occupants = parseInt(state.user.currentLocation.occupants);
  const gallonsPerHomePerDay = Math.round(convertFromGallons(state.user.currentLocation.gallonsPerDayGoal, unitSystem) || 0);
  const gallonsPerPersonPerDay = gallonsPerHomePerDay / occupants;
  const locationId = state.user.currentLocation.id;

  return {
    gallonsPerHomePerDay,
    gallonsPerPersonPerDay,
    occupants,
    locationId,
    consumptionGoalsForm: getFormValues('consumption-goals-form')(state),
    unitSystem
  };
})(Settings_ConsumptionGoals);
