"use strict";

import React, { Component } from "react";
import Header                 from "layout/landing/Header";
import Footer                 from "layout/landing/Footer";
import {browserHistory}       from "react-router";
import UserRegistration       from "actions/userRegistration";
import {Helmet}               from "react-helmet";
import PropTypes              from "prop-types";
import GoogleTag              from "utils/GoogleTag";
import ReactUtils             from "utils/ReactUtils";

/**
 * EmailConfirmation View
 */
export class EmailConfirmation extends Component {

	constructor( props ) {
    super(props);
    this.state = {
      address: '',
      latitude: 0,
      longitude: 0
    };
  }

  componentDidMount() {
    const accountInfo = JSON.parse(localStorage.getItem('accountCreationInfo'));

    if(accountInfo.address === '') {
      browserHistory.push('/l/all-included');
    } else {
      this.setState({
        ...accountInfo
      });

      GoogleTag.gtag('event', 'PageView', { 'url': ReactUtils.getFullPathFromProps(this.props) });
    }
  }

  resend = (event) => {
    UserRegistration.resendEmailConfirmation(this.state.email);
  }

  render() {
    return (
      <section className="account-creation-landing email-confirmation">
        <Helmet>
          <title>Flo - Guarantee Your Home's Protection from Water Damage</title>
        </Helmet>

        <Header />

        <div className="container main-container">
          <div className="row multi-step-indicator">
            <div className="col-12 text-center">
              <i className="fas fa-circle"></i><i className="fas fa-circle active"></i><i className="fas fa-circle"></i>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <img className="envelope" src="/assets/images/mail@2x.png" />
              <h1>Please confirm your email address</h1>
              <h2>
                Last step: We've sent you a confirmation email to <strong>{this.state.email}</strong>. Please go to your
                inbox and click the link inside to verify your email address.
              </h2>

              <button className="btn-landing btn-light-blue-landing" onClick={ this.resend }>
                Resend Email
              </button>
            </div>
          </div>
        </div>

        <Footer />
      </section>
    )
  }
}

EmailConfirmation.contextTypes = {
  mixpanel: PropTypes.object.isRequired
};

export default EmailConfirmation;
