"use strict";

import React, { Component }   from "react";
import Header                 from "layout/landing/Header";
import Footer                 from "layout/landing/Footer";
import UserRegistration       from "actions/userRegistration";
import {Helmet}               from "react-helmet";
import PropTypes              from "prop-types";
import GoogleTag              from "utils/GoogleTag";
import ReactUtils             from "utils/ReactUtils";

/**
 * EmailConfirmed View
 */
export class EmailConfirmed extends Component {

	constructor( props ) {
    super(props);
    this.state = {
      confirmed: false,
      loading: true
    };
  }

  componentDidMount() {
	  UserRegistration
      .verifyAccount(this.props.params.token)
      .then(() => {
        localStorage.removeItem('accountCreationInfo');
        this.setState({ loading: false, confirmed: true });

        GoogleTag.gtag('event', 'PageView', { 'url': ReactUtils.getFullPathFromProps(this.props) });
        GoogleTag.gtag('event', 'conversion', {'send_to': 'AW-836984716/2t5eCObZoIMBEIy_jY8D'});
      })
      .catch(() => this.setState({ loading: false, confirmed: false }));
  }

  render() {
    return (
      <section className="account-creation-landing email-confirmed">
        <Helmet>
          <title>Flo - Account confirmation</title>
        </Helmet>

        <Header />

        <div className="container main-container">
          <div className="row multi-step-indicator">
            <div className="col-12 text-center">
              <i className="fas fa-circle"></i><i className="fas fa-circle"></i><i className="fas fa-circle active"></i>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              { this.state.loading &&
              <div>
                <h1>Processing the confirmation ...</h1>
              </div>
              }

              { !this.state.loading && !this.state.confirmed &&
              <div>
                <h1>There was an error trying to confirm your email</h1>
                <h2>Please try again later or contact our support team.</h2>
              </div>
              }

              { !this.state.loading && this.state.confirmed &&
              <div>
                <i className="far fa-check-circle fa-7x"></i>
                <h1>You’re Confirmed!</h1>
                <h2>
                  We’ll email you a customized plan for guaranteeing your home against water damage soon! <br />
                  You can close this window.
                </h2>
              </div>
              }
            </div>
          </div>
        </div>

        <Footer />
      </section>
    )
  }
}

EmailConfirmed.contextTypes = {
  mixpanel: PropTypes.object.isRequired
};

export default EmailConfirmed;
