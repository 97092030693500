import React, { PureComponent } from "react";
import ContentLoader from "react-content-loader";

const MAX_TEXT_WIDTH = 250;
const MIN_TEXT_WIDTH = 110;
const getQuestionTextWidth = (min, max) => Math.floor(Math.random() * max) + min;;

export class HomeProfileSkeleton extends PureComponent {
  constructor(props) {
		super(props);
	}

  render() {
    return (
      <ContentLoader
        height={800}
        width={800}
        speed={3}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="10" rx="3" ry="3" width={getQuestionTextWidth(MIN_TEXT_WIDTH, MAX_TEXT_WIDTH)} height="10" /> 
        <rect x="0" y="32" rx="3" ry="3" width="800" height="20" />

        <rect x="0" y="80" rx="3" ry="3" width={getQuestionTextWidth(MIN_TEXT_WIDTH, MAX_TEXT_WIDTH)} height="10" /> 
        <rect x="0" y="102" rx="3" ry="3" width="800" height="20" />

        <rect x="0" y="150" rx="3" ry="3" width={getQuestionTextWidth(MIN_TEXT_WIDTH, MAX_TEXT_WIDTH)} height="10" /> 
        <rect x="0" y="172" rx="3" ry="3" width="800" height="20" />

        <rect x="0" y="220" rx="3" ry="3" width={getQuestionTextWidth(MIN_TEXT_WIDTH, MAX_TEXT_WIDTH)} height="10" /> 
        <rect x="0" y="242" rx="3" ry="3" width="800" height="20" />

        <rect x="0" y="290" rx="3" ry="3" width={getQuestionTextWidth(MIN_TEXT_WIDTH, MAX_TEXT_WIDTH)} height="10" /> 
        <rect x="0" y="312" rx="3" ry="3" width="800" height="20" />

        <rect x="0" y="360" rx="3" ry="3" width={getQuestionTextWidth(MIN_TEXT_WIDTH, MAX_TEXT_WIDTH)} height="10" /> 
        <rect x="0" y="382" rx="3" ry="3" width="800" height="20" />

        <rect x="0" y="430" rx="3" ry="3" width={getQuestionTextWidth(MIN_TEXT_WIDTH, MAX_TEXT_WIDTH)} height="10" /> 
        <rect x="0" y="452" rx="3" ry="3" width="800" height="20" />

        <rect x="0" y="500" rx="3" ry="3" width={getQuestionTextWidth(MIN_TEXT_WIDTH, MAX_TEXT_WIDTH)} height="10" /> 
        <rect x="0" y="522" rx="3" ry="3" width="800" height="20" />

        <rect x="0" y="570" rx="3" ry="3" width={getQuestionTextWidth(MIN_TEXT_WIDTH, MAX_TEXT_WIDTH)} height="10" /> 
        <rect x="0" y="592" rx="3" ry="3" width="800" height="20" />

        <rect x="0" y="640" rx="3" ry="3" width={getQuestionTextWidth(MIN_TEXT_WIDTH, MAX_TEXT_WIDTH)} height="10" /> 
        <rect x="0" y="662" rx="3" ry="3" width="800" height="20" />

        <rect x="0" y="710" rx="3" ry="3" width={getQuestionTextWidth(MIN_TEXT_WIDTH, MAX_TEXT_WIDTH)} height="10" /> 
        <rect x="0" y="732" rx="3" ry="3" width="800" height="20" />
      </ContentLoader>
    );
  }
}

export default HomeProfileSkeleton;
