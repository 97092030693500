"use strict";

import React  from 'react';

const InputField = ({
                      input,
                      label,
                      type,
                      placeholder,
                      className,
                      disabled,
                      meta: { touched, error, warning }
                    }) => (
  <div>
    {label &&
      <label>
        {label}
      </label>
    }

    <div>
      { type === 'checkbox' && <span style={{ paddingRight: 10 }}>Yes / No: </span> }
      <input
        {...input}
        style={ (type === 'checkbox') ? { width: 'unset', display: 'inline' } : {}}
        disabled={disabled}
        className={ className + ( (touched && error) ? " invalid" : "" )  }
        placeholder={ placeholder || label }
        type={type}
      />
    </div>

    <span className="message-container">
      {touched &&
      ((error &&
      <span className="error-message">
        {error}
      </span>) ||
      (warning &&
      <span>
        {warning}
      </span>))}
    </span>
  </div>
);

export default InputField;
