"use strict";

import {
  UPDATE_SETTING,
  FETCH_ALARMS_SUCCESS,
  UPDATE_HOME_PROFILE_FIELDS,
  LOADING_HOME_PROFILE_FIELDS,
} from "constants/action";
import _ from "lodash";

const sevToStr = num => num === 1 ? "critical" : num === 2 ? "warning" : "info";
const sevToNum = str => str.toLowerCase() === "critical" ? 1 : str.toLowerCase() === "warning" ? 2 : 3;
const sysModeToStr = num => num === 2 ? "home" : num === 3 ? "away" : "sleep";
const sysModeToNum = str => str === "home" ? 2 : str === "away" ? 3 : 5;
const getAlarmKey = id => `alarm-${id}`;

/**
 * Initial State for Settings Store
 */
const initialState = {
  homeProfileFields      : [],
  loadingProfileFields   : false,
  contacts               : [],
  stateList              : [],
  countryList            : [],
  timezoneList           : [],
  notificationPreferences: {
    display: {
      critical: [],
      warning : [],
      info    : []
    }
  },
  alarms: []
};

/**
 * Settings Reducer
 * @param { Object } state - State of the 'Settings' Store
 * @param { Object } action - Action Object. Always has action.type, which should match a constant
 * @return { Object } - Updated Settings Store
 */
export const settings = ( state = initialState, action ) => {
  let newState = { ...state };
  switch( action.type ) {
    case FETCH_ALARMS_SUCCESS: {
      return { ...state, alarms: _.keyBy(action.data, 'id') };
    };
    case UPDATE_SETTING: {
      newState = {
        ...newState,
        ...action.data
      };
    }; break;

    case UPDATE_HOME_PROFILE_FIELDS: {
      const data = action.data
        .filter(item => item.fieldName && item.type);

      return {
        ...state,
        loadingProfileFields: false,
        homeProfileFields: data
      };
    }

    case LOADING_HOME_PROFILE_FIELDS: {
      return { ...state, loadingProfileFields: true };
    }

    default: break;
  }
  return newState;
}
export default settings;
