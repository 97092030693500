const ValveStatus = {
  ON: "on",
  OFF: "off",
  IN_TRANSITION: "inTransition",
  UNKNOWN: "unknown",
  BROKEN: "broken"
};


export default ValveStatus;
