"use strict";

import React, { Component } from "react";
import { render, unmountComponentAtNode } from "react-dom";
import LabelInput from "elements/LabelInput";

/**
 * LegacyDialogBox component definition:
 */
export class LegacyDialogBox extends Component {
  /**
   * React Lifecycle: Initilizes state with initial properties
   * @param { Object } props - Initial properties being passed into the component
   */
  constructor( props ) {
    super();
    this.state = { ...props };
  }
  /**
   * Setup dialog button set based on type
   * @return { Array } - of buttons
   */
	getButtons(){
    let buttons = [];
		if ( this.state.type && this.state.type.indexOf( "confirm" ) > -1 ) {
			buttons.push( { reaction: "fulfill", html: this.state.options.yesBtn } );
			buttons.push( { reaction: "reject", html: this.state.options.noBtn } );
    } else if ( this.state.type && this.state.type === "ok" ) {
			buttons.push( { reaction: "fulfill", html: this.state.options.okBtn } );
		}
    if ( this.state.options.buttons instanceof Array ) {
      buttons = [
        ...buttons,
        ...this.state.options.buttons
      ]
    }
    return buttons;
	}
  /**
   * Builds message box with message based on type
   * @return { JSX } - message || message and input
   */
  renderMessage() {
		if ( this.state.type === "confirmInput" ){
			return ( <div className="message">
				{ this.state.message }
				<div className="input-wrapper">
					<LabelInput label={ this.state.options.inputLabel } placeholder={this.state.inputPlaceholder} ref={ this.state.options.inputName } >
						<input type={ this.state.options.inputType } name={ this.state.options.inputName } required={ this.state.options.inputRequired } />
					</LabelInput>
				</div>
			</div> );
		} else {
			return ( <div className="message">{this.state.message}</div>);
		}
  }
  /**
   * Response action, executes button reaction then `destroy`s the message box
	 * Calls: {@link  destroy}
   * @return { undefined } - returns nothing
   */
  respond = proxy => {
    const buttonReaction = proxy && proxy.target.getAttribute( "data-reaction" ) ? proxy.target.getAttribute( "data-reaction" ) : undefined;
    const buttonValue = proxy && proxy.target.getAttribute( "data-value" ) ? proxy.target.getAttribute( "data-value" ) : undefined;
    let value = this.state.type === "confirmInput" ? this.refs[ this.state.options.inputName ].refs.in.value : buttonValue ;
    this.props[ buttonReaction ? buttonReaction : "reject" ]( value );
    this.destroy();
  }
  /**
   * Destroy the message box
   * @return { undefined } - returns nothing
   */
  destroy() {
    this.state.onUnmount();
  }
	/**
   * React Lifecycle: render - Is fired when the <LegacyDialogBox /> component is leveraged to draw to the screen.
   * Calls: {@link  renderMessage}
   * @return { JSX } - a JSX Object
   */
  render() {
    return ( <div className={ this.state.type + " " + this.state.className + " dialog-wrapper"} data-reaction="reject" onClick={ this.respond }>
              <div className="dialog" onClick={ proxy => proxy.stopPropagation() }>
                { this.renderMessage() }
								{ this.getButtons().map( ( button, i ) => {
                  return ( <button key={ i } data-reaction={ button.reaction } onClick={ this.respond } data-value={ button.value }>{ button.html }</button> );
                })}
              </div>
            </div> );
  }
}

/**
 * AlarmDialogBox component definition:
 */
export class AlarmDialogBox extends Component {
  /**
   * React Lifecycle: Initilizes state with initial properties
   * @param { Object } props - Initial properties being passed into the component
   */
  constructor( props ) {
    super();
    this.state = {
			...props,
			className: props.className || "",
		};
  }
  /**
   * Response action, executes button reaction then `destroy`s the message box
	 * Calls: {@link  destroy}
   * @return { undefined } - returns nothing
   */
  respond = proxy => {
    const buttonReaction = proxy && proxy.target.getAttribute( "data-reaction" ) ? proxy.target.getAttribute( "data-reaction" ) : undefined;
    this.props[ buttonReaction ? buttonReaction : "reject" ]();
    this.destroy();
  }

  /**
   * Destroy the message box
   * @return { undefined } - returns nothing
   */
  destroy() {
    this.state.onUnmount();
  }

	/**
   * React Lifecycle: render - Is fired when the <AlarmDialogBox /> component is leveraged to draw to the screen.
   * Calls: {@link  renderMessage}
   * @return { JSX } - a JSX Object
   */
  render() {
		const linkBaseURI = "/support/alarms/";
    return ( <div className={ this.state.className + " dialog-wrapper alarm-dialog" } data-reaction="reject" onClick={ this.respond }>
              <div className="dialog" onClick={ proxy => proxy.stopPropagation() }>
								<div className="close" data-reaction="reject" onClick={ this.respond }><i className="icon-Closing-X"></i></div>
								<div className="inner-wrapper">
									<div className="alarm-bell"></div>
									<h4 className="title">{ this.state.title }</h4>
									<div className="message">
										{ this.state.message }
									</div>
									<div className="buttons">
										<a className="modal-button learn-more" href={ linkBaseURI + this.state.options.alarm_id + "/learnmore" } target="_blank">Learn More<i className="icon-Info"></i></a>
										<a className="modal-button clear-alert" onClick={ this.respond } data-reaction="fulfill">I'm on it, clear the alert<i className="icon-Check"></i></a>
										<a className="modal-button troubleshoot" href={ linkBaseURI + this.state.options.alarm_id + "/troubleshoot" } target="_blank">Troubleshoot<i className="icon-Gear"></i></a>
									</div>
								</div>
              </div>
            </div> );
  }
}

/**
 * IgnoreAlarmDialogBox component definition:
 */
export class IgnoreAlarmDialogBox extends Component {
  /**
   * React Lifecycle: Initilizes state with initial properties
   * @param { Object } props - Initial properties being passed into the component
   */
  constructor( props ) {
    super();
    this.state = {
			...props,
			className: props.className || "",
		};
  }
  /**
   * Response action, executes button reaction then `destroy`s the message box
	 * Calls: {@link  destroy}
   * @return { undefined } - returns nothing
   */
  respond = ( fulfill, proxy ) => {
    const buttonReaction = proxy && proxy.target.getAttribute( "data-reaction" ) ? proxy.target.getAttribute( "data-reaction" ) : undefined;
    this.props[ buttonReaction ? buttonReaction : "reject" ]( fulfill || undefined );
    this.destroy();
  }

  /**
   * Destroy the message box
   * @return { undefined } - returns nothing
   */
  destroy() {
    this.state.onUnmount();
  }

	/**
   * React Lifecycle: render - Is fired when the <IgnoreAlarmDialogBox /> component is leveraged to draw to the screen.
   * Calls: {@link  renderMessage}
   * @return { JSX } - a JSX Object
   */
  render() {
		const linkBaseURI = "/support/alarms/";
    return ( <div className={ this.state.className + " dialog-wrapper alarm-dialog" } data-reaction="reject" onClick={ this.respond }>
              <div className="dialog" onClick={ proxy => proxy.stopPropagation() }>
								<div className="close" data-reaction="reject" onClick={ this.respond }><i className="icon-Closing-X"></i></div>
								<div className="inner-wrapper">
									<div className="alarm-bell"></div>
									<h4 className="title">{ this.state.title }</h4>
									<div className="message">
										{ this.state.message }
									</div>
									<div className="buttons">
										{ this.state.options.actions.map( item => (<a key={ item.id } className="modal-button" onClick={this.respond.bind( this, item.id ) } data-reaction="fulfill">{item.text}</a> ) ) }
									</div>
								</div>
              </div>
            </div> );
  }
}

/**
 * Dialog Class Definition
 */
export class Dialog {
  INITIAL_OPTIONS = {
    yesBtn: "Yes",
    noBtn : "No",
    okBtn : "Ok",
  }
  /**
   * Represents a Dialog / Modal: builds initial state;
   * @constructor
   * @param {string} elementId - The id of the element you wish to render the dialog in.
   * @param {string} style - The type of dialog. [ "success" || "warning" || "error" ]
   */
  constructor( elemId, style = "noStyle" ) {
    this.state = {
      style,
      parent: elemId ? document.getElementById( elemId ) : document.getElementById( "the-dialog-wrapper" )
    }
  }

  /**
   * Unmounts the component from the parent.
   */
  handleUnmountComponent = () => {
    unmountComponentAtNode(this.state.parent);
  };

  /**
   * Confirmation dialog box. Two buttons: 'Ok' and 'Cancel'
   * @confirm
   * @param { String } message - The message to display in the dialog
   * @param { Object } options - Object of options list
   * @returns { Promise } From @drawLegacy method with type "confirm"
   */
  confirm( message, options ) {
		return this.drawLegacy( "confirm", message, { ...this.INITIAL_OPTIONS, ...options } );
	}

  /**
   * Confirmation dialog box with customizable input
   * @confirmInput
   * @param { String } message - The message to display in the dialog
   * @param { Object } options - Object of options list
   * @returns { Promise } From @drawLegacy method with type "confirmInput"
   */
  confirmInput( message, options ) {
		return this.drawLegacy( "confirmInput", message, { ...this.INITIAL_OPTIONS, ...options } );
	}

  /**
   * Ok dialog box for messages only.
   * @ok
   * @param { String } message - The message to display in the dialog
   * @param { Object } options - Object of options list
   * @returns { Promise } From @drawLegacy method with type "ok"
   */
  ok( message, options ) {
		return this.drawLegacy( "ok", message, { ...this.INITIAL_OPTIONS, ...options } );
	}

	/**
   * Custom dialog box.
   * @custom
   * @param { String } message - The message to display in the dialog
   * @param { Object } options - Object of options list ( Required options: buttons: {}
   * @returns { Promise } From @drawLegacy method with type "custom"
   */
  custom( message, options ) {
    return this.drawLegacy( "custom", message, { ...this.INITIAL_OPTIONS, ...options } );
  }

  /**
   * @param { String } title - The title to display in the dialog
   * @param { String } message - The message to display in the dialog
   * @param { Object } options - Object of options list
   * @returns { Promise } From @drawAlarm method
   */
  alert( title, message, options = {} ) {
    return this.drawAlarm( title, message, options );
  }

  ignoreAlert( title, message, options = {} ) {
    return this.drawIgnoreAlarm( title, message, options );
  }

  /**
   * draws / Renders the legacy dialog box - This is the main function that the different dialog type's access to draw the legacy dialog box
   * @drawLegacy
   * @param { String } type - The type of dialog box you wish to display
   * @param { String } message - The message to display in the dialog
   * @param { Object } options - Object of options list
   * @returns { Promise } for the response of the dialog: ( ok/yes === truthy && cancel === falsy )
   */
  drawLegacy( type, message, options ){
    return new Promise( ( fulfill, reject ) => {
      render( <LegacyDialogBox className={ this.state.style } type={ type } options={ {...options} } message={ message } fulfill={ fulfill } reject={ reject } wrapper={ this.state.parent } onUnmount={this.handleUnmountComponent} />, this.state.parent );
    });
  }

	/**
   * draws / Renders the dialog box - This is the main function that the different dialog type's access to draw the dialog box
   * @draw
   * @param { String } title - The title to display in the dialog
   * @param { String } message - The message to display in the dialog
   * @param { !Object } options - Object of options list
   * @returns { Promise } for the response of the dialog: ( ok/yes === truthy && cancel === falsy )
   */
  drawAlarm( title, message, options ) {
		return new Promise( ( fulfill, reject ) => {
			render( <AlarmDialogBox className={ this.state.style } title={ title } message={ message } options={ {...options} } fulfill={ fulfill } reject={ reject } wrapper={ this.state.parent } onUnmount={this.handleUnmountComponent} />, this.state.parent );
		});
	}
	/**
   * draws / Renders the dialog box - This is the main function that the different dialog type's access to draw the dialog box
   * @draw
   * @param { String } title - The title to display in the dialog
   * @param { String } message - The message to display in the dialog
   * @param { !Object } options - Object of options list
   * @returns { Promise } for the response of the dialog: ( ok/yes === truthy && cancel === falsy )
   */
  drawIgnoreAlarm( title, message, options ) {
    return new Promise( ( fulfill, reject ) => {
      render( <IgnoreAlarmDialogBox className={ this.state.style } title={ title } message={ message } options={ {...options} } fulfill={ fulfill } reject={ reject } wrapper={ this.state.parent } onUnmount={this.handleUnmountComponent} />, this.state.parent );
    });
  }
}

/**
 * @ignore
 */
export default Dialog;
