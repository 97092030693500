"use strict";

import config from "config";
import {
  UPDATE_DEVICE,
  RECEIVE_DEVICE_LIST,
  UPDATE_DEVICE_STATUS,
  ADD_DEVICE_STATUS_FIRESTORE_REFERENCE,
  SET_CURRENT_DEVICE,
  UPDATE_FIRESTORE_DATA,
  FETCHING_HEALTH_TEST_DATA,
  FETCHED_HEALTH_TEST_DATA_SUCCESS,
  FETCHED_HEALTH_TEST_DATA_ERROR,
  CLEAR_HEALTH_TEST_DATA
} from "constants/action";
import DeviceAPI from "api/device";
import { Authenticate } from "actions/authentication";
import Store from "store";
import AlertApi from 'api/alert';
import HealthTestAPI from 'api/healthTest';
import FirebaseTokenApi from 'api/firebaseToken';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import DeviceStatus from 'constants/DeviceStatus';
import LocationProfile from './locationProfile';
import FloDetect from "./floDetect";
import Consumption from "./consumption";
import Alert from "./alert";

const twentyFourHours = 86400;

const updateDevice = ( id, json ) => ({
  type: UPDATE_DEVICE,
  device: { id: id, data: json }
});

const receiveDeviceList = devices => ({
  type: RECEIVE_DEVICE_LIST,
  devices
});

const updateDeviceStatus = connectionStatus => {
  return {
    type: UPDATE_DEVICE_STATUS,
    connectionStatus
  };
};

const addDeviceStatusFirestoreReference = reference => ({
  type: ADD_DEVICE_STATUS_FIRESTORE_REFERENCE,
  deviceStatusFirestoreReference: reference
});

const setCurrentDevice = macAddress => ({
  type: SET_CURRENT_DEVICE,
  macAddress
});

const updateFirestoreData = (macAddress, firestore) => ({
  type: UPDATE_FIRESTORE_DATA,
  firestore,
  macAddress
});

const fetchingHealthTestData = () => ({
  type: FETCHING_HEALTH_TEST_DATA
});

const fetchedHealthTestDataSuccess = data => ({
  type: FETCHED_HEALTH_TEST_DATA_SUCCESS,
  data
});

const fetchedHealthTestDataError = message => ({
  type: FETCHED_HEALTH_TEST_DATA_ERROR,
  message
});


const clearHealthTestData = () => ({
  type: CLEAR_HEALTH_TEST_DATA,
});

/**
 * Device Actions module
 */
class _Device {

  isConnectedFirestore() {
    try {
      firebase.app();
      return true;
    } catch (err) {
      return false;
    }
  }

  connectFirestore() {
    if (!this.isConnectedFirestore()) {
      firebase.initializeApp({
        apiKey: config.environment.firebase.apiKey,
        projectId: config.environment.firebase.projectID,
        databaseURL: config.environment.firebase.databaseURL,
        authDomain: config.environment.firebase.authDomain,
      });

      return FirebaseTokenApi.issueToken().then(data => {
        return firebase
          .auth()
          .signInWithCustomToken(data.token)
          .catch((error) => console.log(error.message));
      });
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Get the device object
   * @return { Promise } - to return the device object
   */
  @Authenticate()
  get(){
    return new Promise( ( fulfill, reject ) => {
      const deviceStore = Store.getState().device;
      const index = this._getCurrentDeviceIndex();

      if ( index !== undefined && deviceStore.list.length > 0 && deviceStore.list[ index ][ "device_id" ] && deviceStore.dict[ deviceStore.list[ index ][ "device_id" ] ] ) {
        fulfill( { ...deviceStore.dict[ deviceStore.list[ index ][ "device_id" ] ],  ...deviceStore.list[ index ] } );
      } else {
        LocationProfile
          .hydrateStore()
          .then(location => {
            const devices = location.devices;
            const deviceIndex = this._getCurrentDeviceIndex();
            if ( deviceIndex !== undefined && devices && devices.length > 0 && typeof devices[ deviceIndex ] === "object" ) {
              const currentDevice = devices[ deviceIndex ];
              fulfill({ ...currentDevice, device_id: currentDevice.macAddress });
            } else {
              reject("No device");
            }
          })
          .catch(reject);
      }
    });
  }

  _getCurrentDeviceIndex() {
    const deviceStore = Store.getState().device;
    if (deviceStore.list.length < 1) {
      return undefined;
    }
    const index = deviceStore.list.findIndex(l => l.device_id === deviceStore.currentMacAddress);
    if (index < 0) {
      return console.error("Could not find device index");
    }
    return index;
  }

  changeCurrentDevice(macAddress) {
    const prevMacAddress = Store.getState().device.currentMacAddress;
    const userStore = Store.getState().user;

    this.setActiveDevice(prevMacAddress, macAddress);

    FloDetect.retrieveLatestInLastHours(
      twentyFourHours, (userStore.currentLocation.timezone !== '') ? userStore.currentLocation.timezone : undefined
    );
    Consumption.getDailyGoalConsumption();
    Alert.hydrateStore();
    Consumption.getWeatherTemperatureToday(macAddress);
  }

  /**
   * Set the active device
   * @param {*} prevMacAddress 
   * @param {*} macAddress 
   */
  setActiveDevice(prevMacAddress, macAddress) {
    this.unsubscribeAllDeviceStatus();

    if (macAddress) {
      Store.dispatch(setCurrentDevice(macAddress));
      this.subscribeDeviceStatus(macAddress);
    }
  }

  /**
   * Find the QR Code for this device
   * @return { Promise } - to return the QR Code information
   */
  @Authenticate()
  fetchQRCode(macAddress) {
		return DeviceAPI.fetchQRCode(macAddress).catch(error => console.log(error));
  }
  /**
   * Set valve state for this device [ Fire&Forget ]
   * @param { string } state - new state for the valve
   */
  @Authenticate()
  setValveState(state) {
    this.get().then(device => DeviceAPI.setValveState(device.id, state === "on" ? "open" : "close")).catch(error => console.log(error));
  }
  /**
   * Run health check for this device
   */
  @Authenticate()
  runHealthCheck() {
    Store.dispatch(clearHealthTestData());
    this.get().then(device => HealthTestAPI.runHealthTest(device.id)).catch(error => console.log(error));
  }
  /**
   * Get health test info for this device
   */
  @Authenticate()
  fetchHealthTestInfo(roundId) {
    Store.dispatch(fetchingHealthTestData());
    this.get()
      .then(device => HealthTestAPI.getHealthTestInfo(device.id, roundId))
      .then(result => Store.dispatch(fetchedHealthTestDataSuccess(result)))
      .catch(error => Store.dispatch(fetchedHealthTestDataError(error)));
  }

  @Authenticate()
  subscribeDeviceStatus(deviceId) {
    if(deviceId == null){
      Store.dispatch(updateDeviceStatus(DeviceStatus.NO_DEVICE));
    } else {
      this.connectFirestore()
      .then(() => Store.dispatch(addDeviceStatusFirestoreReference(this._getSubscriptionByDeviceId(deviceId))))
      .catch(() => Store.dispatch(updateDeviceStatus(DeviceStatus.UNKNOWN)))
    }
  }

  _getSubscriptionByDeviceId(macAddress) {
    const db = firebase.firestore();
    const device = (uid) => db.doc(`devices/${uid}`);
    return device(macAddress)
      .onSnapshot(snapshot => {
        const data = snapshot.data();
        Store.dispatch(updateFirestoreData(macAddress, data));
        Store.dispatch(updateDeviceStatus((data && data.isConnected) ? DeviceStatus.ONLINE : DeviceStatus.OFFLINE));
      }, err => {
        console.log(`Encountered error: ${err}`);
        Store.dispatch(updateDeviceStatus(DeviceStatus.UNKNOWN));
      });
  }

  @Authenticate()
  unsubscribeAllDeviceStatus() {
    const state = Store.getState();
    if (state.device && state.device.deviceStatusFirestoreReferences) {
      state.device.deviceStatusFirestoreReferences.forEach(ref => ref());
    }
  }
}

/**
 * @ignore
 */
export const Device = new _Device();
export default Device;
