"use strict";

import React, { PureComponent } from "react";
import { Link }                 from "react-router";
import ReactEChart              from "elements/ReactEChart";
import { displayLongVolumeUnit } from 'constants/UnitSystem';

export default class DailyUsageGoalCard extends PureComponent {
  constructor(props) {
    super(props);
  }

  consumptionGoalPorcentageProgressText(percentage) {
    if(percentage < 30) {
      return "";
    } else if(percentage < 51) {
      return `${ percentage.toFixed(2) }%`;
    } else {
      return `${ percentage.toFixed(2) }% remaining`;
    }
  }

  render() {
    const galonsDifference = this.props.gallonsPerDayGoal - this.props.gallonsConsumedToday;
    const remainingGalonsToday = galonsDifference < 0 ? 0 : galonsDifference.toFixed(2);
    const remainingPercentage = this.props.gallonsPerDayGoal > 0 ?
      (remainingGalonsToday / this.props.gallonsPerDayGoal) * 100 : 100;
    const usedPercentage = parseFloat(this.props.gallonsConsumedToday) > parseFloat(this.props.gallonsPerDayGoal) ?
      0 :
      (this.props.gallonsConsumedToday / this.props.gallonsPerDayGoal) * 100;

    const remainingProgressStyle = {
      width: `${remainingPercentage}%`
    };

    const usedProgressStyle = {
      width: `${usedPercentage}%`
    };

    const graphStyles = {
      height: "200px"
    };

    return (
      <div className="card daily-usage-goal">
        <div className="card-header">
          Daily Usage Goal
          <Link className="float-right" to="/settings/consumption-goals">Edit goal</Link>
        </div>
        <div className="card-body">
          <div className="row bottom-margin">
            <div className="col-12">
                    <span className="gallons-consumed-vs-goal-total">
                      <strong className="gallons-consumed">
                        { this.props.gallonsConsumedToday }
                      </strong>
                      <span className="goal-total"> / { this.props.gallonsPerDayGoal }
                      </span> {displayLongVolumeUnit(this.props.unitSystem)} used
                    </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 align-self-center">
              <div className="progress progress-bg">
                <div className="progress-bar flo-yellow"
                     role="progressbar"
                     style={ usedProgressStyle }
                     aria-valuenow={ usedPercentage }
                     aria-valuemin="0"
                     aria-valuemax="100">
                </div>
                <div className="progress-bar progress-bar-delimiter progress-bar-left-aligned flo-green"
                     role="progressbar"
                     style={ remainingProgressStyle }
                     aria-valuenow={ remainingPercentage }
                     aria-valuemin="0"
                     aria-valuemax="100">
                        <span className="progress-bar-text">
                          { this.consumptionGoalPorcentageProgressText(remainingPercentage) }
                          </span>
                </div>
              </div>
            </div>
          </div>

          <ReactEChart
            option={ this.props.gallongsConsumedTodayGraphOptions }
            style={ graphStyles }
            showLoading={ false }
          />
        </div>
      </div>
    );
  }
}
