"use strict";

import {
  SUBSCRING_TO_FLO_PROTECT,
  SUBSCRIBED_TO_FLO_PROTECT,
  UPDATE_SUBSCRIPTION_INFO,
  UPDATE_PLAN_INFO,
  UPDATE_SOURCE_ID,
  FLO_PROTECT_SUBSCRIPTION_FAILED,
  UPDATE_COUPON_INFO,
  UPDATE_PAYMENT_METHOD_FORM_INFO,
  UNSUBSCRIBING_FROM_FLO_PROTECT,
  UNSUBSCRIBED_FROM_FLO_PROTECT,
  FLO_PROTECT_UNSUBSCRIPTION_FAILED,
  CLEAN_PAYMENT_FORM_STATE,
} from "constants/action";

const initialState = {
  id: null,
  isSubscribedToFloProtect: false,
  checkoutFormState: "clean",
  errorMessage: "",
  plan: {
    plan_id: "default"
  },
  sourceId: null,
  couponInfo: {},
  status: "unknown",
  cancelAtPeriodEnd: false,
  updatePaymentMethod: {
    isModalOpen: false,
    paymentInfo: undefined
  },
};

const subscription = (state = initialState, action) => {
  switch( action.type ){
    case SUBSCRING_TO_FLO_PROTECT: {
      return {
        ...state,
        checkoutFormState: "submitting"
      };
    };break;
    case SUBSCRIBED_TO_FLO_PROTECT: {
      return {
        ...state,
        isSubscribedToFloProtect: true,
        checkoutFormState: "submitted",
        status: 'trailing'
      };
    };break;
    case FLO_PROTECT_SUBSCRIPTION_FAILED: {
      return {
        ...state,
        checkoutFormState: "error",
        errorMessage: action.payload.errorMessage
      };
    }
    case UPDATE_SUBSCRIPTION_INFO: {
      return {
        ...state,
        id: action.data.id,
        isSubscribedToFloProtect: action.data.isActive,
        status: action.data.status,
        cancelAtPeriodEnd: action.data.providerInfo && action.data.providerInfo.cancelAtPeriodEnd,
        currentPeriodEnd: action.data.providerInfo && action.data.providerInfo.currentPeriodEnd,
      };
    };break;
    case UPDATE_PLAN_INFO: {
      return {
        ...state,
        plan: {
          ...state.plan,
          ...action.data
        }
      };
    };break;
    case UPDATE_SOURCE_ID: {
      return {
        ...state,
        sourceId: action.data.sourceId
      };
    };break;
    case UPDATE_COUPON_INFO: {
      return {
        ...state,
        couponInfo: action.data
      };
    };break;
    case UPDATE_PAYMENT_METHOD_FORM_INFO: {
      return {
        ...state,
        updatePaymentMethod: {
          ...state.updatePaymentMethod,
          ...action.data
        }
      };
    };break;
    case UNSUBSCRIBING_FROM_FLO_PROTECT: {
      return {
        ...state,
        checkoutFormState: "submitting"
      };
    };
    case UNSUBSCRIBED_FROM_FLO_PROTECT: {
      return {
        ...state,
        checkoutFormState: "submitted",
      };
    };
    case FLO_PROTECT_UNSUBSCRIPTION_FAILED: {
      return {
        ...state,
        checkoutFormState: "error",
        errorMessage: action.payload.errorMessage
      };
    };
    case CLEAN_PAYMENT_FORM_STATE: {
      return {
        ...state,
        checkoutFormState: "clean",
      };
    };
  }
  return { ...state };
};

export default subscription;
