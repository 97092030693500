"use strict";

import React, { PureComponent } from "react";
import ContentLoader from "react-content-loader";

export class InsuranceLetterSkeleton extends PureComponent {
  constructor(props) {
		super(props);
	}

  render() {
    return (
      <ContentLoader
        height={1000}
        width={800}
        speed={3}
        primaryColor="#ecebeb"
        secondaryColor="#D4D4D4"
        {...this.props}
      >
        <rect x="92.63" y="48.61" rx="0" ry="0" width="98.01" height="60.35" />
        <rect x="617.63" y="128.61" rx="0" ry="0" width="89.62" height="11.83" />
        <rect x="91.56" y="189.27" rx="0" ry="0" width="181.25" height="12.04" />
        <rect x="91.56" y="219.68" rx="0" ry="0" width="132.03" height="11.83" />
        <rect x="91.56" y="249.68" rx="0" ry="0" width="132.03" height="11.83" />
        <rect x="91.56" y="265.68" rx="0" ry="0" width="113.97" height="12.14" />
        <rect x="91.56" y="281.68" rx="0" ry="0" width="159.41" height="11.36" />
        <rect x="91.8" y="310.04" rx="0" ry="0" width="603.24" height="12.09" />
        <rect x="91.6" y="326.84" rx="0" ry="0" width="614.62" height="12.09" />
        <rect x="92.15" y="344.09" rx="0" ry="0" width="606.74" height="12.46" />
        <rect x="91.95" y="362.38" rx="0" ry="0" width="608.28" height="12.46" />
        <rect x="91.63" y="381.22" rx="0" ry="0" width="358.01" height="12.09" />
        <rect x="90.63" y="518.56" rx="0" ry="0" width="210.79" height="56.61" />
        <rect x="91.63" y="412.4" rx="0" ry="0" width="382.87" height="11.53" />
        <rect x="91.95" y="441.75" rx="0" ry="0" width="592.69" height="12.09" />
        <rect x="91.22" y="458.61" rx="0" ry="0" width="70.19" height="12.28" />
        <rect x="91.22" y="486.61" rx="0" ry="0" width="109.18" height="12.28" />
        <rect x="92.22" y="592.61" rx="0" ry="0" width="140.37" height="12.28" />
        <rect x="91.22" y="610.61" rx="0" ry="0" width="155.97" height="11.35" />
        <rect x="91.22" y="636.61" rx="0" ry="0" width="210.56" height="11.35" />
        <rect x="91.22" y="654.61" rx="0" ry="0" width="163.77" height="11.35" />
        <rect x="279.22" y="849.61" rx="0" ry="0" width="241.75" height="10.97" />
        <rect x="263.2" y="867.61" rx="0" ry="0" width="272.95" height="11.35" />
        <rect x="304.17" y="884.61" rx="0" ry="0" width="187.16" height="12.28" />
        <rect x="335.32" y="903.61" rx="0" ry="0" width="116.98" height="12.28" />
      </ContentLoader>
    );
  }
}

export default InsuranceLetterSkeleton;
