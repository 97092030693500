const GALLONS_TO_LITERS_FACTOR = 3.785412;
const PSI_TO_KPA_FACTOR = 6.89476;

const UnitSystem = {
  IMPERIAL: 'imperial_us',
  METRIC: 'metric_kpa'
};

const volumeUnits = {
  [UnitSystem.IMPERIAL]: {
    short: 'gal',
    long: 'gallons'
  },
  [UnitSystem.METRIC]: {
    short: 'L',
    long: 'liters'
  },
};

const temperatureUnits = {
  [UnitSystem.IMPERIAL]: {
    short: '°F',
    long: 'Fahrenheit'
  },
  [UnitSystem.METRIC]: {
    short: '°C',
    long: 'Celsius'
  },
};

const flowUnits = {
  [UnitSystem.IMPERIAL]: {
    short: 'gpm',
    long: 'Gallons per Minute'
  },
  [UnitSystem.METRIC]: {
    short: 'lpm',
    long: 'Liters per Minute'
  },
};

const pressureUnits = {
  [UnitSystem.IMPERIAL]: {
    short: 'PSI',
    long: 'Pounds Per Square Inch'
  },
  [UnitSystem.METRIC]: {
    short: 'kPa',
    long: 'Kilopascal'
  },
};

export const convertFromGallons = (gallons, unitSystem = UnitSystem.IMPERIAL) => {
  return (unitSystem === UnitSystem.IMPERIAL) ? gallons : gallons * GALLONS_TO_LITERS_FACTOR;
};

export const convertToGallons = (litersOrGallons, unitSystem = UnitSystem.IMPERIAL) => {
  return (unitSystem === UnitSystem.IMPERIAL) ? litersOrGallons : litersOrGallons / GALLONS_TO_LITERS_FACTOR;
};

export const convertFromPSI = (psi, unitSystem = UnitSystem.IMPERIAL) => {
  return (unitSystem === UnitSystem.IMPERIAL) ? psi : psi * PSI_TO_KPA_FACTOR;
};

export const convertFromFahrenheit = (temp, unitSystem = UnitSystem.IMPERIAL) => {
  if (unitSystem === UnitSystem.IMPERIAL) {
    return temp;
  } else {
    return temp ? (temp - 32) / 1.8 : 0;
  }
};

export const displayShortVolumeUnit = (unitSystem = UnitSystem.IMPERIAL) => volumeUnits[unitSystem].short;
export const displayLongVolumeUnit = (unitSystem = UnitSystem.IMPERIAL) => volumeUnits[unitSystem].long;
export const displayShortTempUnit = (unitSystem = UnitSystem.IMPERIAL) => temperatureUnits[unitSystem].short;
export const displayLongTempUnit = (unitSystem = UnitSystem.IMPERIAL) => temperatureUnits[unitSystem].long;
export const displayShortPressureUnit = (unitSystem = UnitSystem.IMPERIAL) => pressureUnits[unitSystem].short;
export const displayLongPressureUnit = (unitSystem = UnitSystem.IMPERIAL) => pressureUnits[unitSystem].long;
export const displayShortFlowUnit = (unitSystem = UnitSystem.IMPERIAL) => flowUnits[unitSystem].short;
export const displayLongFlowUnit = (unitSystem = UnitSystem.IMPERIAL) => flowUnits[unitSystem].long;

export const Unit = {
  displayShortVolumeUnit,
  displayLongVolumeUnit,
  displayShortTempUnit,
  displayLongTempUnit,
  displayShortPressureUnit,
  displayLongPressureUnit,
  displayShortFlowUnit,
  displayLongFlowUnit
};

export const Converter = {
  convertFromGallons,
  convertToGallons,
  convertFromPSI,
  convertFromFahrenheit
};

export default UnitSystem;
