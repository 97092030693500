import {
  SEND_USER_CONFIRMATION,
  SEND_USER_CONFIRMATION_SUCCESS,
  SEND_USER_CONFIRMATION_ERROR,
} from "constants/action";

const initialState = {
  processingConfirmation: false,
  confirmationResult: {},
  errorMessage: null,
  errorStatus: null,
};

const userConfirmation = (state = initialState, action) => {
  switch(action.type) {
    case SEND_USER_CONFIRMATION: {
      return {
        ...state,
        processingConfirmation: true,
        errorMessage: null,
        errorStatus: null,
      };
    }

    case SEND_USER_CONFIRMATION_SUCCESS: {
      return {
        ...state,
        processingConfirmation: false,
        errorMessage: null,
        errorStatus: null,
        confirmationResult: action.payload.data,
      };
    }

    case SEND_USER_CONFIRMATION_ERROR: {
      return {
        ...state,
        processingConfirmation: false,
        errorMessage: action.payload.message,
        errorStatus: action.payload.status,
        confirmationResult: {},
      };
    }

    default:
      return state;
  }
  return state;
};

export default userConfirmation;
