/** @module API#Authentication */
"use strict";

import axios  from "axios";
import config from "config";

// API GW - Multi-Locations
const apiURIV2 = config.environment.apiV2.uri + "/api/v2";
const presenceUri = `${apiURIV2}/presence/me`;
const passwordResetUri = `${apiURIV2}/users/password/request-reset`;

/**
 * Authentication API Interface
 */
class _Authentication {
	/**
   * request token for authenticating actions / requests
   * @param { String } email - login credential email
   * @param { String } password - login credential password
   * @throws { Error } - New error if there is no { email } or { password }
   * @return { Promise } - returns a promise
   */
	requestToken( email, password ) {
    if ( !email || !password ) {
      throw new Error( "email and password required" );
    }
    return axios
            .post( `${config.environment.api.uri}/api/v1/users/auth`,
            {
              username: email,
              password: password,
            });
	}
  /**
   * @param { string } email - Admin email
   * @param { string } password - Admin password
   * @param { string } uid - User ID that is to be impersonated
   */
  impersonate( email, password, uid ) {
    if ( !email || !password || !uid ){
      throw new Error( "email, password, and userId required" );
    }
    return axios
            .post( `${config.environment.api.uri}/api/v1/auth/user/${uid}/impersonate`,
						{
              username: email,
              password: password,
            });
  }
  /**
   * @param { string } email - Account email
   * @return { Promise } - return Promise of success.data
   */
  resetPasswordRequest( email ){
    return axios.post(passwordResetUri, { email: email }).then(success => success.data);
  }
  resetPasswordRequestToken( userId, resetToken ){
    if ( !userId || !resetToken ) {
      throw new Error( "userId, and resetToken required" );
    }
    return axios.get( `${config.environment.api.uri}/api/v1/users/requestreset/${userId}/${resetToken}`).then( success => success.data.token );
  }
  /**
   *
   */
  resetPassword( password, authToken) {
    return axios.put( `${config.environment.api.uri}/api/v1/users/me`, { password }, { headers: { authorization: authToken } } );
  }
  /**
   * @param { string } currPass - User's current password
   * @param { string } newPass - Password user wishes to change too
   * @param { string } newPassConf - Password match to confirm this is the password the user wishes to change too
   * @return { Promise } - return Promise with fulfill of success.data on statuses: 200 - 300, and 400 - 500, manually errors
   */
  setPassword( currPass, newPass, newPassConf ){
    return axios
        .post( `${config.environment.api.uri}/api/v1/users/me/resetpassword`,
        {
          old_pass: currPass,
          new_pass: newPass,
          new_pass_conf: newPassConf,
        },
        {
          validateStatus: status => ( status >= 200 && status <  300 ) || ( status >= 400 && status < 500 )
        })
        .then( success => success.data );
  }
  /**
   * Round trip for authentication token timeout
   * @return { Promise } - that fulfills with a boolean value on token's validity
   */
	tokenHealthCheck() {
    return new Promise( ( fulfill, reject ) => {
      axios
        .get( `${config.environment.api.uri}/api/v1/usertokens/me` )
        .then( res => {
          if ( new Date().getTime() >= ( res.data.time_issued * 1000 ) + ( res.data.expiration * 1000 ) ){
            reject( "Token Expired" );
            return;
          }
          fulfill();
        }, reject )
        .catch( reject );
    });
	}

  getUserTokenInfo() {
    return axios.get(`${config.environment.api.uri}/api/v1/usertokens/me`).then(result => result.data);
  }

  /**
   * Send's logout request to API
   * @return { Promise } - that fulfills on successful deauth of token in the API
   */
  logout(){
    return axios.post(`${config.environment.api.uri}/api/v1/users/logout` );
  }

  /**
   * Notify backend of user's active presence on apps or websites
   */
  notifyPresence() {
    return axios.post(presenceUri).then(result => result.data);
  }
}

/**
 * @ignore
 */
export const Authentication = new _Authentication();
export default Authentication;
