"use strict";

import axios from "axios";
import config from "config";

// API GW - Multi-Location
const apiURIV2 = config.environment.apiV2.uri + "/api/v2";
const locationsByIdURI = (locationId, expands = '') => `${apiURIV2}/locations/${locationId}${expands}`;
const listsURI = `${apiURIV2}/lists`;
const countriesV2URI = `${listsURI}/country`;
const regionByCountryV2URI = countryCode => `${listsURI}/region_${countryCode}`;
const timezonesByRegionV2URI = (countryCode, regionCode) => `${listsURI}/timezone_${countryCode}_${regionCode}`;
const timezonesByCountryV2URI = countryCode => `${listsURI}/timezone_${countryCode}`;
const setSystemModeByIdV2URI = locationId => `${apiURIV2}/locations/${locationId}/systemMode`;
const listsURIById = listId => `${listsURI}/${listId}`;

/**
 * Settings->Location API interface
 */
class _Location {

  fetchTimezones(countryCode, stateCode) {
    let promise;

    if(stateCode) {
      promise = axios.get(timezonesByRegionV2URI(countryCode, stateCode.toLowerCase()));
    } else {
      promise = axios.get(timezonesByCountryV2URI(countryCode));
    }

    return promise.then(success => {
      const data = success.data.items;

      return data.map(tz => ({
        tz: tz.key,
        display: tz.longDisplay
      }));
    });
  }
  /**
   * Get countries list
   * @return { Promise } - to return countries list
   */
  fetchCountries() {
    return axios.get( countriesV2URI ).then( success => {
      return success.data.items.map(c => ({
        locale: c.key,
        name: c.longDisplay
      }));
    } );
  }
  /**
   * Get states list for a country
   * @param { string } country - 2 character country code eg: US | AU
   * @return { Promise } - to return state list
   */
	fetchStates( countryCode ) {
    return axios.get( regionByCountryV2URI(countryCode) ).then( success => {
      return success.data.items.map(s => ({
        name: s.longDisplay,
        abbrev: s.key.toUpperCase()
      }));
    });
	}

  /**
   * Save location parts by location ID - API V2
   * 
   * @param { String } locationId - Location ID
   * @param { Object } data - Object literal dictionary of location parts and thier values
   * @return { Promise } - to return the successful data that was replaced for the entire location block
   */
  saveLocationById(locationId, data) {
    return axios.post(locationsByIdURI(locationId), data).then( success => success.data );
  }

  /**
   * Fetch location data, including devices list.
   * 
   * @param { String } locationId - Location id
   * @param { Boolean } expandDevices - Default value true.
   * @return { Promise } - to return the location data
   */
  fetchLocationById(locationId, expandDevices = true, expandSubscriptions = false) {
    const params = [];
    if (expandDevices) {
      params.push('devices');
    }
    if (expandSubscriptions) {
      params.push('subscription');
    }
    const expands = `?expand=${params.join(',')}`;
    return axios.get(locationsByIdURI(locationId, expands)).then( success => success.data );
  }

  /**
   * Set system mode by location ID - API V2
   * 
   * @param { String } locationId - Location ID
   * @param { Number } mode - System Mode
   * @param { Number } revertMinutes - Minutes to sleep, 0 otherwise. Default value is 0.
   * @param { Number } revertMode - Previous mode after the location goes to sleep. Default is "mode".
   * @return { Promise } - to return the successful data that was replaced
   */
  setSystemModeData(locationId, mode, revertMinutes, revertMode) {
    const systemMode = {
      target: mode,
      revertMinutes: revertMinutes,
      revertMode: revertMode
    };
    return axios.post(setSystemModeByIdV2URI(locationId), systemMode).then( success => success.data );
  }

  /**
   * Get list by list id
   * @param { String } listId
   * @return { Promise } - to return countries list
   */
  getListValues(listId) {
    return axios.get(listsURIById(listId)).then(success => {
      return success.data.items.map(it => ({
        value: it.key,
        display: it.shortDisplay
      }));
    });
  }

  getHomeProfileFields() {
    return axios.get(listsURIById('profile_qn_location')).then(success => success.data);
  }
}

/**
 * @ignore
 */
export const Location = new _Location();
export default Location;
