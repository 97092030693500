"use strict";

import { ALERT_LOG_UPDATE, ALERT_LOG_UPDATING, ALERT_LOG_ERROR, FETCH_PENDING_ALERTS_SUCCESS, FETCH_CLEARED_ALERTS_SUCCESS } from "constants/action";
import moment from "moment";

const initialState = {
  pendingTotal: 0,
  clearedTotal: 0,
  pending: {
    warning : [],
    critical: []
  },
  cleared: [],
  fetchingPendingAlerts: false,
};

/**
 * Allowed Actions in the Alert Reducer
 */
const alertsActions = [
  ALERT_LOG_UPDATE,
  ALERT_LOG_UPDATING,
  ALERT_LOG_ERROR,
  FETCH_PENDING_ALERTS_SUCCESS,
  FETCH_CLEARED_ALERTS_SUCCESS
];


const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const oneDay = hour * 24;
const twoDays = oneDay * 2;
const threeDays = twoDays + oneDay;
const fourDays = twoDays * 2;
const humanReadableDate = date => {
    return new Date( date ).toLocaleDateString("en-US", {
      year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit"
    }).toString().replace( /,(?!.*,)/g, " at" );
};

const friendlyDescriptionResolver = event => ([
  { key: "{$incident_date_time}", value: humanReadableDate(event.createAt) }
]);

const relativeDate = delta  => {
  switch( true ){
    case delta > 0 && delta <= hour:{
      return "under 1 hour";
    }break;
    case delta > hour && delta <= oneDay:{
      const hours = Math.floor( delta / hour );
      return "over " + hours + " hour" + ( hours > 1 ? "s" :"" );
    }break;
    case delta > oneDay && delta < twoDays: {
      return "over 1 day";
    }break;
    case delta > twoDays && delta < threeDays: {
      return "over 2 days";
    }break;
    case delta > threeDays && delta < fourDays: {
      return "over 3 days";
    }break;
    default: break;
  }
};

const flattenDate = date => {
  const universal = moment.utc(date).toDate();
  const delta = new Date().getTime() - universal;
  if ( delta >= fourDays ){
    return humanReadableDate( universal );
  } else {
    return relativeDate( delta ) + " ago";
  }
};

const getSystemModes = incidents => {
  const systemModes = new Set(incidents.map(incident => incident.systemMode));
  return Array.from(systemModes);
};

const mapIncidentsToPendingAlarms = incidents => ({
  incident_id: incidents[0].id,
  friendly_name: incidents.length > 1 ?  incidents[0].displayTitle + ` (${incidents.length >= 100 ? '> 100' : incidents.length})`: incidents[0].displayTitle,
  alarm_id: incidents[0].alarm.id,
  system_modes: getSystemModes(incidents),
  system_mode_text: getSystemModes(incidents).join( " / " ), 
  incident_time: flattenDate(incidents[0].createAt),
  severity: incidents[0].alarm.severity,
  friendly_description: incidents[0].displayMessage,
  count: incidents.length
});

const replacePlaceholders = (placeholderResolver, description, event) => (
  placeholderResolver(event).reduce((acc, curr) => acc.replace(curr.key, curr.value), description)
);

/**
 * Alert's Reducer
 * @param { Object } state - State of the 'Alerts' Store
 * @param { Object } action - Action Object. Always has action.type, which should match a constant
 * @return { Object } - Updated Alert's Store
 */
export const alerts = ( state = initialState, action ) => {
  let newState = { ...state };
  if ( action && action.type && alertsActions.indexOf( action.type ) > -1 ) {
    switch( action.type ){

      case ALERT_LOG_UPDATE:{
        if ( action.flag === "warning" && action.list ){
          const pendingTotal = state.pending.critical.length + action.list.length;
          return {
            ...state,
            pendingTotal,
            pending: {
              ...state.pending,
              warning: [ ...action.list ]
            }
          };  
        } else if  ( action.flag === "critical" && action.list ){
          const pendingTotal = state.pending.warning.length + action.list.length;
          return {
            ...state,
            pendingTotal,
            pending: {
              ...state.pending,
              critical: [ ...action.list ],
            }
          };
        }
      };break;

      case FETCH_PENDING_ALERTS_SUCCESS: {
        const groupBySeverity =_.groupBy(action.data.items, "alarm.severity");
        let pendingTotal = 0;

        Object.keys(groupBySeverity).forEach(severity => {
          const groupedByAlarm = _.groupBy(groupBySeverity[severity], "alarm.id");
          const alarmList = Object.keys(groupedByAlarm).map(alarm => groupedByAlarm[alarm]);
          pendingTotal = pendingTotal + alarmList.length;

          groupBySeverity[severity] = alarmList
            .map(incidentsList => _.orderBy(incidentsList, ['createAt'], ['desc']))
            .map(incidentsList => mapIncidentsToPendingAlarms(incidentsList));
        });

        return {
          ...state,
          pendingTotal,
          pending:{
            warning: [],
            critical: [],
            ...groupBySeverity
          },
          fetchingPendingAlerts: false
        };
      };

      case FETCH_CLEARED_ALERTS_SUCCESS: {
        const clearedTotal = action.data.total;
        const cleared = action.data.items.map(event => ({
          incident_time: flattenDate(event.createAt),
          severity: event.alarm.severity,
          friendly_name: event.displayTitle,
          alarm_id: event.alarm.id,
          system_mode_text: event.systemMode, 
          friendly_description: replacePlaceholders(friendlyDescriptionResolver, event.displayMessage, event)
        }));

        return {
          ...state,
          clearedTotal,
          cleared
        };
      };

      case ALERT_LOG_UPDATING: {
        return {
          ...newState,
          fetchingPendingAlerts: true
        };
      };

      case ALERT_LOG_ERROR: {
        return {
          ...newState,
          fetchingPendingAlerts: false
        };
      };
      default: break;
    }
  }
  return newState;
}

export default alerts;
