"use strict";

import React, { PureComponent } from "react";
import Slider                   from 'rc-slider';
import { Popover, PopoverBody } from 'reactstrap';
import { Link }                 from "react-router";
import HealthCheckWidget        from "elements/HealthCheckWidget";
import _                        from "lodash";


export default class LeakDetectionCard extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const debouncedEvent = _.throttle(this.props.onHealthTestAlarmsSensitivity, 5000, { leading: true, trailing: false });

    return (
      <div className="leak-detection-widget health-test h-100">
        <div className="leak-header">
          Leak Detection
          <a
            id="popover-health-test-info"
            className="popover-info-icon"
            onFocus={ this.props.onToggleShowHealthTestInfo }
            onMouseEnter={ this.props.onToggleShowHealthTestInfo }
            onMouseLeave={ this.props.onToggleShowHealthTestInfo }
          >
            <i className="far fa-question-circle" />
          </a>
          <Popover
            placement="bottom"
            isOpen={this.props.showHealthTestInfo}
            target="popover-health-test-info"
            toggle={ this.onToggleShowHealthTestInfo }
          >
            <PopoverBody>
              Health Tests run automatically each day when you’re not using your water to test for leaks.
              Manually run one anytime you're not using water to check for leaks.
            </PopoverBody>
          </Popover>
          <Link className="float-right" to="/alerts">Manage All</Link>
        </div>
        <div className="card-body">
          <div className="row align-items-center sensitivity-controls-container bottom-margin">
            <div className="col-3 text-center drip-logo-container">
              <img
                className={ "img-fluid image-size-" + this.props.dripLogoSize }
                src="/assets/images/drip@2x.png"
              />
            </div>
            <div className="col-9">
              <Slider
                min={0}
                max={3}
                step={null}
                marks={ this.props.healthTestAlarmsSensitivityLevels }
                value={ this.props.healthTestAlarmsSensitivity }
                onChange={ debouncedEvent }
              />
            </div>
          </div>
          <div className="row align-items-center bottom-margin">
            <div className="col-12 text-center">
              <span>{ this.props.healTestAlarmSelectedSensitivityDetails }</span>
            </div>
          </div>
          <div className="row align-items-center h-100">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto text-center">
              <HealthCheckWidget />
            </div>
          </div>
        </div>
        <div className="card-footer text-muted">
          <small>
            { `Last health test ran ${this.props.lastTimeHealthTestRan}` }
          </small>
        </div>
      </div>
    )
  }
}
