"use strict";

import React, { Component }       from 'react';
import { connect }                from "react-redux";
import LocationProfileForm   from 'elements/LocationProfileForm';
import LocationProfileActions from "actions/locationProfile";
import { getFormValues } from 'redux-form';

class LocationProfile extends Component {

  constructor( props ){
    super( props );
    this.state = {
      submitting: false
    };
  }

  componentDidMount(){
    LocationProfileActions.hydrateStore();
  }

  onCountryChange = proxy => {
    LocationProfileActions.hydrateDropdowns(
      this.props.locationProfileFormValues,
      {
        country: proxy.target.value
      }
    );
  }

  onStateChange = proxy => {
    LocationProfileActions.hydrateDropdowns(
      this.props.locationProfileFormValues,
      {
        state: proxy.target.value.toUpperCase()
      }
    );
  }

  /**
   * Save location event handler
   * @param { Proxy } proxy - Event, Target, Handler proxy object
   */

  saveLocation = formValues => {
    this.setState( {submitting: true} );
    return LocationProfileActions.saveLocationProfile(formValues.id, {
      address      : formValues.address,
      address2     : formValues.address2,
      city         : formValues.city,
      state        : formValues.state,
      postalCode   : formValues.postalCode,
      country      : formValues.country,
      timezone     : formValues.timezone
    }).then(result => {
      this.setState({ submitting: false });
    });
  }

  getLocationProfile() {
    return this.props.locationProfile;
  }

  getTimezoneName() {
    const locationProfile = this.getLocationProfile();
    const location = locationProfile.location;

    if(location.timezone) {
      const timezone = locationProfile.timezones.find(x => x.tz == location.timezone);
      return timezone ? timezone.display : '';
    }
  }

  getCountryName() {
    const locationProfile = this.getLocationProfile();
    const location = locationProfile.location;

    if(location.country) {
      const country = locationProfile.countries.find(x => x.locale == location.country);
      return country ? country.name : '';
    }
  }

  getStateName() {
    const locationProfile = this.getLocationProfile();
    const location = locationProfile.location;

    if(location.state) {
      const state = locationProfile.states.find(x => x.abbrev == location.state);
      return state ? state.name : '';
    }
  }

  render() {
    const {
      location,
      states,
      countries,
      timezones
    } = this.props.locationProfile;

    return (
      <div className="card h-100">
        <div className="card-header">
          <span>Location Profile</span>
        </div>
        <div className="card-body">
          <LocationProfileForm
            initialValues={ location }
            countries={ countries }
            states={ states }
            timezones={ timezones }
            onCountryChange={ this.onCountryChange }
            onStateChange={ this.onStateChange }
            onSubmit={ this.saveLocation }
            submitting={ this.state.submitting }
          />

        </div>
      </div>
    );
  }
}

const ConnectedLocationProfile = connect( state =>
  ({
    locationProfile: {
      ...state.locationProfile
    },
    locationProfileFormValues: getFormValues('user-localtion-profile-form')(state),
  })
)( LocationProfile );

export default ConnectedLocationProfile;
