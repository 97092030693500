"use strict";

import React, { Component } from "react";

const createStateFromProps = props => ({
	label: ( props.value ? props.label || props.placeholder : props.placeholder || props.label ),
	style: ( props.value ? "label" : "placeholder" )
});

/**
 * AdaptivePlaceholder component
 */
export class AdaptivePlaceholder extends Component {
	/**
   * React Lifecycle: Initilizes the component and sets the initial state 
   * @param { Object } props - Initial incomming properties on the component
   */
	constructor( props ) {
		super();
		this.state = createStateFromProps( props );	
	}
	/**
   * React Lifecycle: Exposes the incomming property updates to the component
   * @param { Object } nextProps - The incomming properties due to react properties update
   */
  componentWillReceiveProps( props ) {
    this.setState( createStateFromProps( props ) );
  }
	/**          
   * React Lifecycle: render - Is fired when the <AdaptivePlaceholder /> component is leveraged to draw to the screen.
   * @return { JSX } - a JSX Object
   */
  render() {
    return ( <label className={ this.state.style } onClick={ this.props.onClick }>{ this.state.label }</label> );
  }
}
/**
 * Label Input Dynamic Component
 */
export class LabelInput extends Component {
	/**     
   * React Lifecycle: Initilizes the component and sets the initial state                       
   * @param { Object } props - Initial incomming properties on the component                    
   */
	constructor( props ){
		super();
		this.state = { ...props };
	}
	/**
   * Input change event handler
   * @param { Proxy<Object> } proxy - Event proxy object
   */
	handleChange( proxy ){
		if ( typeof this.props.onChange === "function" ) {
			this.props.onChange.call( this, proxy.target.value );
		}
		this.setState({ value: proxy.target.value })
	}
  /**
   * Label click handler
   */
	handleLabelClick(){
		this.refs.in.focus();
	}
	/**                       
   * React Lifecycle: render - Is fired when the <LabelInput /> component is leveraged to draw to the screen.
   * @return { JSX } - a JSX Object
   */
	render() {
		const field = React.cloneElement(
				React.Children.only(this.props.children), {
						ref: "in", 
						onChange: this.handleChange.bind( this )
				});
		return (
			<div className="label-input">
				{field}
				<AdaptivePlaceholder 
					{ ...this.props }
					value={this.state.value}
					onClick={this.handleLabelClick.bind( this )}/>
			</div>
		);
	}
}

export default LabelInput;
