"use strict";

import React, { Component }         from "react";
import { connect }                  from "react-redux";
import { Helmet }                   from "react-helmet";
import InsuranceLetterActions       from "actions/insuranceLetter";
import moment                       from 'moment';
import InsuranceLetterStatus        from "constants/InsuranceLetterStatus";
import { Link }                     from "react-router";
import PropTypes                    from "prop-types";
import queryString                  from "query-string";
import PDF                          from "react-pdf-js";
import InsuranceLetterSkeleton      from 'elements/InsuranceLetterSkeleton';
import NoDevicesCard                from '../elements/NoDevicesCard';
import ProductType from "../../constants/ProductType";
import SystemMode from 'constants/SystemMode';

export class InsuranceLetter extends Component {

  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    InsuranceLetterActions.hydrateStore(this.context.mixpanel, this.getSourceId());
  }

  getSourceId() {
    return queryString.parse(this.props.location.search).source_id || 'user-portal';
  }

  onDownload = () => {
    InsuranceLetterActions.download(this.context.mixpanel, this.getSourceId(), this.props.floProtect.status);
  }

  downloadButton(featureEnabled, downloadLinkStatus) {
    if(!featureEnabled) {
      return (
        <Link className="btn btn-primary btn-lg btn-block text-uppercase" to="/floprotect?source_id=insurance_letter">
          Add FloProtect to Download
        </Link>
      );
    }

    const downloadDisabled = downloadLinkStatus != InsuranceLetterStatus.READY;

    return (
      <a
        className={"btn btn-primary btn-lg btn-block text-uppercase" + (downloadDisabled ? " disabled" : "")}
        download="insurance-letter.pdf"
        href={this.props.blobObjectUrl}
        onClick={this.onDownload}
      >
        Download
      </a>
    );
  }

  render() {
    const currentYear = moment().year();
    const featureEnabled = true; // allow to download and see insurance letter no matter user subscription status
    const downloadLinkStatus = this.props.status;
    const title = (
      <Helmet>
        <title>Flo - Insurance Letter</title>
      </Helmet>
    );
    const shutoffDevices = this.props.devices.filter(d => ProductType.fromValue(d.deviceType) === ProductType.SHUTOFF && d.isDeviceInstalled && d.isConnected && (d.systemMode != SystemMode.SLEEP.name || (d.systemMode == SystemMode.SLEEP.name && d.systemModeIsLocked)));

    if (!this.props.devices.length) {
      return (
        <div className="container insurance-letter">
          { title }
          <NoDevicesCard
            description = "This home currently has no connected devices. To view your home's insurance letter, please use the Flo by Moen Mobile App to add devices."
          />
        </div>
      );
    } else if (!shutoffDevices.length) {
      return (
        <div className="container insurance-letter">
          { title }
          <NoDevicesCard
            description = "Insurance letter requires the device to be installed and online"
          />
        </div>
      );
    }

    return (
      <div className="container insurance-letter">
        { title }

        <div className="card">
          <div className="card-body m-0">
            <div className="row justify-content-center">
              <div className="col-12">
                <h2 className="lighter-font mb-3">Receive a Discount on Your Home Insurance Policy</h2>

                <p>
                  As a part of your <Link to="/floprotect?source_id=insurance_letter">FloProtect Plan</Link>,
                  you may be eligible for a rebate or discount on your homeowner's insurance policy for having a Flo by
                  Moen Smart Water Shutoff.  Incentives vary by state. Please confirm with your insurance company directly, but here are a
                  few that currently provide a rebate or discount:
                </p>
              </div>
              <div className="col-10 mt-3">
                <p>
                  <img className="img-fluid" src="/assets/images/insurance-letter-logos@2x.png" />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-4">
            <div className="card">
              <div className="card-header">
                Download {currentYear} insurance letter
              </div>
              <div className="card-body m-0">
                <p>
                  Send this letter to your home owner’s insurance company, letting them know Flo by Moen is providing an extra
                  level of protection against leaks and water damage. This letter will be valid for the next 12 months.
                </p>

                { this.downloadButton(featureEnabled, downloadLinkStatus) }

                <div className="text-center mt-2">
                  <small className="text-muted">Document will download as a PDF</small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mt-4">
            { (downloadLinkStatus == InsuranceLetterStatus.UNKNOWN || this.props.loadingFile ) &&
            <div className="card">
              <div className="card-body m-0">
                <InsuranceLetterSkeleton/>
              </div>
            </div>
            }

            { featureEnabled && this.props.document_download_url && !this.props.loadingFile &&
            <div className="card h-100">
              <div className="card-body letter-container m-0 p-0">
                <PDF
                  file={this.props.document_download_url}
                  scale={5}
                  className="img-fluid"
                />
              </div>
            </div>
            }

            { !featureEnabled && downloadLinkStatus != InsuranceLetterStatus.UNKNOWN && !this.props.loadingFile &&
            <div className="card h-100">
              <div className="card-body letter-container m-0 p-0">
                <div className="non-sub letter">
                  <img className="card-img" src="/assets/images/insurance-letter-non-sub.png" />

                  <div className="card-img-overlay h-100 d-flex flex-column justify-content-center p-0">
                    <div className="banner p-5">
                      <h3 className="card-title">Add FloProtect to Guarantee Your Home’s Safety from Water Damage</h3>
                      <p className="card-text">
                        Add FloProtect to immediately access your insurance verification letter, see deeper insights &
                        data, add a $2500 deductible guarantee & more
                      </p>
                      <Link className="card-text text-uppercase" to="/floprotect?source_id=insurance_letter">Add FloProtect</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

InsuranceLetter.contextTypes = {
  mixpanel: PropTypes.object.isRequired
};


export default connect(state => {
  return {
    locationId: state.locationProfile.location.location_id,
    floProtect: {
      enabled: state.subscription.isSubscribedToFloProtect,
      status: state.subscription.status
    },
    status: state.insuranceLetter.status,
    document_download_url: state.insuranceLetter.document_download_url,
    blobObjectUrl: state.insuranceLetter.blobObjectUrl,
    loadingFile: state.insuranceLetter.loadingFile,
    devices: state.device.list,
  };
})(InsuranceLetter);
