"use strict";

import {
  ALERT_LOG_UPDATE,
  ALERT_LOG_UPDATING,
  ALERT_LOG_ERROR,
  FETCH_PENDING_ALERTS_SUCCESS,
  FETCH_ALARMS_SUCCESS,
  FETCH_CLEARED_ALERTS_SUCCESS
} from "constants/action";
import AlertAPI from "api/alert";
import NotificationsSettingAPI from "api/setting/notifications";
import { Authenticate } from "actions/authentication";
import Device from "actions/device";
import LocationProfile from "actions/locationProfile";
import Store from "store";
import _ from "lodash";

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);


const updateAlertLog = ( flag, list, total ) => ({
  type: ALERT_LOG_UPDATE,
  flag,
  list,
  total
});

const fetchingAlerts = () => ({
  type: ALERT_LOG_UPDATING
});

const fetchingAlertsError = () => ({
  type: ALERT_LOG_ERROR
});

const fetchPendingAlertsSuccess = (data) => ({
  type: FETCH_PENDING_ALERTS_SUCCESS,
  data,
});

const fetchClearedAlertsSuccess = (data) => ({
  type: FETCH_CLEARED_ALERTS_SUCCESS,
  data,
});

const fetchAlarmsSuccess = data => ({
  type: FETCH_ALARMS_SUCCESS,
  data
});

const initialPage = 1;

/**
 * Alert Actions module
 */
class _Alert {
  /**
   * Start's TTL and Hydrates the Store with Alert data
   * @return { undefined } - No Value
   */
  hydrateStore() {
    return this.fetchAlertsByLocation();
  }


  @Authenticate()
  fetchAlertsByLocation() {
    Store.dispatch(fetchingAlerts());
    return LocationProfile.hydrateStore()
      .then(locationData => {
        const alarmsPromise = NotificationsSettingAPI.getAlarms();
        const pendingAlertsPromise = AlertAPI.getPendingAlerts(locationData.id);
        const clearedAlertsPromise = AlertAPI.getClearedAlerts(locationData.id, initialPage);

        return Promise.all([
          alarmsPromise,
          pendingAlertsPromise,
          clearedAlertsPromise
        ])
      })
      .then(([alarms, pendingAlerts, clearedAlerts]) => {
        Store.dispatch(fetchPendingAlertsSuccess(pendingAlerts));
        Store.dispatch(fetchAlarmsSuccess(alarms.items));
        Store.dispatch(fetchClearedAlertsSuccess(clearedAlerts));
      })
      .catch( error => {
        Store.dispatch(fetchingAlertsError());
        console.error(error);
      });
  }

  @Authenticate()
  fetchClearedAlerts(page) {
    return LocationProfile.hydrateStore()
      .then(locationData => AlertAPI.getClearedAlerts(locationData.id, page))
      .then(clearedAlerts => Store.dispatch(fetchClearedAlertsSuccess(clearedAlerts)))
      .catch(error => console.error("Error fething cleared alerts"));
  }

  @Authenticate()
  clearPending(alertsToClearIds){
    const alerts = Store.getState().alert;
    const alarmsToClear = alerts.pending.critical
      .concat(alerts.pending.warning)
      .filter(event => alertsToClearIds.includes(event.incident_id))
      .map(event => event.alarm_id);

    return this.userAction(alarmsToClear, 0);
  }

  @Authenticate()
  userAction(alarmIds, snoozeSeconds) {
    return LocationProfile.hydrateStore()
      .then(locationData => AlertAPI.sendAction(locationData.id, alarmIds, snoozeSeconds))
      .then(() => {
        const alerts = Store.getState().alert;
        const criticalList = alerts.pending.critical.filter(item => !alarmIds.includes(item.alarm_id));
        const warningList = alerts.pending.warning.filter(item => !alarmIds.includes(item.alarm_id));
        Store.dispatch( updateAlertLog( "critical", criticalList ) );
        Store.dispatch( updateAlertLog( "warning", warningList ) );
      });
  }
}

/**
 * @ignore
 */
export const Alert = new _Alert();
export default Alert;
