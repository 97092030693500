"use strict";

import {
  AUTHORIZATION_RETRIEVE_CLIENT_INFO_AND_SCOPES
} from "constants/action";

const initialState = {
  clientId: null,
  clientName: "",
  scopes: []
};

/**
 * Autehntication Reducer
 * @param { Object } state - State of the 'Authentication' Store
 * @param { Object } action - Action Object. Always has action.type, which should match a constant
 * @return { Object } - Updated Authentication Store
 */
export const authorization = ( state = initialState, action ) => {
  switch( action.type ){
    case AUTHORIZATION_RETRIEVE_CLIENT_INFO_AND_SCOPES: {
      return {
        ...state,
        clientId: action.clientId,
        clientName: action.clientName,
        scopes: action.scopes.slice()
      };
    }
    default:
      return { ...state };
  }
}

export default authorization;
