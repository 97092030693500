"use strict";

import axios from "axios";
import config from "config";

const apiURIV2 = config.environment.apiV2.uri + "/api/v2";
const accountByIdURI = accountId => `${apiURIV2}/accounts/${accountId}`;

/**
 * Account API Interface
 */
class _Account {
  getAccountById(accountId, expand = []) {
    const parameters = (expand.length > 0) ? `?expand=${expand.join(",")}` : '';
    return axios.get(accountByIdURI(accountId) + parameters).then(success => success.data);
  }
}

/**
 * @ignore
 */
export const Account = new _Account();
export default Account;
