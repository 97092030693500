"use strict";

import React, { PureComponent } from "react";
import { Link } from "react-router";

/**
 * Alert Notifications Header Widget
 */
export class AlertsPanel extends PureComponent {
  constructor(props) {
		super(props);
	}

  mapSeverityToBootstrapAlertClass(severity) {
    switch (severity) {
      case "critical":
        return "alert-danger";
      case "warning":
        return "alert-warning";
      case "info":
        return "alert-success";
      default:
        return "alert-info";
    }
  }

  render() {
    const alertsText = this.props.count > 1 ? "alerts" : "alert";

    return (
      <Link className="link-unstyled" to="alerts">
        <div className={ "alert " + this.mapSeverityToBootstrapAlertClass(this.props.severity) + ' fade show' } role="alert">
          <strong>
            { this.props.count } pending { alertsText }!
          </strong> Click here to see more details.
        </div>
      </Link>
    )
  }
}

export default AlertsPanel;
