"use strict";

import React, { PureComponent } from "react";

export class Footer extends PureComponent {

  constructor( props ) {
    super(props);
  }

  render() {
    return (
      <footer className="footer">
        <div className="container footer-block">
          <div className="row">
            <div className="col-12">
              <img className="img-fluid" src="/assets/images/FloByMoenLogo-White.png" />
              <div className="text">© 2018 Flo Technologies, Inc.</div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;
