"use strict";

import React, { Component } from "react";
import config from "config";
/*
 * Redirect Component
 */
export class Redirect extends Component {
  /**
   * Set Redirect Component's initial state
   * @param { Object } props - component initiliziation properties
   */
  constructor( props ){
    super( props );
    this.state = { ...props };
  }
  /**
   * React Lifecycle: componentWillMount - This happens before the component mounts but will not stop the mounting progression
   * Sends to redirect based on the incoming route information.
   */
  componentWillMount(){
    if ( this.state.route.loc === "AppStore" ) {
      if ( navigator.userAgent.search( /Android/ )  > -1) {
        window.location = "market://details?id=com.flotechnologies";
      } else if ( navigator.userAgent.search( /iPhone|iPod/ )  > -1 ){
        window.location = "itms://itunes.apple.com/app/id1114650234";
      }
    } else if ( this.state.route.loc === "AlarmSupport") {
      if ( !this.props.params.alarmId || !this.props.params.pageName ) { return; }
      let article = "";
      switch( ~~this.props.params.alarmId ){
        case 28:
        case 29:
        case 30:
        case 31: {
          article = "Small_Drip_Detected";
        }; break;
        case 13: {
          article = "Freeze_Warning";
        }; break;
        case 16: {
          article = "High_Water_Pressure";
        }; break;
        case 15: {
          article = "Low_Water_Pressure";
        }; break;
        case 22: {
          article = "General_System_Error";
        }; break;
        case 23: {
          article = "Device_Memory_Error";
        }; break;
        case 18: {
          article = "Valve_Error";
        }; break;
        case 33: {
          article = "Device_Offline";
        }; break;
        case 26: {
          article = "Extended_Water_Usage";
        }; break;
        case 11: {
          article = "High_Water_Usage";
        }; break;
        case 10: {
          article = "Fast_Water_Flow";
        }; break;
        case 51:
        case 52:
        case 53:
        case 37: {
          article = "Water_System_Shutoff";
        }; break;
        case 45: {
          article = "Alert_Resolved";
        }; break;
        case 32:
        case 39:
        case 40:
        case 41: {
          article = "Health_Test_Interrupted";
        }; break;
        case 5:
        case 34: {
          article = "Health_Test_Successful";
        }; break;
        case 55: {
          article = "Water_Use_in_AWAY_Mode";
        }; break;
        case 57: {
          article = "Water_Pressure_Over_Recommended_Max";
        }; break;
        case 0:
        default: {
          article = "";
        };break;
      }
      window.location = `https://solutions.moen.com/Smart_Water_Security_Products/Help_Center/Flo_by_Moen_app/${article}`;
    } else if ( this.state.route.loc === "Registration" ) {
      window.location = `${config.environment.app.protocol}register/${this.props.params.token1}/${this.props.params.token2}`;
    } else if ( this.state.route.loc === "Registration2" ) {
      window.location = `${config.environment.app.protocol}registration2/${this.props.params.token}`;
    }else {
      window.location = this.state.route.loc;
    }
  }
  /*
   * renderFor - Builds the JSX conditionaly on the UserAgent
   * @private
   * @returns { JSX } - A JSX set of elements & inline presentation layer data
   */
  renderFor(){
    if ( this.state.route.loc === "AppStore" && navigator.userAgent.search( /Android|iPhone|iPod/ ) < 0 ) {
      return ( <section><h3>Please visit this link in your iPhone or Android smartphone.</h3><h3>For more information, please visit <a href="https://meetflo.zendesk.com/hc/en-us/articles/230091847-Installing-the-Flo-Mobile-App">Flo Support Center</a>.</h3></section> );
    } else {
      return  ( <section> Redirecting... </section> );
    }
  }
	/**
   * React Lifecycle: render - Is fired when the <Redirect /> component is leveraged to draw to the screen.
   */
  render(){
    return this.renderFor();
  }
}

export default Redirect;
