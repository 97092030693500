"use strict";

import React, { Component } from "react";
import {Helmet}             from "react-helmet";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames           from 'classnames';
import TotalConsumption     from 'views/TotalConsumption';
import VitalMeasurements    from 'views/VitalMeasurements';
import { connect }          from "react-redux";
import Consumption          from "actions/consumption";
import Setting              from "actions/setting";
import NoDevicesCard        from '../elements/NoDevicesCard';

export class Usage extends Component {
	/**
   * React lifecycle: Constructor, Build's component's state
   * @constructor
   * @param { Object } props - Initial incomming properties used to set component's initial state
   */
	constructor( props ) {
		super(props);

    this.state = {
      activeTab: '1'
    }
	}

  /**
   * React Lifecycle: Event triggered before the component mounts
   * - Sets off Settings store hydration if not already complete
   * - Gets Consumption
   */
  componentDidMount(){
    Setting.hydrateStore();
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  setPeriod = proxy => {
    const period =  proxy.target.value;

    Consumption
      .getMeasurements(period, this.props.selectedDeviceIndex);
  }

  render() {
    const { isLocationInfoLoading, devices, locationHasShutoff } = this.props;
    const locationHasOnlyDetector = (devices.length > 0) && !locationHasShutoff;

    // NO devices in the location
    if (!isLocationInfoLoading && devices.length < 1) {
      return (
        <div className="container usage">
          <Helmet>
            <title>Flo - Usage</title>
          </Helmet>
          <NoDevicesCard
            description = "This home currently has no connected devices. To view your home's water usage, please use the Flo by Moen Mobile App to add devices."
          />
        </div>
      );
    }

    // No Shutoff devices in the location
    if (!isLocationInfoLoading && locationHasOnlyDetector) {
      return (
        <div className="container usage">
          <Helmet>
            <title>Flo - Usage</title>
          </Helmet>
          <NoDevicesCard
            title       = "Add a Smart Water Shutoff to this Location using the Flo by Moen Mobile App"
            description = "This home currently has no connected devices. To view your home's water usage, please use Flo by Moen Mobile App to add a Smart Water Shutoff."
          />
        </div>
      );
    };

    return (
      <div className="container usage">
        <Helmet>
          <title>Flo - Usage</title>
        </Helmet>

        <div className="d-flex mb-2 usage-wrapper">
          <div className="mr-auto pb-3">
            <Nav className="nav-pills nav-inner-view">
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                >
                  Total Consumption
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                >
                  Vital Measurements
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <div className="p-2">
            <select className="d-none d-sm-block usage-period" value={ this.props.periodLength }  onChange={ this.setPeriod }>
              <option value="last_day">Today (hourly)</option>
              <option value="last_24_hours">Last 24 hours (hourly)</option>
              <option value="this_week">This Week (daily)</option>
              <option value="last_28_days">Four Weeks (daily)</option>
              <option value="last_12_months">Last 12 months (monthly)</option>
            </select>
          </div>
        </div>

        <div className="d-sm-none text-right">
          <select className="usage-period-xs" value={ this.props.periodLength }  onChange={ this.setPeriod }>
            <option value="last_day">Today (hourly)</option>
            <option value="last_24_hours">Last 24 hours (hourly)</option>
            <option value="this_week">This Week (daily)</option>
            <option value="last_28_days">Four Weeks (daily)</option>
            <option value="last_12_months">Last 12 months (monthly)</option>
          </select>
        </div>

        <TabContent className="usage-content-wrapper" activeTab={ this.state.activeTab }>
          <TabPane tabId="1">
            <TotalConsumption />
          </TabPane>
          <TabPane tabId="2">
            { this.state.activeTab == "2" &&
              <VitalMeasurements />
            }
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default connect( store => ({
  isSubscribedToFloProtect: store.subscription.isSubscribedToFloProtect,
  selectedDeviceIndex: store.consumption.selectedDeviceIndex,
  periodLength: store.consumption.periodLength || "last_day",
  devices: store.device.list,
  isLocationInfoLoading: !store.query.metadata['User.getUserById'].fetched,
  locationHasShutoff: store.locationProfile.metadata.locationHasShutoff,
}))( Usage );

