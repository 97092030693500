"use strict";

import React, { PureComponent, Component }  from "react";
import ReactEChart                          from "elements/ReactEChart";
import { createSkeletonProvider, createSkeletonElement } from '@trainline/react-skeletor';
import _                                    from "lodash";
import UnitSystem, {
  displayShortVolumeUnit,
  displayLongVolumeUnit
}                                           from 'constants/UnitSystem';

const dummyFixturesUsage = [
  { type: 101, name: "shower" , count: 2, gallons: 94, value: 94, color: '#9b9b9b' },
  { type: 300, name: "faucet" , count: 1, gallons: 32, value: 32, color: '#b0b0b0' },
  { type: 500, name: "irrigation" , count: 1, gallons: 34, value: 34, color: '#686868' },
  { type: 102, name: "bath" , count: 1, gallons: 33, value: 33, color: '#c1c1c1' },
  { type: 200, name: "toilet" , count: 6, gallons: 84, value: 84, color: '#c2c2c2' },
  { type: 601, name: "pool" , count: 1, gallons: 14, value: 14, color: '#cfcfcf' },
  { type: 10000, name: "other" , count: 6, gallons: 34, value: 34, color: '#ebebeb' }
];

function generateNormalTitle(totalUsage, unitSystem) {
  return {
    text: totalUsage,
    subtext: `${displayLongVolumeUnit(unitSystem)} used`,
    right: 'center',
    top: '100px',
    textStyle: {
      color: 'black',
      fontSize: 55,
      fontWeight: '100',
      fontFamily: '"myriad-pro", sans-serif'
    },
    subtextStyle: {
      color: 'black',
      fontSize: 18,
      fontWeight: '100',
      fontFamily: '"myriad-pro", sans-serif'
    }
  };
};

const learningTitle = {
  text: 'Learning',
  subtext: null,
  right: 'center',
  top: '125px',
  textStyle: {
    color: '#4A4A4A',
    fontSize: 30,
    fontWeight: '100',
    fontFamily: '"myriad-pro", sans-serif'
  },
  subtextStyle: null
};

function floProtectTitle(devicePaired) {
  var additionalProperties = {};

  if(devicePaired) {
    additionalProperties = {
      link: '/floprotect',
      target: 'self',
      sublink: '/floprotect',
      subtarget: 'self'
    };
  }

  return {
    text: 'Add',
    subtext: 'FloProtect',
    right: 'center',
    top: '110px',
    textStyle: {
      color: '#4A4A4A',
      fontSize: 25,
      fontWeight: '100',
      fontFamily: '"myriad-pro", sans-serif'
    },
    subtextStyle: {
      color: '#4A4A4A',
      fontSize: 25,
      fontWeight: '100',
      fontFamily: '"myriad-pro", sans-serif'
    },
    ...additionalProperties
  };
}

// Data style
const dataStyle = {
  normal: {
    label: {show: false},
    labelLine: {show: false}
  }
};

const graphStyles = {
  height: "300px"
};

function capitalizeFixtures(name) {
  return name.split('/').map(word => word.charAt(0).toUpperCase() + word.substr(1)).join('/');
}

const FixtureLoading = createSkeletonElement('div', 'pending-div');

const FixtureDetails = ({ key, fixtureData, onClick, isActive, floProtectEnabled, isLearning, isLoading, hasNoDataToDisplay }) => {
  const disabled = (!floProtectEnabled || isLearning || hasNoDataToDisplay || fixtureData.value == 0);
  const disabledClass = disabled ? 'disabled' : '';
  const activeClass = (isActive && !disabled) ? "active" : "";
  const noData = isLearning ? 'learning' : hasNoDataToDisplay ? 'no data' : undefined;
  const fixtureUsageText = noData || `${fixtureData.value.toFixed(2)} ${displayShortVolumeUnit(fixtureData.unitSystem)}`;
  const fixtureName = capitalizeFixtures(fixtureData.name);

  return (
    <div key={ key } className="fixture-details">
      <div className="row">
        <div className="col-4 p-0">
          <FixtureLoading
            className={
              `fixture-logo-container fixture-${fixtureData.type} ${activeClass} ${disabledClass}`
            }
            onClick={ onClick }
          >

            {!isLoading && <img
              className="img-fluid"
              src={`/assets/images/fixture-v2-type-${fixtureData.type}.png`}
            />}
          </FixtureLoading>
        </div>
        <div className="col-6 p-0 fixture-text">
          <FixtureLoading className="fixture-name">
            { !disabled &&
              <strong className="fixture-count">{fixtureData.count}</strong>
            } {fixtureName}
          </FixtureLoading>
          <FixtureLoading className="fixture-usage">
            { floProtectEnabled &&
              fixtureUsageText
            }
          </FixtureLoading>
        </div>
      </div>
    </div>
  );
};

const FixtureDetailsWithSkeletor = createSkeletonProvider({
  fixtureData: {
    gallons: 0,
    value: 0,
    name: '________',
    type: '_',
  }
}, (props) => props.isLoading)(FixtureDetails);

export default class FixtureUsageSummaryCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeFixtureName: null,
      activePeriod: "24_hours",
    }
  }

  buildFixtureUsageChartOptions() {
    const disabled = (!this.props.floProtectEnabled || this.props.isLearning || this.props.hasNoDataToDisplay);
    const data = disabled ? dummyFixturesUsage : this.props.fixturesUsage;
    const totalUsage = Math.ceil(data.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0));
    const learningOrFloProtectTitle = (this.props.floProtectEnabled && (this.props.isLearning || this.props.hasNoDataToDisplay)) ?
      learningTitle : floProtectTitle(this.props.devicePaired);
    const unitSystem = data.length > 0 ? data[0].unitSystem || UnitSystem.IMPERIAL : UnitSystem.IMPERIAL;
    let title = disabled ? learningOrFloProtectTitle : generateNormalTitle(totalUsage, unitSystem);

    if (this.props.isLoading) {
      title = { ...learningTitle, text: 'Loading', subtext: null };
    }
    if (this.props.hasNoDataToDisplay) {
      title = { ...learningTitle, text: 'No Data', subtext: null };
    }

    const tooltip = disabled ?
      null :
      {
        show: true,
        formatter: `{b} <br/> {c} ${displayShortVolumeUnit(unitSystem)} <br/> ({d}%)`
      };

    return {
      title,
      tooltip,
      color: data.map(x => x.color),
      series: [
        {
          name: 'fixtureUsage',
          type: 'pie',
          radius: ['65%', '90%'],
          itemStyle: dataStyle,
          data: data.map(x => ({ value: x.value.toFixed(2), name: x.name, selected: this.state.activeFixtureName === x.name}))
        }
      ]
    };
  }

  onActiveFixtureChange = name => e => {
    if(this.state.activeFixtureName == name) {
      this.setState({ activeFixtureName: '' });
    } else {
      this.setState({ activeFixtureName: name });
    }
  };

  render() {
    const disabled = (!this.props.floProtectEnabled || this.props.isLearning || this.props.hasNoDataToDisplay);
    const fixturesUsage = (_.isEmpty(this.props.fixturesUsage) || disabled) ?
      dummyFixturesUsage : this.props.fixturesUsage;

    return (
      <div className="card fixture-usage-summary">
        <div className="card-header">
          Usage by Fixtures
        </div>
        <div className="card-body">
          <div className="row bottom-margin">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <ul className="nav nav-pills nav-fill">
                <li className="nav-item">
                  <a
                    className={ "nav-link " + (this.props.activePeriod == '24_hours' ? "active" : "") }
                    onClick={ this.props.onActivePeriodChange('24_hours') }
                  >
                    Last 24 Hours
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={ "nav-link " + (this.props.activePeriod == '7_days' ? "active" : "") }
                    onClick={ this.props.onActivePeriodChange('7_days') }
                  >
                    Last 7 Days
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <ReactEChart
                option={ this.buildFixtureUsageChartOptions() }
                showLoading={ false }
                style={ graphStyles }
              />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div className="row">
                {fixturesUsage.map((fixtureUsage, i) => {

                  return (
                    <div className="col-6">
                      <FixtureDetailsWithSkeletor
                        key={i}
                        fixtureData={fixtureUsage}
                        onClick={this.onActiveFixtureChange(fixtureUsage.name)}
                        isActive={this.state.activeFixtureName === fixtureUsage.name}
                        floProtectEnabled={this.props.floProtectEnabled}
                        isLearning={this.props.isLearning}
                        isLoading={this.props.isLoading}
                        hasNoDataToDisplay={this.props.hasNoDataToDisplay}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
