"use strict";

import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * FloGroupTogglebox
 */
export default class FloGroupTogglebox extends Component {
	/**
   * React Lifecycle: Initilizes the component and sets the initial state
   * @param { Object } props - Initial incomming properties on the component
   */
  constructor( props ) {
    super();
    this.state = { ...props };
  }
	/**
   * React Lifecycle: Handles incoming property updates, if the store is connected. Every store update.
   * - parses the store's device and attributes, and updates the state on all values.
   * @param { Object } nextProps - The incomming properties due to react properties update
   */
  componentWillReceiveProps( nextProps ) {
    this.setState({ ...nextProps });
  }
  /**
   * React Lifecycle: brings in the next state and properties to allow for conditional updating of the component / redraw
   * @param { Object } nextProps - The incomming properties due to react properties update
   * @param { Object } nextState - The next state the component would redraw with
   * @return { Boolean } - of whether component should redraw
   */
  shouldComponentUpdate( nextProps, nextState ) {
    return JSON.stringify( nextProps ) !== JSON.stringify( this.state ) || JSON.stringify( nextState ) !== JSON.stringify( this.state )
  }
  /**
   * Set the component disabled state to true
   */
  disable(){
    this.setState( { disabled: true } );
  }
  /**
   * Set the component disabled state to false
   */
  enable(){
    this.setState( { disabled: false } );
  }
	/**
   * React Lifecycle: render - Is fired when the <FloGroupTogglebox /> component is leveraged to draw to the screen.
   * @return { JSX } - a JSX Object
   */
  render(){
    const classList = "depressed-box checkbox " + ( this.state.display === "all" ? "icon-Checkmark " : this.state.display === "partial" ? "icon-IndeterminateCheckBox " : "" ) + ( this.state.className || "" );
    const classListWrapper = [
      "flo-group-togglebox",
      "depressed-box",
      `${ this.state.disabled ? "disabled" : "" }`,
    ].join( " " );
    return (<div className={ classListWrapper } onClick={ this.props.onClick }>
      <div className={ classList } data-active={ this.state.display }></div>
    </div>)
  }
}

/**
 * Set FloGroupTogglebox incomming property types
 */
FloGroupTogglebox.propTypes = {
  display : PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

/**
 * Set FloGroupTogglebox incomming property defaults
 */
FloGroupTogglebox.defaultProps = {
  display : "none",
  disabled: false,
}
